import { Suspense } from "react";
import { useSelector } from "react-redux";
import Backdrop from "@mui/material/Backdrop";

import ContentLoader from "../ContentLoader";

const MainModal: React.FC = () => {
  const modalOpen = useSelector((state: any) => state.app.modalOpen);
  const modalContent = useSelector((state: any) => state.app.modalContent);

  return (
    <Backdrop
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        alignItems: "flex-start",
      }}
      open={modalOpen}
    >
      <Suspense fallback={<ContentLoader />}>{modalContent}</Suspense>
    </Backdrop>
  );
};

export default MainModal;
