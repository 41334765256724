export enum Directions {
  RTL = "rtl",
  LTR = "ltr",
}

export enum Languages {
  ENGLISH = "en",
  ARABIC = "ar",
}

export enum Pages {
  LOGIN = "/",
  REGISTER = "/register",
  VERIFY = "/verify",
  FORGOT_PASSWORD = "/forget-password",
  DASHBOARD = "/dashboard/overview",
  ACCOUNTS = "/dashboard/accounts",
  USERS = "/dashboard/users",
  LANGUAGES = "/dashboard/languages",
  FEATURES = "/dashboard/features",
  PAGES = "/dashboard/pages",
  MEDIA = "/dashboard/media-library",
  MESSAGES = "/dashboard/messages",
  MENUS = "/dashboard/menus",
  POSTS = "/dashboard/posts",
  SETTINGS = "/dashboard/settings",
  SLIDES = "/dashboard/slides",
  COURSES = "/dashboard/courses",
  MODULES = "/dashboard/modules",
  QUIZZES = "/dashboard/quizzes",
  QUIZ_QUESTIONS = "/dashboard/quiz-questions",
  QUESTIONS = "/dashboard/questions",
  QUESTIONS_TYPES = "/dashboard/questions-types",
  STUDENTS = "students",
}

export enum Orders {
  ASC = "asc",
  DSC = "desc",
}

export enum FormActions {
  ADD = "Add",
  EDIT = "Edit",
}

export enum QuestionTypes {
  TRUE_FALSE = "True False",
  CHOOSE = "Choose",
  CHOOSE_IMAGE = "Choose with Image",
  TEXT_IMAGE = "Text with Image",
  OPTIONS_IMAGE = "Options as Image",
  COMPLETE_IMAGE = "Complete with Image",
}

export enum Optimizer {
  DEV = "https://wanijoseph.mo.cloudinary.net",
  VRTHEATRE = "https://wanijoseph.mo.cloudinary.net/vrtheatre",
}
