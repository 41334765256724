import { Auth, API } from "aws-amplify";

export const signup = async (data: any) => {
  try {
    const user = await Auth.signUp({
      username: data.email,
      password: data.password,
      attributes: {
        name: data.name,
        email: data.email,
      },
    });

    return user;
  } catch (error: any) {
    console.log(error);
    throw new Error(error.errors[0].message);
  }
};

export const login = async (data: any) => {
  try {
    const { attributes } = await Auth.signIn({
      username: data.email,
      password: data.password,
    });

    return attributes;
  } catch (error: any) {
    throw new Error(error.errors[0].message);
  }
};

export const logout = async () => {
  try {
    await Auth.signOut();
  } catch (error: any) {
    throw new Error(error.errors[0].message);
  }
};

export const authState = async () => {
  try {
    const { attributes } = await Auth.currentAuthenticatedUser({
      bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    });

    return attributes;
  } catch (error: any) {
    throw new Error(error.errors[0].message);
  }
};

export async function disableUser(username: string) {
  try {
    const apiName = "AdminQueries";
    const path = "/disableUser";
    const request = {
      body: {
        username: username,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    };

    const result = await API.post(apiName, path, request);
    return result;
  } catch (error: any) {
    throw new Error(error.errors[0].message);
  }
}

export async function enableUser(username: string) {
  try {
    const apiName = "AdminQueries";
    const path = "/enableUser";
    const request = {
      body: {
        username: username,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    };

    const result = await API.post(apiName, path, request);
    return result;
  } catch (error: any) {
    throw new Error(error.errors[0].message);
  }
}

export async function listGroups(limit?: number, nextToken?: string) {
  try {
    const apiName = "AdminQueries";
    const path = "/listGroups";
    const request = {
      queryStringParameters: {
        limit: limit,
        token: nextToken,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    };
    const result = await API.get(apiName, path, request);
    return result.Groups;
  } catch (error: any) {
    throw new Error(error.errors[0].message);
  }
}

export const addToGroup = async (username: string, groupname: string) => {
  try {
    const apiName = "AdminQueries";
    const path = "/addUserToGroup";
    const request = {
      body: {
        username: username,
        groupname: groupname,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    };

    const result = await API.post(apiName, path, request);
    return result;
  } catch (error: any) {
    throw new Error(error.errors[0].message);
  }
};

export async function removeUserFromGroup(username: string, groupname: string) {
  try {
    const apiName = "AdminQueries";
    const path = "/removeUserFromGroup";
    const request = {
      body: {
        username: username,
        groupname: groupname,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    };

    const result = await API.post(apiName, path, request);
    return result;
  } catch (error: any) {
    throw new Error(error.errors[0].message);
  }
}

export async function update(username: string, attributes: any) {
  try {
    const apiName = "AdminQueries";
    const path = "/editUserAttributes";
    const request = {
      body: {
        username: username,
        attributes: attributes,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    };

    const result = await API.post(apiName, path, request);
    return result;
  } catch (error: any) {
    throw new Error(error.errors[0].message);
  }
}
