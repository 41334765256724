import { Post } from "../../models/api";

// actions
const SET = "posts/SET";
const SET_SEARCH_TEXT = "posts/SET_SEARCH_TEXT";
const SET_DATES = "posts/SET_DATES";

const DEFAULT_STATE = {
  posts: [],
  searchText: "",
  datesFilter: [null, null],
};

// reducer
const postReducer = (state = DEFAULT_STATE, action: any = {}) => {
  switch (action.type) {
    case SET:
      return Object.assign({}, state, { posts: action.posts });
    case SET_SEARCH_TEXT:
      return Object.assign({}, state, { searchText: action.searchText });
    case SET_DATES:
      return Object.assign({}, state, { datesFilter: action.dates });
    default:
      return state;
  }
};

export default postReducer;

// action creators
export const setPosts = (posts: Post[]) => {
  return { posts, type: SET };
};

export const setSearchText = (searchText: string) => {
  return { searchText, type: SET_SEARCH_TEXT };
};

export const setDates = (dates: any[]) => {
  return { dates, type: SET_DATES };
};
