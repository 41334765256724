import { Media } from "../../models/api";

// actions
const SET = "media/SET";
const SET_SEARCH_TEXT = "media/SET_SEARCH_TEXT";
const SET_SELECTED = "media/SET_SELECTED";
const SET_DATES = "media/SET_DATES";

const DEFAULT_STATE = {
  media: [],
  searchText: "",
  selectedFiles: new Set(),
  datesFilter: [null, null],
};

// reducer
const mediaReducer = (state = DEFAULT_STATE, action: any = {}) => {
  switch (action.type) {
    case SET:
      return Object.assign({}, state, { media: action.media });
    case SET_SELECTED:
      return Object.assign({}, state, { selectedFiles: action.files });
    case SET_SEARCH_TEXT:
      return Object.assign({}, state, { searchText: action.searchText });
    case SET_DATES:
      return Object.assign({}, state, { datesFilter: action.dates });
    default:
      return state;
  }
};

export default mediaReducer;

// action creators
export const setMedia = (media: Media[]) => {
  return { media, type: SET };
};

export const setSearchText = (searchText: string) => {
  return { searchText, type: SET_SEARCH_TEXT };
};

export const setDates = (dates: any[]) => {
  return { dates, type: SET_DATES };
};
export const setSelectedFiles = (files: any) => {
  return { files, type: SET_SELECTED };
};
