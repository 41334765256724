import { Message } from "../../models/api";

// actions
const SET = "messages/SET";
const SET_SEARCH_TEXT = "messages/SET_SEARCH_TEXT";
const SET_DATES = "messages/SET_DATES";

const DEFAULT_STATE = {
  messages: [],
  searchText: "",
  datesFilter: [null, null],
};

// reducer
const messageReducer = (state = DEFAULT_STATE, action: any = {}) => {
  switch (action.type) {
    case SET:
      return Object.assign({}, state, { messages: action.messages });
    case SET_SEARCH_TEXT:
      return Object.assign({}, state, { searchText: action.searchText });
    case SET_DATES:
      return Object.assign({}, state, { datesFilter: action.dates });
    default:
      return state;
  }
};

export default messageReducer;

// action creators
export const setMessages = (messages: Message[]) => {
  return { messages, type: SET };
};

export const setSearchText = (searchText: string) => {
  return { searchText, type: SET_SEARCH_TEXT };
};

export const setDates = (dates: any[]) => {
  return { dates, type: SET_DATES };
};
