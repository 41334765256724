import { lazy, FC, Suspense, useEffect } from "react";
import { Amplify } from "aws-amplify";
import { Route, Routes, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline, GlobalStyles } from "@mui/material";

import awsExports from "./aws-exports";
import MainLoader from "./components/UI/MainLoader";
import MainModal from "./components/UI/MainModal";
import MainSnackbar from "./components/UI/MainSnackbar";
import useTheme from "./hooks/useTheme";
import useLoading from "./hooks/useLoading";
import { authState, listGroups } from "./services/auth";
import { get as getUser } from "./services/user";
import { list as listLanguages } from "./services/language";
import { list as listFeatures } from "./services/feature";
import { clearSession, setSession } from "./store/ducks/app";
import { setGroups } from "./store/ducks/user";
import { setLanguages } from "./store/ducks/language";
import { setFeaturesList } from "./store/ducks/feature";
import { Pages } from "./constants/enums";
import ErrorBoundary from "./components/UI/ErrorBoundary";

Amplify.configure(awsExports);
// Amplify.Logger.LOG_LEVEL = "DEBUG";

const Admin = lazy(() => import("./containers/Dashboard"));
const Auth = lazy(() => import("./containers/Auth"));

const App: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state: any) => state.app.session);
  const modalOpen = useSelector((state: any) => state.app.modalOpen);
  const snackBarOpen = useSelector((state: any) => state.app.snackBarOpen);
  const { loading, changeLoading } = useLoading();
  const { theme } = useTheme();

  useEffect(() => {
    const fetchUserSession = async () => {
      try {
        const session = await authState();
        const user = await getUser(session.sub);

        dispatch(setSession(user));
        changeLoading(false);
      } catch (error) {
        dispatch(clearSession());
        changeLoading(false);
        navigate(Pages.LOGIN);
      }
    };

    fetchUserSession();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const fetchGroups = () => {
      listGroups().then((groups: any) => dispatch(setGroups(groups)));
    };

    const fetchLanguages = () => {
      listLanguages(1000, undefined).then((languages: any) =>
        dispatch(setLanguages(languages.items))
      );
    };

    const fetchFeatures = () => {
      listFeatures(1000, undefined).then((features: any) =>
        dispatch(setFeaturesList(features.items))
      );
    };

    if (user) {
      fetchGroups();
      fetchFeatures();
      fetchLanguages();
    }

    // eslint-disable-next-line
  }, [user]);

  if (loading) return <MainLoader />;

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalStyles
        styles={{
          ".MuiSnackbar-root": { right: "24px !important" },
          ".MuiPaper-root-MuiSnackbarContent-root": {
            width: "100%",
          },
          ".MuiDrawer-paper": {
            borderRight: "none !important",
          },
          ".tox-tinymce": {
            borderWidth: "1px !important",
            borderRadius: "0px !important",
          },
          "#editor textarea": {
            display: "none",
          },
        }}
      />
      <ErrorBoundary>
        <Suspense fallback={<MainLoader />}>
          <Routes>
            <Route path="/*" element={<Auth />} />
            <Route path="dashboard/*" element={<Admin />} />
          </Routes>
        </Suspense>
        {modalOpen && <MainModal />}
        {snackBarOpen && <MainSnackbar />}
      </ErrorBoundary>
    </ThemeProvider>
  );
};

export default App;
