import { QuestionType } from "../../models/api";

// actions
const SET = "questionTypes/SET";
const SET_SEARCH_TEXT = "questionTypes/SET_SEARCH_TEXT";
const SET_DATES = "questionTypes/SET_DATES";

const DEFAULT_STATE = {
  questionTypes: [],
  searchText: "",
  datesFilter: [null, null],
};

// reducer
const moduleReducer = (state = DEFAULT_STATE, action: any = {}) => {
  switch (action.type) {
    case SET:
      return Object.assign({}, state, { questionTypes: action.questionTypes });
    case SET_SEARCH_TEXT:
      return Object.assign({}, state, { searchText: action.searchText });
    case SET_DATES:
      return Object.assign({}, state, { datesFilter: action.dates });
    default:
      return state;
  }
};

export default moduleReducer;

// action creators
export const setQuestionTypes = (questionTypes: QuestionType[]) => {
  return { questionTypes, type: SET };
};

export const setSearchText = (searchText: string) => {
  return { searchText, type: SET_SEARCH_TEXT };
};

export const setDates = (dates: any[]) => {
  return { dates, type: SET_DATES };
};
