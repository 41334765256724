/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      courses {
        items {
          id
          userID
          courseID
          createdAt
          updatedAt
        }
        nextToken
      }
      username
      preferred_username
      email
      email_verified
      phone_number
      phone_number_verified
      name
      nickname
      given_name
      middle_name
      family_name
      address
      birthdate
      gender
      locale
      picture
      website
      zoneinfo
      verification_code
      group
      deleted
      createdAt
      createdBy
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        courses {
          nextToken
        }
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        website
        zoneinfo
        verification_code
        group
        deleted
        createdAt
        createdBy
        updatedAt
      }
      nextToken
    }
  }
`;
export const activeUsers = /* GraphQL */ `
  query ActiveUsers(
    $deleted: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    activeUsers(
      deleted: $deleted
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        courses {
          nextToken
        }
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        website
        zoneinfo
        verification_code
        group
        deleted
        createdAt
        createdBy
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEmailVerification = /* GraphQL */ `
  query GetEmailVerification($id: ID!) {
    getEmailVerification(id: $id) {
      id
      name
      email
      verification_code
      createdAt
      deleted
      updatedAt
    }
  }
`;
export const listEmailVerifications = /* GraphQL */ `
  query ListEmailVerifications(
    $filter: ModelEmailVerificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEmailVerifications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        email
        verification_code
        createdAt
        deleted
        updatedAt
      }
      nextToken
    }
  }
`;
export const activeVerifications = /* GraphQL */ `
  query ActiveVerifications(
    $deleted: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEmailVerificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    activeVerifications(
      deleted: $deleted
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        email
        verification_code
        createdAt
        deleted
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAccount = /* GraphQL */ `
  query GetAccount($id: ID!) {
    getAccount(id: $id) {
      id
      logo {
        id
        fileurl
        alternativeText
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        mediaAttachmentsId
        attachmentCreatedById
      }
      siteTitle
      tagline
      description
      siteAddress
      defaultLanguage
      languages {
        items {
          id
          accountID
          languageID
          createdAt
          updatedAt
        }
        nextToken
      }
      status
      socialLinks {
        items {
          id
          name
          icon
          slug
          deleted
          createdAt
          updatedAt
          accountSocialLinksId
          socialLinkCreatedById
        }
        nextToken
      }
      deleted
      createdAt
      createdBy {
        id
        courses {
          nextToken
        }
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        website
        zoneinfo
        verification_code
        group
        deleted
        createdAt
        createdBy
        updatedAt
      }
      updatedAt
      accountLogoId
      accountCreatedById
    }
  }
`;
export const listAccounts = /* GraphQL */ `
  query ListAccounts(
    $filter: ModelAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAccounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        logo {
          id
          fileurl
          alternativeText
          createdAt
          updatedAt
          mediaAttachmentsId
          attachmentCreatedById
        }
        siteTitle
        tagline
        description
        siteAddress
        defaultLanguage
        languages {
          nextToken
        }
        status
        socialLinks {
          nextToken
        }
        deleted
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        accountLogoId
        accountCreatedById
      }
      nextToken
    }
  }
`;
export const activeAccounts = /* GraphQL */ `
  query ActiveAccounts(
    $deleted: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    activeAccounts(
      deleted: $deleted
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        logo {
          id
          fileurl
          alternativeText
          createdAt
          updatedAt
          mediaAttachmentsId
          attachmentCreatedById
        }
        siteTitle
        tagline
        description
        siteAddress
        defaultLanguage
        languages {
          nextToken
        }
        status
        socialLinks {
          nextToken
        }
        deleted
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        accountLogoId
        accountCreatedById
      }
      nextToken
    }
  }
`;
export const getSocialLink = /* GraphQL */ `
  query GetSocialLink($id: ID!) {
    getSocialLink(id: $id) {
      id
      name
      icon
      slug
      deleted
      createdAt
      createdBy {
        id
        courses {
          nextToken
        }
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        website
        zoneinfo
        verification_code
        group
        deleted
        createdAt
        createdBy
        updatedAt
      }
      updatedAt
      accountSocialLinksId
      socialLinkCreatedById
    }
  }
`;
export const listSocialLinks = /* GraphQL */ `
  query ListSocialLinks(
    $filter: ModelSocialLinkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSocialLinks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        icon
        slug
        deleted
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        accountSocialLinksId
        socialLinkCreatedById
      }
      nextToken
    }
  }
`;
export const activeLinks = /* GraphQL */ `
  query ActiveLinks(
    $deleted: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSocialLinkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    activeLinks(
      deleted: $deleted
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        icon
        slug
        deleted
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        accountSocialLinksId
        socialLinkCreatedById
      }
      nextToken
    }
  }
`;
export const getFeature = /* GraphQL */ `
  query GetFeature($id: ID!) {
    getFeature(id: $id) {
      id
      name
      icon
      slug
      isDashboard
      precedence
      deleted
      createdAt
      createdBy {
        id
        courses {
          nextToken
        }
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        website
        zoneinfo
        verification_code
        group
        deleted
        createdAt
        createdBy
        updatedAt
      }
      updatedAt
      featureCreatedById
    }
  }
`;
export const listFeatures = /* GraphQL */ `
  query ListFeatures(
    $filter: ModelFeatureFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFeatures(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        icon
        slug
        isDashboard
        precedence
        deleted
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        featureCreatedById
      }
      nextToken
    }
  }
`;
export const activeFeatures = /* GraphQL */ `
  query ActiveFeatures(
    $deleted: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFeatureFilterInput
    $limit: Int
    $nextToken: String
  ) {
    activeFeatures(
      deleted: $deleted
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        icon
        slug
        isDashboard
        precedence
        deleted
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        featureCreatedById
      }
      nextToken
    }
  }
`;
export const getLanguage = /* GraphQL */ `
  query GetLanguage($id: ID!) {
    getLanguage(id: $id) {
      id
      name
      code
      accounts {
        items {
          id
          accountID
          languageID
          createdAt
          updatedAt
        }
        nextToken
      }
      deleted
      createdAt
      createdBy {
        id
        courses {
          nextToken
        }
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        website
        zoneinfo
        verification_code
        group
        deleted
        createdAt
        createdBy
        updatedAt
      }
      updatedAt
      languageCreatedById
    }
  }
`;
export const listLanguages = /* GraphQL */ `
  query ListLanguages(
    $filter: ModelLanguageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLanguages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        code
        accounts {
          nextToken
        }
        deleted
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        languageCreatedById
      }
      nextToken
    }
  }
`;
export const activeLanguages = /* GraphQL */ `
  query ActiveLanguages(
    $deleted: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLanguageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    activeLanguages(
      deleted: $deleted
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        code
        accounts {
          nextToken
        }
        deleted
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        languageCreatedById
      }
      nextToken
    }
  }
`;
export const getMedia = /* GraphQL */ `
  query GetMedia($id: ID!) {
    getMedia(id: $id) {
      id
      fileurl
      filename
      filetype
      filesize
      alternativeText
      caption
      description
      attachments {
        items {
          id
          fileurl
          alternativeText
          createdAt
          updatedAt
          mediaAttachmentsId
          attachmentCreatedById
        }
        nextToken
      }
      deleted
      createdAt
      createdBy {
        id
        courses {
          nextToken
        }
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        website
        zoneinfo
        verification_code
        group
        deleted
        createdAt
        createdBy
        updatedAt
      }
      updatedAt
      mediaCreatedById
    }
  }
`;
export const listMedia = /* GraphQL */ `
  query ListMedia(
    $filter: ModelMediaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMedia(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        fileurl
        filename
        filetype
        filesize
        alternativeText
        caption
        description
        attachments {
          nextToken
        }
        deleted
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        mediaCreatedById
      }
      nextToken
    }
  }
`;
export const activeMedia = /* GraphQL */ `
  query ActiveMedia(
    $deleted: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMediaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    activeMedia(
      deleted: $deleted
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        fileurl
        filename
        filetype
        filesize
        alternativeText
        caption
        description
        attachments {
          nextToken
        }
        deleted
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        mediaCreatedById
      }
      nextToken
    }
  }
`;
export const getAttachment = /* GraphQL */ `
  query GetAttachment($id: ID!) {
    getAttachment(id: $id) {
      id
      fileurl
      alternativeText
      createdAt
      createdBy {
        id
        courses {
          nextToken
        }
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        website
        zoneinfo
        verification_code
        group
        deleted
        createdAt
        createdBy
        updatedAt
      }
      updatedAt
      mediaAttachmentsId
      attachmentCreatedById
    }
  }
`;
export const listAttachments = /* GraphQL */ `
  query ListAttachments(
    $filter: ModelAttachmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAttachments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        fileurl
        alternativeText
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        mediaAttachmentsId
        attachmentCreatedById
      }
      nextToken
    }
  }
`;
export const getMenu = /* GraphQL */ `
  query GetMenu($id: ID!) {
    getMenu(id: $id) {
      id
      name
      locations
      items {
        items {
          id
          type
          precedence
          deleted
          createdAt
          updatedAt
          menuItemsId
          menuItemPageId
          menuItemCreatedById
        }
        nextToken
      }
      deleted
      createdAt
      createdBy {
        id
        courses {
          nextToken
        }
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        website
        zoneinfo
        verification_code
        group
        deleted
        createdAt
        createdBy
        updatedAt
      }
      updatedAt
      menuCreatedById
    }
  }
`;
export const listMenus = /* GraphQL */ `
  query ListMenus(
    $filter: ModelMenuFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMenus(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        locations
        items {
          nextToken
        }
        deleted
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        menuCreatedById
      }
      nextToken
    }
  }
`;
export const activeMenus = /* GraphQL */ `
  query ActiveMenus(
    $deleted: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMenuFilterInput
    $limit: Int
    $nextToken: String
  ) {
    activeMenus(
      deleted: $deleted
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        locations
        items {
          nextToken
        }
        deleted
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        menuCreatedById
      }
      nextToken
    }
  }
`;
export const getMenuItem = /* GraphQL */ `
  query GetMenuItem($id: ID!) {
    getMenuItem(id: $id) {
      id
      type
      page {
        id
        title
        slug
        status
        content
        excerpt
        parentId
        thumbnail {
          id
          fileurl
          alternativeText
          createdAt
          updatedAt
          mediaAttachmentsId
          attachmentCreatedById
        }
        visibility
        deleted
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        pageThumbnailId
        pageCreatedById
      }
      precedence
      deleted
      createdAt
      createdBy {
        id
        courses {
          nextToken
        }
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        website
        zoneinfo
        verification_code
        group
        deleted
        createdAt
        createdBy
        updatedAt
      }
      updatedAt
      menuItemsId
      menuItemPageId
      menuItemCreatedById
    }
  }
`;
export const listMenuItems = /* GraphQL */ `
  query ListMenuItems(
    $filter: ModelMenuItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMenuItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        page {
          id
          title
          slug
          status
          content
          excerpt
          parentId
          visibility
          deleted
          createdAt
          updatedAt
          pageThumbnailId
          pageCreatedById
        }
        precedence
        deleted
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        menuItemsId
        menuItemPageId
        menuItemCreatedById
      }
      nextToken
    }
  }
`;
export const activeMenuItems = /* GraphQL */ `
  query ActiveMenuItems(
    $deleted: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMenuItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    activeMenuItems(
      deleted: $deleted
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        page {
          id
          title
          slug
          status
          content
          excerpt
          parentId
          visibility
          deleted
          createdAt
          updatedAt
          pageThumbnailId
          pageCreatedById
        }
        precedence
        deleted
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        menuItemsId
        menuItemPageId
        menuItemCreatedById
      }
      nextToken
    }
  }
`;
export const getPage = /* GraphQL */ `
  query GetPage($id: ID!) {
    getPage(id: $id) {
      id
      title
      slug
      status
      content
      excerpt
      parentId
      thumbnail {
        id
        fileurl
        alternativeText
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        mediaAttachmentsId
        attachmentCreatedById
      }
      visibility
      deleted
      createdAt
      createdBy {
        id
        courses {
          nextToken
        }
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        website
        zoneinfo
        verification_code
        group
        deleted
        createdAt
        createdBy
        updatedAt
      }
      updatedAt
      pageThumbnailId
      pageCreatedById
    }
  }
`;
export const listPages = /* GraphQL */ `
  query ListPages(
    $filter: ModelPageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        slug
        status
        content
        excerpt
        parentId
        thumbnail {
          id
          fileurl
          alternativeText
          createdAt
          updatedAt
          mediaAttachmentsId
          attachmentCreatedById
        }
        visibility
        deleted
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        pageThumbnailId
        pageCreatedById
      }
      nextToken
    }
  }
`;
export const activePages = /* GraphQL */ `
  query ActivePages(
    $deleted: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    activePages(
      deleted: $deleted
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        slug
        status
        content
        excerpt
        parentId
        thumbnail {
          id
          fileurl
          alternativeText
          createdAt
          updatedAt
          mediaAttachmentsId
          attachmentCreatedById
        }
        visibility
        deleted
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        pageThumbnailId
        pageCreatedById
      }
      nextToken
    }
  }
`;
export const getCategory = /* GraphQL */ `
  query GetCategory($id: ID!) {
    getCategory(id: $id) {
      id
      title
      slug
      type
      description
      thumbnail {
        id
        fileurl
        alternativeText
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        mediaAttachmentsId
        attachmentCreatedById
      }
      visibility
      posts {
        items {
          id
          categoryID
          postID
          createdAt
          updatedAt
        }
        nextToken
      }
      deleted
      createdAt
      createdBy {
        id
        courses {
          nextToken
        }
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        website
        zoneinfo
        verification_code
        group
        deleted
        createdAt
        createdBy
        updatedAt
      }
      updatedAt
      categoryThumbnailId
      categoryCreatedById
    }
  }
`;
export const listCategories = /* GraphQL */ `
  query ListCategories(
    $filter: ModelCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCategories(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        slug
        type
        description
        thumbnail {
          id
          fileurl
          alternativeText
          createdAt
          updatedAt
          mediaAttachmentsId
          attachmentCreatedById
        }
        visibility
        posts {
          nextToken
        }
        deleted
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        categoryThumbnailId
        categoryCreatedById
      }
      nextToken
    }
  }
`;
export const activeCategories = /* GraphQL */ `
  query ActiveCategories(
    $deleted: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    activeCategories(
      deleted: $deleted
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        slug
        type
        description
        thumbnail {
          id
          fileurl
          alternativeText
          createdAt
          updatedAt
          mediaAttachmentsId
          attachmentCreatedById
        }
        visibility
        posts {
          nextToken
        }
        deleted
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        categoryThumbnailId
        categoryCreatedById
      }
      nextToken
    }
  }
`;
export const getPost = /* GraphQL */ `
  query GetPost($id: ID!) {
    getPost(id: $id) {
      id
      title
      slug
      content
      excerpt
      status
      thumbnail {
        id
        fileurl
        alternativeText
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        mediaAttachmentsId
        attachmentCreatedById
      }
      visibility
      categories {
        items {
          id
          categoryID
          postID
          createdAt
          updatedAt
        }
        nextToken
      }
      deleted
      createdAt
      createdBy {
        id
        courses {
          nextToken
        }
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        website
        zoneinfo
        verification_code
        group
        deleted
        createdAt
        createdBy
        updatedAt
      }
      updatedAt
      postThumbnailId
      postCreatedById
    }
  }
`;
export const listPosts = /* GraphQL */ `
  query ListPosts(
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        slug
        content
        excerpt
        status
        thumbnail {
          id
          fileurl
          alternativeText
          createdAt
          updatedAt
          mediaAttachmentsId
          attachmentCreatedById
        }
        visibility
        categories {
          nextToken
        }
        deleted
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        postThumbnailId
        postCreatedById
      }
      nextToken
    }
  }
`;
export const activePosts = /* GraphQL */ `
  query ActivePosts(
    $deleted: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    activePosts(
      deleted: $deleted
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        slug
        content
        excerpt
        status
        thumbnail {
          id
          fileurl
          alternativeText
          createdAt
          updatedAt
          mediaAttachmentsId
          attachmentCreatedById
        }
        visibility
        categories {
          nextToken
        }
        deleted
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        postThumbnailId
        postCreatedById
      }
      nextToken
    }
  }
`;
export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      id
      name
      email
      subject
      message
      deleted
      createdAt
      updatedAt
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        email
        subject
        message
        deleted
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const activeMessages = /* GraphQL */ `
  query ActiveMessages(
    $deleted: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    activeMessages(
      deleted: $deleted
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        email
        subject
        message
        deleted
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSlide = /* GraphQL */ `
  query GetSlide($id: ID!) {
    getSlide(id: $id) {
      id
      title
      precedence
      content
      image {
        id
        fileurl
        alternativeText
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        mediaAttachmentsId
        attachmentCreatedById
      }
      mobImage {
        id
        fileurl
        alternativeText
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        mediaAttachmentsId
        attachmentCreatedById
      }
      deleted
      createdAt
      createdBy {
        id
        courses {
          nextToken
        }
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        website
        zoneinfo
        verification_code
        group
        deleted
        createdAt
        createdBy
        updatedAt
      }
      updatedAt
      slideImageId
      slideMobImageId
      slideCreatedById
    }
  }
`;
export const listSlides = /* GraphQL */ `
  query ListSlides(
    $filter: ModelSlideFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSlides(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        precedence
        content
        image {
          id
          fileurl
          alternativeText
          createdAt
          updatedAt
          mediaAttachmentsId
          attachmentCreatedById
        }
        mobImage {
          id
          fileurl
          alternativeText
          createdAt
          updatedAt
          mediaAttachmentsId
          attachmentCreatedById
        }
        deleted
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        slideImageId
        slideMobImageId
        slideCreatedById
      }
      nextToken
    }
  }
`;
export const acttiveSlides = /* GraphQL */ `
  query ActtiveSlides(
    $deleted: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSlideFilterInput
    $limit: Int
    $nextToken: String
  ) {
    acttiveSlides(
      deleted: $deleted
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        precedence
        content
        image {
          id
          fileurl
          alternativeText
          createdAt
          updatedAt
          mediaAttachmentsId
          attachmentCreatedById
        }
        mobImage {
          id
          fileurl
          alternativeText
          createdAt
          updatedAt
          mediaAttachmentsId
          attachmentCreatedById
        }
        deleted
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        slideImageId
        slideMobImageId
        slideCreatedById
      }
      nextToken
    }
  }
`;
export const getCourse = /* GraphQL */ `
  query GetCourse($id: ID!) {
    getCourse(id: $id) {
      id
      name
      slug
      description
      image {
        id
        fileurl
        alternativeText
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        mediaAttachmentsId
        attachmentCreatedById
      }
      modules {
        items {
          id
          name
          parentId
          content
          precedence
          prerequisite
          prerequisiteTitle
          afterModuleQuiz
          nextQuiz
          nextQuizTitle
          nextQuizSlug
          nextModule
          nextModuleTitle
          nextModuleId
          nextCourse
          nextCourseTitle
          nextCourseSlug
          deleted
          createdAt
          updatedAt
          courseModulesId
          moduleImageId
          moduleVrContentId
          moduleCreatedById
        }
        nextToken
      }
      students {
        items {
          id
          userID
          courseID
          createdAt
          updatedAt
        }
        nextToken
      }
      prerequisiteQuiz
      prerequisiteQuizTitle
      prerequisiteQuizSlug
      prerequisiteModule
      prerequisiteModuleTitle
      prerequisiteModuleId
      deleted
      createdAt
      createdBy {
        id
        courses {
          nextToken
        }
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        website
        zoneinfo
        verification_code
        group
        deleted
        createdAt
        createdBy
        updatedAt
      }
      updatedAt
      courseImageId
      courseCreatedById
    }
  }
`;
export const listCourses = /* GraphQL */ `
  query ListCourses(
    $filter: ModelCourseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCourses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        slug
        description
        image {
          id
          fileurl
          alternativeText
          createdAt
          updatedAt
          mediaAttachmentsId
          attachmentCreatedById
        }
        modules {
          nextToken
        }
        students {
          nextToken
        }
        prerequisiteQuiz
        prerequisiteQuizTitle
        prerequisiteQuizSlug
        prerequisiteModule
        prerequisiteModuleTitle
        prerequisiteModuleId
        deleted
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        courseImageId
        courseCreatedById
      }
      nextToken
    }
  }
`;
export const activeCourses = /* GraphQL */ `
  query ActiveCourses(
    $deleted: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCourseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    activeCourses(
      deleted: $deleted
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        slug
        description
        image {
          id
          fileurl
          alternativeText
          createdAt
          updatedAt
          mediaAttachmentsId
          attachmentCreatedById
        }
        modules {
          nextToken
        }
        students {
          nextToken
        }
        prerequisiteQuiz
        prerequisiteQuizTitle
        prerequisiteQuizSlug
        prerequisiteModule
        prerequisiteModuleTitle
        prerequisiteModuleId
        deleted
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        courseImageId
        courseCreatedById
      }
      nextToken
    }
  }
`;
export const getModule = /* GraphQL */ `
  query GetModule($id: ID!) {
    getModule(id: $id) {
      id
      name
      parentId
      content
      precedence
      image {
        id
        fileurl
        alternativeText
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        mediaAttachmentsId
        attachmentCreatedById
      }
      quizzes {
        items {
          id
          title
          slug
          description
          quizQuestions
          prerequisite
          prerequisiteTitle
          prerequisiteSlug
          prerequisiteModule
          prerequisiteModuleTitle
          nextQuiz
          nextQuizTitle
          nextQuizSlug
          nextCourse
          nextCourseTitle
          nextCourseSlug
          hasFeedback
          deleted
          createdAt
          updatedAt
          moduleQuizzesId
          quizTypeId
          quizCreatedById
        }
        nextToken
      }
      prerequisite
      prerequisiteTitle
      afterModuleQuiz
      nextQuiz
      nextQuizTitle
      nextQuizSlug
      nextModule
      nextModuleTitle
      nextModuleId
      nextCourse
      nextCourseTitle
      nextCourseSlug
      vrContent {
        id
        fileurl
        alternativeText
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        mediaAttachmentsId
        attachmentCreatedById
      }
      deleted
      createdAt
      createdBy {
        id
        courses {
          nextToken
        }
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        website
        zoneinfo
        verification_code
        group
        deleted
        createdAt
        createdBy
        updatedAt
      }
      updatedAt
      courseModulesId
      moduleImageId
      moduleVrContentId
      moduleCreatedById
    }
  }
`;
export const listModules = /* GraphQL */ `
  query ListModules(
    $filter: ModelModuleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listModules(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        parentId
        content
        precedence
        image {
          id
          fileurl
          alternativeText
          createdAt
          updatedAt
          mediaAttachmentsId
          attachmentCreatedById
        }
        quizzes {
          nextToken
        }
        prerequisite
        prerequisiteTitle
        afterModuleQuiz
        nextQuiz
        nextQuizTitle
        nextQuizSlug
        nextModule
        nextModuleTitle
        nextModuleId
        nextCourse
        nextCourseTitle
        nextCourseSlug
        vrContent {
          id
          fileurl
          alternativeText
          createdAt
          updatedAt
          mediaAttachmentsId
          attachmentCreatedById
        }
        deleted
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        courseModulesId
        moduleImageId
        moduleVrContentId
        moduleCreatedById
      }
      nextToken
    }
  }
`;
export const activeModules = /* GraphQL */ `
  query ActiveModules(
    $deleted: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelModuleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    activeModules(
      deleted: $deleted
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        parentId
        content
        precedence
        image {
          id
          fileurl
          alternativeText
          createdAt
          updatedAt
          mediaAttachmentsId
          attachmentCreatedById
        }
        quizzes {
          nextToken
        }
        prerequisite
        prerequisiteTitle
        afterModuleQuiz
        nextQuiz
        nextQuizTitle
        nextQuizSlug
        nextModule
        nextModuleTitle
        nextModuleId
        nextCourse
        nextCourseTitle
        nextCourseSlug
        vrContent {
          id
          fileurl
          alternativeText
          createdAt
          updatedAt
          mediaAttachmentsId
          attachmentCreatedById
        }
        deleted
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        courseModulesId
        moduleImageId
        moduleVrContentId
        moduleCreatedById
      }
      nextToken
    }
  }
`;
export const getModuleStatus = /* GraphQL */ `
  query GetModuleStatus($id: ID!) {
    getModuleStatus(id: $id) {
      id
      module {
        id
        name
        parentId
        content
        precedence
        image {
          id
          fileurl
          alternativeText
          createdAt
          updatedAt
          mediaAttachmentsId
          attachmentCreatedById
        }
        quizzes {
          nextToken
        }
        prerequisite
        prerequisiteTitle
        afterModuleQuiz
        nextQuiz
        nextQuizTitle
        nextQuizSlug
        nextModule
        nextModuleTitle
        nextModuleId
        nextCourse
        nextCourseTitle
        nextCourseSlug
        vrContent {
          id
          fileurl
          alternativeText
          createdAt
          updatedAt
          mediaAttachmentsId
          attachmentCreatedById
        }
        deleted
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        courseModulesId
        moduleImageId
        moduleVrContentId
        moduleCreatedById
      }
      student {
        id
        courses {
          nextToken
        }
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        website
        zoneinfo
        verification_code
        group
        deleted
        createdAt
        createdBy
        updatedAt
      }
      deleted
      isCompleted
      createdAt
      updatedAt
      moduleStatusModuleId
      moduleStatusStudentId
    }
  }
`;
export const listModuleStatuses = /* GraphQL */ `
  query ListModuleStatuses(
    $filter: ModelModuleStatusFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listModuleStatuses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        module {
          id
          name
          parentId
          content
          precedence
          prerequisite
          prerequisiteTitle
          afterModuleQuiz
          nextQuiz
          nextQuizTitle
          nextQuizSlug
          nextModule
          nextModuleTitle
          nextModuleId
          nextCourse
          nextCourseTitle
          nextCourseSlug
          deleted
          createdAt
          updatedAt
          courseModulesId
          moduleImageId
          moduleVrContentId
          moduleCreatedById
        }
        student {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        deleted
        isCompleted
        createdAt
        updatedAt
        moduleStatusModuleId
        moduleStatusStudentId
      }
      nextToken
    }
  }
`;
export const activeModuleStatus = /* GraphQL */ `
  query ActiveModuleStatus(
    $deleted: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelModuleStatusFilterInput
    $limit: Int
    $nextToken: String
  ) {
    activeModuleStatus(
      deleted: $deleted
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        module {
          id
          name
          parentId
          content
          precedence
          prerequisite
          prerequisiteTitle
          afterModuleQuiz
          nextQuiz
          nextQuizTitle
          nextQuizSlug
          nextModule
          nextModuleTitle
          nextModuleId
          nextCourse
          nextCourseTitle
          nextCourseSlug
          deleted
          createdAt
          updatedAt
          courseModulesId
          moduleImageId
          moduleVrContentId
          moduleCreatedById
        }
        student {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        deleted
        isCompleted
        createdAt
        updatedAt
        moduleStatusModuleId
        moduleStatusStudentId
      }
      nextToken
    }
  }
`;
export const getQuiz = /* GraphQL */ `
  query GetQuiz($id: ID!) {
    getQuiz(id: $id) {
      id
      title
      slug
      description
      type {
        id
        name
        deleted
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        quizTypeCreatedById
      }
      questions {
        items {
          id
          quizID
          questionID
          createdAt
          updatedAt
        }
        nextToken
      }
      quizQuestions
      prerequisite
      prerequisiteTitle
      prerequisiteSlug
      prerequisiteModule
      prerequisiteModuleTitle
      nextQuiz
      nextQuizTitle
      nextQuizSlug
      nextCourse
      nextCourseTitle
      nextCourseSlug
      hasFeedback
      deleted
      createdAt
      createdBy {
        id
        courses {
          nextToken
        }
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        website
        zoneinfo
        verification_code
        group
        deleted
        createdAt
        createdBy
        updatedAt
      }
      updatedAt
      moduleQuizzesId
      quizTypeId
      quizCreatedById
    }
  }
`;
export const listQuizzes = /* GraphQL */ `
  query ListQuizzes(
    $filter: ModelQuizFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuizzes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        slug
        description
        type {
          id
          name
          deleted
          createdAt
          updatedAt
          quizTypeCreatedById
        }
        questions {
          nextToken
        }
        quizQuestions
        prerequisite
        prerequisiteTitle
        prerequisiteSlug
        prerequisiteModule
        prerequisiteModuleTitle
        nextQuiz
        nextQuizTitle
        nextQuizSlug
        nextCourse
        nextCourseTitle
        nextCourseSlug
        hasFeedback
        deleted
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        moduleQuizzesId
        quizTypeId
        quizCreatedById
      }
      nextToken
    }
  }
`;
export const activeQuizzes = /* GraphQL */ `
  query ActiveQuizzes(
    $deleted: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelQuizFilterInput
    $limit: Int
    $nextToken: String
  ) {
    activeQuizzes(
      deleted: $deleted
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        slug
        description
        type {
          id
          name
          deleted
          createdAt
          updatedAt
          quizTypeCreatedById
        }
        questions {
          nextToken
        }
        quizQuestions
        prerequisite
        prerequisiteTitle
        prerequisiteSlug
        prerequisiteModule
        prerequisiteModuleTitle
        nextQuiz
        nextQuizTitle
        nextQuizSlug
        nextCourse
        nextCourseTitle
        nextCourseSlug
        hasFeedback
        deleted
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        moduleQuizzesId
        quizTypeId
        quizCreatedById
      }
      nextToken
    }
  }
`;
export const getQuizType = /* GraphQL */ `
  query GetQuizType($id: ID!) {
    getQuizType(id: $id) {
      id
      name
      deleted
      createdAt
      createdBy {
        id
        courses {
          nextToken
        }
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        website
        zoneinfo
        verification_code
        group
        deleted
        createdAt
        createdBy
        updatedAt
      }
      updatedAt
      quizTypeCreatedById
    }
  }
`;
export const listQuizTypes = /* GraphQL */ `
  query ListQuizTypes(
    $filter: ModelQuizTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuizTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        deleted
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        quizTypeCreatedById
      }
      nextToken
    }
  }
`;
export const activeQuizTypes = /* GraphQL */ `
  query ActiveQuizTypes(
    $deleted: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelQuizTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    activeQuizTypes(
      deleted: $deleted
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        deleted
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        quizTypeCreatedById
      }
      nextToken
    }
  }
`;
export const getQuizStatus = /* GraphQL */ `
  query GetQuizStatus($id: ID!) {
    getQuizStatus(id: $id) {
      id
      quiz {
        id
        title
        slug
        description
        type {
          id
          name
          deleted
          createdAt
          updatedAt
          quizTypeCreatedById
        }
        questions {
          nextToken
        }
        quizQuestions
        prerequisite
        prerequisiteTitle
        prerequisiteSlug
        prerequisiteModule
        prerequisiteModuleTitle
        nextQuiz
        nextQuizTitle
        nextQuizSlug
        nextCourse
        nextCourseTitle
        nextCourseSlug
        hasFeedback
        deleted
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        moduleQuizzesId
        quizTypeId
        quizCreatedById
      }
      student {
        id
        courses {
          nextToken
        }
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        website
        zoneinfo
        verification_code
        group
        deleted
        createdAt
        createdBy
        updatedAt
      }
      isCompleted
      totalScore
      studentScore
      deleted
      createdAt
      updatedAt
      quizStatusQuizId
      quizStatusStudentId
    }
  }
`;
export const listQuizStatuses = /* GraphQL */ `
  query ListQuizStatuses(
    $filter: ModelQuizStatusFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuizStatuses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        quiz {
          id
          title
          slug
          description
          quizQuestions
          prerequisite
          prerequisiteTitle
          prerequisiteSlug
          prerequisiteModule
          prerequisiteModuleTitle
          nextQuiz
          nextQuizTitle
          nextQuizSlug
          nextCourse
          nextCourseTitle
          nextCourseSlug
          hasFeedback
          deleted
          createdAt
          updatedAt
          moduleQuizzesId
          quizTypeId
          quizCreatedById
        }
        student {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        isCompleted
        totalScore
        studentScore
        deleted
        createdAt
        updatedAt
        quizStatusQuizId
        quizStatusStudentId
      }
      nextToken
    }
  }
`;
export const activeQuizStatus = /* GraphQL */ `
  query ActiveQuizStatus(
    $deleted: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelQuizStatusFilterInput
    $limit: Int
    $nextToken: String
  ) {
    activeQuizStatus(
      deleted: $deleted
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        quiz {
          id
          title
          slug
          description
          quizQuestions
          prerequisite
          prerequisiteTitle
          prerequisiteSlug
          prerequisiteModule
          prerequisiteModuleTitle
          nextQuiz
          nextQuizTitle
          nextQuizSlug
          nextCourse
          nextCourseTitle
          nextCourseSlug
          hasFeedback
          deleted
          createdAt
          updatedAt
          moduleQuizzesId
          quizTypeId
          quizCreatedById
        }
        student {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        isCompleted
        totalScore
        studentScore
        deleted
        createdAt
        updatedAt
        quizStatusQuizId
        quizStatusStudentId
      }
      nextToken
    }
  }
`;
export const getQuestionType = /* GraphQL */ `
  query GetQuestionType($id: ID!) {
    getQuestionType(id: $id) {
      id
      name
      deleted
      createdAt
      createdBy {
        id
        courses {
          nextToken
        }
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        website
        zoneinfo
        verification_code
        group
        deleted
        createdAt
        createdBy
        updatedAt
      }
      updatedAt
      questionTypeCreatedById
    }
  }
`;
export const listQuestionTypes = /* GraphQL */ `
  query ListQuestionTypes(
    $filter: ModelQuestionTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestionTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        deleted
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        questionTypeCreatedById
      }
      nextToken
    }
  }
`;
export const activeQuestionTypes = /* GraphQL */ `
  query ActiveQuestionTypes(
    $deleted: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    activeQuestionTypes(
      deleted: $deleted
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        deleted
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        questionTypeCreatedById
      }
      nextToken
    }
  }
`;
export const getQuestion = /* GraphQL */ `
  query GetQuestion($id: ID!) {
    getQuestion(id: $id) {
      id
      title
      type {
        id
        name
        deleted
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        questionTypeCreatedById
      }
      correctAnswer
      options
      image {
        id
        fileurl
        alternativeText
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        mediaAttachmentsId
        attachmentCreatedById
      }
      quizzes {
        items {
          id
          quizID
          questionID
          createdAt
          updatedAt
        }
        nextToken
      }
      deleted
      createdAt
      createdBy {
        id
        courses {
          nextToken
        }
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        website
        zoneinfo
        verification_code
        group
        deleted
        createdAt
        createdBy
        updatedAt
      }
      updatedAt
      questionTypeId
      questionImageId
      questionCreatedById
    }
  }
`;
export const listQuestions = /* GraphQL */ `
  query ListQuestions(
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        type {
          id
          name
          deleted
          createdAt
          updatedAt
          questionTypeCreatedById
        }
        correctAnswer
        options
        image {
          id
          fileurl
          alternativeText
          createdAt
          updatedAt
          mediaAttachmentsId
          attachmentCreatedById
        }
        quizzes {
          nextToken
        }
        deleted
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        questionTypeId
        questionImageId
        questionCreatedById
      }
      nextToken
    }
  }
`;
export const activeQuestions = /* GraphQL */ `
  query ActiveQuestions(
    $deleted: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    activeQuestions(
      deleted: $deleted
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        type {
          id
          name
          deleted
          createdAt
          updatedAt
          questionTypeCreatedById
        }
        correctAnswer
        options
        image {
          id
          fileurl
          alternativeText
          createdAt
          updatedAt
          mediaAttachmentsId
          attachmentCreatedById
        }
        quizzes {
          nextToken
        }
        deleted
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        questionTypeId
        questionImageId
        questionCreatedById
      }
      nextToken
    }
  }
`;
export const getAnswer = /* GraphQL */ `
  query GetAnswer($id: ID!) {
    getAnswer(id: $id) {
      id
      question {
        id
        title
        type {
          id
          name
          deleted
          createdAt
          updatedAt
          questionTypeCreatedById
        }
        correctAnswer
        options
        image {
          id
          fileurl
          alternativeText
          createdAt
          updatedAt
          mediaAttachmentsId
          attachmentCreatedById
        }
        quizzes {
          nextToken
        }
        deleted
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        questionTypeId
        questionImageId
        questionCreatedById
      }
      student {
        id
        courses {
          nextToken
        }
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        website
        zoneinfo
        verification_code
        group
        deleted
        createdAt
        createdBy
        updatedAt
      }
      values
      questionID
      studentID
      deleted
      isCorrect
      createdAt
      updatedAt
      answerQuestionId
      answerStudentId
    }
  }
`;
export const listAnswers = /* GraphQL */ `
  query ListAnswers(
    $filter: ModelAnswerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAnswers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        question {
          id
          title
          correctAnswer
          options
          deleted
          createdAt
          updatedAt
          questionTypeId
          questionImageId
          questionCreatedById
        }
        student {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        values
        questionID
        studentID
        deleted
        isCorrect
        createdAt
        updatedAt
        answerQuestionId
        answerStudentId
      }
      nextToken
    }
  }
`;
export const questionAnswer = /* GraphQL */ `
  query QuestionAnswer(
    $questionID: String!
    $studentID: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAnswerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    questionAnswer(
      questionID: $questionID
      studentID: $studentID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        question {
          id
          title
          correctAnswer
          options
          deleted
          createdAt
          updatedAt
          questionTypeId
          questionImageId
          questionCreatedById
        }
        student {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        values
        questionID
        studentID
        deleted
        isCorrect
        createdAt
        updatedAt
        answerQuestionId
        answerStudentId
      }
      nextToken
    }
  }
`;
export const studentAnswer = /* GraphQL */ `
  query StudentAnswer(
    $studentID: String!
    $questionID: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAnswerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    studentAnswer(
      studentID: $studentID
      questionID: $questionID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        question {
          id
          title
          correctAnswer
          options
          deleted
          createdAt
          updatedAt
          questionTypeId
          questionImageId
          questionCreatedById
        }
        student {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        values
        questionID
        studentID
        deleted
        isCorrect
        createdAt
        updatedAt
        answerQuestionId
        answerStudentId
      }
      nextToken
    }
  }
`;
export const activeAnswers = /* GraphQL */ `
  query ActiveAnswers(
    $deleted: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAnswerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    activeAnswers(
      deleted: $deleted
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        question {
          id
          title
          correctAnswer
          options
          deleted
          createdAt
          updatedAt
          questionTypeId
          questionImageId
          questionCreatedById
        }
        student {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        values
        questionID
        studentID
        deleted
        isCorrect
        createdAt
        updatedAt
        answerQuestionId
        answerStudentId
      }
      nextToken
    }
  }
`;
export const getCourseStudent = /* GraphQL */ `
  query GetCourseStudent($id: ID!) {
    getCourseStudent(id: $id) {
      id
      userID
      courseID
      user {
        id
        courses {
          nextToken
        }
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        website
        zoneinfo
        verification_code
        group
        deleted
        createdAt
        createdBy
        updatedAt
      }
      course {
        id
        name
        slug
        description
        image {
          id
          fileurl
          alternativeText
          createdAt
          updatedAt
          mediaAttachmentsId
          attachmentCreatedById
        }
        modules {
          nextToken
        }
        students {
          nextToken
        }
        prerequisiteQuiz
        prerequisiteQuizTitle
        prerequisiteQuizSlug
        prerequisiteModule
        prerequisiteModuleTitle
        prerequisiteModuleId
        deleted
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        courseImageId
        courseCreatedById
      }
      createdAt
      updatedAt
    }
  }
`;
export const listCourseStudents = /* GraphQL */ `
  query ListCourseStudents(
    $filter: ModelCourseStudentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCourseStudents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        courseID
        user {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        course {
          id
          name
          slug
          description
          prerequisiteQuiz
          prerequisiteQuizTitle
          prerequisiteQuizSlug
          prerequisiteModule
          prerequisiteModuleTitle
          prerequisiteModuleId
          deleted
          createdAt
          updatedAt
          courseImageId
          courseCreatedById
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAccountLanguage = /* GraphQL */ `
  query GetAccountLanguage($id: ID!) {
    getAccountLanguage(id: $id) {
      id
      accountID
      languageID
      account {
        id
        logo {
          id
          fileurl
          alternativeText
          createdAt
          updatedAt
          mediaAttachmentsId
          attachmentCreatedById
        }
        siteTitle
        tagline
        description
        siteAddress
        defaultLanguage
        languages {
          nextToken
        }
        status
        socialLinks {
          nextToken
        }
        deleted
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        accountLogoId
        accountCreatedById
      }
      language {
        id
        name
        code
        accounts {
          nextToken
        }
        deleted
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        languageCreatedById
      }
      createdAt
      updatedAt
    }
  }
`;
export const listAccountLanguages = /* GraphQL */ `
  query ListAccountLanguages(
    $filter: ModelAccountLanguageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAccountLanguages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accountID
        languageID
        account {
          id
          siteTitle
          tagline
          description
          siteAddress
          defaultLanguage
          status
          deleted
          createdAt
          updatedAt
          accountLogoId
          accountCreatedById
        }
        language {
          id
          name
          code
          deleted
          createdAt
          updatedAt
          languageCreatedById
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPostCategory = /* GraphQL */ `
  query GetPostCategory($id: ID!) {
    getPostCategory(id: $id) {
      id
      categoryID
      postID
      category {
        id
        title
        slug
        type
        description
        thumbnail {
          id
          fileurl
          alternativeText
          createdAt
          updatedAt
          mediaAttachmentsId
          attachmentCreatedById
        }
        visibility
        posts {
          nextToken
        }
        deleted
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        categoryThumbnailId
        categoryCreatedById
      }
      post {
        id
        title
        slug
        content
        excerpt
        status
        thumbnail {
          id
          fileurl
          alternativeText
          createdAt
          updatedAt
          mediaAttachmentsId
          attachmentCreatedById
        }
        visibility
        categories {
          nextToken
        }
        deleted
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        postThumbnailId
        postCreatedById
      }
      createdAt
      updatedAt
    }
  }
`;
export const listPostCategories = /* GraphQL */ `
  query ListPostCategories(
    $filter: ModelPostCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPostCategories(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        categoryID
        postID
        category {
          id
          title
          slug
          type
          description
          visibility
          deleted
          createdAt
          updatedAt
          categoryThumbnailId
          categoryCreatedById
        }
        post {
          id
          title
          slug
          content
          excerpt
          status
          visibility
          deleted
          createdAt
          updatedAt
          postThumbnailId
          postCreatedById
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getQuizQuestion = /* GraphQL */ `
  query GetQuizQuestion($id: ID!) {
    getQuizQuestion(id: $id) {
      id
      quizID
      questionID
      quiz {
        id
        title
        slug
        description
        type {
          id
          name
          deleted
          createdAt
          updatedAt
          quizTypeCreatedById
        }
        questions {
          nextToken
        }
        quizQuestions
        prerequisite
        prerequisiteTitle
        prerequisiteSlug
        prerequisiteModule
        prerequisiteModuleTitle
        nextQuiz
        nextQuizTitle
        nextQuizSlug
        nextCourse
        nextCourseTitle
        nextCourseSlug
        hasFeedback
        deleted
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        moduleQuizzesId
        quizTypeId
        quizCreatedById
      }
      question {
        id
        title
        type {
          id
          name
          deleted
          createdAt
          updatedAt
          questionTypeCreatedById
        }
        correctAnswer
        options
        image {
          id
          fileurl
          alternativeText
          createdAt
          updatedAt
          mediaAttachmentsId
          attachmentCreatedById
        }
        quizzes {
          nextToken
        }
        deleted
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        questionTypeId
        questionImageId
        questionCreatedById
      }
      createdAt
      updatedAt
    }
  }
`;
export const listQuizQuestions = /* GraphQL */ `
  query ListQuizQuestions(
    $filter: ModelQuizQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuizQuestions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        quizID
        questionID
        quiz {
          id
          title
          slug
          description
          quizQuestions
          prerequisite
          prerequisiteTitle
          prerequisiteSlug
          prerequisiteModule
          prerequisiteModuleTitle
          nextQuiz
          nextQuizTitle
          nextQuizSlug
          nextCourse
          nextCourseTitle
          nextCourseSlug
          hasFeedback
          deleted
          createdAt
          updatedAt
          moduleQuizzesId
          quizTypeId
          quizCreatedById
        }
        question {
          id
          title
          correctAnswer
          options
          deleted
          createdAt
          updatedAt
          questionTypeId
          questionImageId
          questionCreatedById
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
