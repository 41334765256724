import { Slide } from "../../models/api";

// actions
const SET = "slides/SET";
const SET_SEARCH_TEXT = "slides/SET_SEARCH_TEXT";
const SET_DATES = "slides/SET_DATES";

const DEFAULT_STATE = {
  slides: [],
  searchText: "",
  datesFilter: [null, null],
};

// reducer
const slideReducer = (state = DEFAULT_STATE, action: any = {}) => {
  switch (action.type) {
    case SET:
      return Object.assign({}, state, { slides: action.slides });
    case SET_SEARCH_TEXT:
      return Object.assign({}, state, { searchText: action.searchText });
    case SET_DATES:
      return Object.assign({}, state, { datesFilter: action.dates });
    default:
      return state;
  }
};

export default slideReducer;

// action creators
export const setSlides = (slides: Slide[]) => {
  return { slides, type: SET };
};

export const setSearchText = (searchText: string) => {
  return { searchText, type: SET_SEARCH_TEXT };
};

export const setDates = (dates: any[]) => {
  return { dates, type: SET_DATES };
};
