// actions
const SET = "menus/SET";
const SET_SELECTED = "menus/SET_SELECTED";
const SET_ITEMS = "menus/SET_ITEMS";

const DEFAULT_STATE = {
  menus: [],
  selectedMenu: null,
  items: [],
};

// reducer
const menuReducer = (state = DEFAULT_STATE, action: any = {}) => {
  switch (action.type) {
    case SET:
      return Object.assign({}, state, { menus: action.menus });
    case SET_SELECTED:
      return Object.assign({}, state, { selectedMenu: action.menu });
    case SET_ITEMS:
      return Object.assign({}, state, { items: action.items });
    default:
      return state;
  }
};

export default menuReducer;

// action creators
export const setMenus = (menus: any[]) => {
  return { menus, type: SET };
};

export const setItems = (items: any[]) => {
  return { items, type: SET_ITEMS };
};

export const setSelectedMenu = (menu: any) => {
  return { menu, type: SET_SELECTED };
};
