import { Module } from "../../models/api";

// actions
const SET = "modules/SET";
const SET_SEARCH_TEXT = "modules/SET_SEARCH_TEXT";
const SET_DATES = "modules/SET_DATES";

const DEFAULT_STATE = {
  modules: [],
  searchText: "",
  datesFilter: [null, null],
};

// reducer
const moduleReducer = (state = DEFAULT_STATE, action: any = {}) => {
  switch (action.type) {
    case SET:
      return Object.assign({}, state, { modules: action.modules });
    case SET_SEARCH_TEXT:
      return Object.assign({}, state, { searchText: action.searchText });
    case SET_DATES:
      return Object.assign({}, state, { datesFilter: action.dates });
    default:
      return state;
  }
};

export default moduleReducer;

// action creators
export const setModules = (modules: Module[]) => {
  return { modules, type: SET };
};

export const setSearchText = (searchText: string) => {
  return { searchText, type: SET_SEARCH_TEXT };
};

export const setDates = (dates: any[]) => {
  return { dates, type: SET_DATES };
};
