/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      courses {
        items {
          id
          userID
          courseID
          createdAt
          updatedAt
        }
        nextToken
      }
      username
      preferred_username
      email
      email_verified
      phone_number
      phone_number_verified
      name
      nickname
      given_name
      middle_name
      family_name
      address
      birthdate
      gender
      locale
      picture
      website
      zoneinfo
      verification_code
      group
      deleted
      createdAt
      createdBy
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      courses {
        items {
          id
          userID
          courseID
          createdAt
          updatedAt
        }
        nextToken
      }
      username
      preferred_username
      email
      email_verified
      phone_number
      phone_number_verified
      name
      nickname
      given_name
      middle_name
      family_name
      address
      birthdate
      gender
      locale
      picture
      website
      zoneinfo
      verification_code
      group
      deleted
      createdAt
      createdBy
      updatedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      courses {
        items {
          id
          userID
          courseID
          createdAt
          updatedAt
        }
        nextToken
      }
      username
      preferred_username
      email
      email_verified
      phone_number
      phone_number_verified
      name
      nickname
      given_name
      middle_name
      family_name
      address
      birthdate
      gender
      locale
      picture
      website
      zoneinfo
      verification_code
      group
      deleted
      createdAt
      createdBy
      updatedAt
    }
  }
`;
export const createEmailVerification = /* GraphQL */ `
  mutation CreateEmailVerification(
    $input: CreateEmailVerificationInput!
    $condition: ModelEmailVerificationConditionInput
  ) {
    createEmailVerification(input: $input, condition: $condition) {
      id
      name
      email
      verification_code
      createdAt
      deleted
      updatedAt
    }
  }
`;
export const updateEmailVerification = /* GraphQL */ `
  mutation UpdateEmailVerification(
    $input: UpdateEmailVerificationInput!
    $condition: ModelEmailVerificationConditionInput
  ) {
    updateEmailVerification(input: $input, condition: $condition) {
      id
      name
      email
      verification_code
      createdAt
      deleted
      updatedAt
    }
  }
`;
export const deleteEmailVerification = /* GraphQL */ `
  mutation DeleteEmailVerification(
    $input: DeleteEmailVerificationInput!
    $condition: ModelEmailVerificationConditionInput
  ) {
    deleteEmailVerification(input: $input, condition: $condition) {
      id
      name
      email
      verification_code
      createdAt
      deleted
      updatedAt
    }
  }
`;
export const createAccount = /* GraphQL */ `
  mutation CreateAccount(
    $input: CreateAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    createAccount(input: $input, condition: $condition) {
      id
      logo {
        id
        fileurl
        alternativeText
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        mediaAttachmentsId
        attachmentCreatedById
      }
      siteTitle
      tagline
      description
      siteAddress
      defaultLanguage
      languages {
        items {
          id
          accountID
          languageID
          createdAt
          updatedAt
        }
        nextToken
      }
      status
      socialLinks {
        items {
          id
          name
          icon
          slug
          deleted
          createdAt
          updatedAt
          accountSocialLinksId
          socialLinkCreatedById
        }
        nextToken
      }
      deleted
      createdAt
      createdBy {
        id
        courses {
          nextToken
        }
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        website
        zoneinfo
        verification_code
        group
        deleted
        createdAt
        createdBy
        updatedAt
      }
      updatedAt
      accountLogoId
      accountCreatedById
    }
  }
`;
export const updateAccount = /* GraphQL */ `
  mutation UpdateAccount(
    $input: UpdateAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    updateAccount(input: $input, condition: $condition) {
      id
      logo {
        id
        fileurl
        alternativeText
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        mediaAttachmentsId
        attachmentCreatedById
      }
      siteTitle
      tagline
      description
      siteAddress
      defaultLanguage
      languages {
        items {
          id
          accountID
          languageID
          createdAt
          updatedAt
        }
        nextToken
      }
      status
      socialLinks {
        items {
          id
          name
          icon
          slug
          deleted
          createdAt
          updatedAt
          accountSocialLinksId
          socialLinkCreatedById
        }
        nextToken
      }
      deleted
      createdAt
      createdBy {
        id
        courses {
          nextToken
        }
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        website
        zoneinfo
        verification_code
        group
        deleted
        createdAt
        createdBy
        updatedAt
      }
      updatedAt
      accountLogoId
      accountCreatedById
    }
  }
`;
export const deleteAccount = /* GraphQL */ `
  mutation DeleteAccount(
    $input: DeleteAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    deleteAccount(input: $input, condition: $condition) {
      id
      logo {
        id
        fileurl
        alternativeText
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        mediaAttachmentsId
        attachmentCreatedById
      }
      siteTitle
      tagline
      description
      siteAddress
      defaultLanguage
      languages {
        items {
          id
          accountID
          languageID
          createdAt
          updatedAt
        }
        nextToken
      }
      status
      socialLinks {
        items {
          id
          name
          icon
          slug
          deleted
          createdAt
          updatedAt
          accountSocialLinksId
          socialLinkCreatedById
        }
        nextToken
      }
      deleted
      createdAt
      createdBy {
        id
        courses {
          nextToken
        }
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        website
        zoneinfo
        verification_code
        group
        deleted
        createdAt
        createdBy
        updatedAt
      }
      updatedAt
      accountLogoId
      accountCreatedById
    }
  }
`;
export const createSocialLink = /* GraphQL */ `
  mutation CreateSocialLink(
    $input: CreateSocialLinkInput!
    $condition: ModelSocialLinkConditionInput
  ) {
    createSocialLink(input: $input, condition: $condition) {
      id
      name
      icon
      slug
      deleted
      createdAt
      createdBy {
        id
        courses {
          nextToken
        }
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        website
        zoneinfo
        verification_code
        group
        deleted
        createdAt
        createdBy
        updatedAt
      }
      updatedAt
      accountSocialLinksId
      socialLinkCreatedById
    }
  }
`;
export const updateSocialLink = /* GraphQL */ `
  mutation UpdateSocialLink(
    $input: UpdateSocialLinkInput!
    $condition: ModelSocialLinkConditionInput
  ) {
    updateSocialLink(input: $input, condition: $condition) {
      id
      name
      icon
      slug
      deleted
      createdAt
      createdBy {
        id
        courses {
          nextToken
        }
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        website
        zoneinfo
        verification_code
        group
        deleted
        createdAt
        createdBy
        updatedAt
      }
      updatedAt
      accountSocialLinksId
      socialLinkCreatedById
    }
  }
`;
export const deleteSocialLink = /* GraphQL */ `
  mutation DeleteSocialLink(
    $input: DeleteSocialLinkInput!
    $condition: ModelSocialLinkConditionInput
  ) {
    deleteSocialLink(input: $input, condition: $condition) {
      id
      name
      icon
      slug
      deleted
      createdAt
      createdBy {
        id
        courses {
          nextToken
        }
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        website
        zoneinfo
        verification_code
        group
        deleted
        createdAt
        createdBy
        updatedAt
      }
      updatedAt
      accountSocialLinksId
      socialLinkCreatedById
    }
  }
`;
export const createFeature = /* GraphQL */ `
  mutation CreateFeature(
    $input: CreateFeatureInput!
    $condition: ModelFeatureConditionInput
  ) {
    createFeature(input: $input, condition: $condition) {
      id
      name
      icon
      slug
      isDashboard
      precedence
      deleted
      createdAt
      createdBy {
        id
        courses {
          nextToken
        }
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        website
        zoneinfo
        verification_code
        group
        deleted
        createdAt
        createdBy
        updatedAt
      }
      updatedAt
      featureCreatedById
    }
  }
`;
export const updateFeature = /* GraphQL */ `
  mutation UpdateFeature(
    $input: UpdateFeatureInput!
    $condition: ModelFeatureConditionInput
  ) {
    updateFeature(input: $input, condition: $condition) {
      id
      name
      icon
      slug
      isDashboard
      precedence
      deleted
      createdAt
      createdBy {
        id
        courses {
          nextToken
        }
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        website
        zoneinfo
        verification_code
        group
        deleted
        createdAt
        createdBy
        updatedAt
      }
      updatedAt
      featureCreatedById
    }
  }
`;
export const deleteFeature = /* GraphQL */ `
  mutation DeleteFeature(
    $input: DeleteFeatureInput!
    $condition: ModelFeatureConditionInput
  ) {
    deleteFeature(input: $input, condition: $condition) {
      id
      name
      icon
      slug
      isDashboard
      precedence
      deleted
      createdAt
      createdBy {
        id
        courses {
          nextToken
        }
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        website
        zoneinfo
        verification_code
        group
        deleted
        createdAt
        createdBy
        updatedAt
      }
      updatedAt
      featureCreatedById
    }
  }
`;
export const createLanguage = /* GraphQL */ `
  mutation CreateLanguage(
    $input: CreateLanguageInput!
    $condition: ModelLanguageConditionInput
  ) {
    createLanguage(input: $input, condition: $condition) {
      id
      name
      code
      accounts {
        items {
          id
          accountID
          languageID
          createdAt
          updatedAt
        }
        nextToken
      }
      deleted
      createdAt
      createdBy {
        id
        courses {
          nextToken
        }
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        website
        zoneinfo
        verification_code
        group
        deleted
        createdAt
        createdBy
        updatedAt
      }
      updatedAt
      languageCreatedById
    }
  }
`;
export const updateLanguage = /* GraphQL */ `
  mutation UpdateLanguage(
    $input: UpdateLanguageInput!
    $condition: ModelLanguageConditionInput
  ) {
    updateLanguage(input: $input, condition: $condition) {
      id
      name
      code
      accounts {
        items {
          id
          accountID
          languageID
          createdAt
          updatedAt
        }
        nextToken
      }
      deleted
      createdAt
      createdBy {
        id
        courses {
          nextToken
        }
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        website
        zoneinfo
        verification_code
        group
        deleted
        createdAt
        createdBy
        updatedAt
      }
      updatedAt
      languageCreatedById
    }
  }
`;
export const deleteLanguage = /* GraphQL */ `
  mutation DeleteLanguage(
    $input: DeleteLanguageInput!
    $condition: ModelLanguageConditionInput
  ) {
    deleteLanguage(input: $input, condition: $condition) {
      id
      name
      code
      accounts {
        items {
          id
          accountID
          languageID
          createdAt
          updatedAt
        }
        nextToken
      }
      deleted
      createdAt
      createdBy {
        id
        courses {
          nextToken
        }
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        website
        zoneinfo
        verification_code
        group
        deleted
        createdAt
        createdBy
        updatedAt
      }
      updatedAt
      languageCreatedById
    }
  }
`;
export const createMedia = /* GraphQL */ `
  mutation CreateMedia(
    $input: CreateMediaInput!
    $condition: ModelMediaConditionInput
  ) {
    createMedia(input: $input, condition: $condition) {
      id
      fileurl
      filename
      filetype
      filesize
      alternativeText
      caption
      description
      attachments {
        items {
          id
          fileurl
          alternativeText
          createdAt
          updatedAt
          mediaAttachmentsId
          attachmentCreatedById
        }
        nextToken
      }
      deleted
      createdAt
      createdBy {
        id
        courses {
          nextToken
        }
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        website
        zoneinfo
        verification_code
        group
        deleted
        createdAt
        createdBy
        updatedAt
      }
      updatedAt
      mediaCreatedById
    }
  }
`;
export const updateMedia = /* GraphQL */ `
  mutation UpdateMedia(
    $input: UpdateMediaInput!
    $condition: ModelMediaConditionInput
  ) {
    updateMedia(input: $input, condition: $condition) {
      id
      fileurl
      filename
      filetype
      filesize
      alternativeText
      caption
      description
      attachments {
        items {
          id
          fileurl
          alternativeText
          createdAt
          updatedAt
          mediaAttachmentsId
          attachmentCreatedById
        }
        nextToken
      }
      deleted
      createdAt
      createdBy {
        id
        courses {
          nextToken
        }
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        website
        zoneinfo
        verification_code
        group
        deleted
        createdAt
        createdBy
        updatedAt
      }
      updatedAt
      mediaCreatedById
    }
  }
`;
export const deleteMedia = /* GraphQL */ `
  mutation DeleteMedia(
    $input: DeleteMediaInput!
    $condition: ModelMediaConditionInput
  ) {
    deleteMedia(input: $input, condition: $condition) {
      id
      fileurl
      filename
      filetype
      filesize
      alternativeText
      caption
      description
      attachments {
        items {
          id
          fileurl
          alternativeText
          createdAt
          updatedAt
          mediaAttachmentsId
          attachmentCreatedById
        }
        nextToken
      }
      deleted
      createdAt
      createdBy {
        id
        courses {
          nextToken
        }
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        website
        zoneinfo
        verification_code
        group
        deleted
        createdAt
        createdBy
        updatedAt
      }
      updatedAt
      mediaCreatedById
    }
  }
`;
export const createAttachment = /* GraphQL */ `
  mutation CreateAttachment(
    $input: CreateAttachmentInput!
    $condition: ModelAttachmentConditionInput
  ) {
    createAttachment(input: $input, condition: $condition) {
      id
      fileurl
      alternativeText
      createdAt
      createdBy {
        id
        courses {
          nextToken
        }
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        website
        zoneinfo
        verification_code
        group
        deleted
        createdAt
        createdBy
        updatedAt
      }
      updatedAt
      mediaAttachmentsId
      attachmentCreatedById
    }
  }
`;
export const updateAttachment = /* GraphQL */ `
  mutation UpdateAttachment(
    $input: UpdateAttachmentInput!
    $condition: ModelAttachmentConditionInput
  ) {
    updateAttachment(input: $input, condition: $condition) {
      id
      fileurl
      alternativeText
      createdAt
      createdBy {
        id
        courses {
          nextToken
        }
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        website
        zoneinfo
        verification_code
        group
        deleted
        createdAt
        createdBy
        updatedAt
      }
      updatedAt
      mediaAttachmentsId
      attachmentCreatedById
    }
  }
`;
export const deleteAttachment = /* GraphQL */ `
  mutation DeleteAttachment(
    $input: DeleteAttachmentInput!
    $condition: ModelAttachmentConditionInput
  ) {
    deleteAttachment(input: $input, condition: $condition) {
      id
      fileurl
      alternativeText
      createdAt
      createdBy {
        id
        courses {
          nextToken
        }
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        website
        zoneinfo
        verification_code
        group
        deleted
        createdAt
        createdBy
        updatedAt
      }
      updatedAt
      mediaAttachmentsId
      attachmentCreatedById
    }
  }
`;
export const createMenu = /* GraphQL */ `
  mutation CreateMenu(
    $input: CreateMenuInput!
    $condition: ModelMenuConditionInput
  ) {
    createMenu(input: $input, condition: $condition) {
      id
      name
      locations
      items {
        items {
          id
          type
          precedence
          deleted
          createdAt
          updatedAt
          menuItemsId
          menuItemPageId
          menuItemCreatedById
        }
        nextToken
      }
      deleted
      createdAt
      createdBy {
        id
        courses {
          nextToken
        }
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        website
        zoneinfo
        verification_code
        group
        deleted
        createdAt
        createdBy
        updatedAt
      }
      updatedAt
      menuCreatedById
    }
  }
`;
export const updateMenu = /* GraphQL */ `
  mutation UpdateMenu(
    $input: UpdateMenuInput!
    $condition: ModelMenuConditionInput
  ) {
    updateMenu(input: $input, condition: $condition) {
      id
      name
      locations
      items {
        items {
          id
          type
          precedence
          deleted
          createdAt
          updatedAt
          menuItemsId
          menuItemPageId
          menuItemCreatedById
        }
        nextToken
      }
      deleted
      createdAt
      createdBy {
        id
        courses {
          nextToken
        }
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        website
        zoneinfo
        verification_code
        group
        deleted
        createdAt
        createdBy
        updatedAt
      }
      updatedAt
      menuCreatedById
    }
  }
`;
export const deleteMenu = /* GraphQL */ `
  mutation DeleteMenu(
    $input: DeleteMenuInput!
    $condition: ModelMenuConditionInput
  ) {
    deleteMenu(input: $input, condition: $condition) {
      id
      name
      locations
      items {
        items {
          id
          type
          precedence
          deleted
          createdAt
          updatedAt
          menuItemsId
          menuItemPageId
          menuItemCreatedById
        }
        nextToken
      }
      deleted
      createdAt
      createdBy {
        id
        courses {
          nextToken
        }
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        website
        zoneinfo
        verification_code
        group
        deleted
        createdAt
        createdBy
        updatedAt
      }
      updatedAt
      menuCreatedById
    }
  }
`;
export const createMenuItem = /* GraphQL */ `
  mutation CreateMenuItem(
    $input: CreateMenuItemInput!
    $condition: ModelMenuItemConditionInput
  ) {
    createMenuItem(input: $input, condition: $condition) {
      id
      type
      page {
        id
        title
        slug
        status
        content
        excerpt
        parentId
        thumbnail {
          id
          fileurl
          alternativeText
          createdAt
          updatedAt
          mediaAttachmentsId
          attachmentCreatedById
        }
        visibility
        deleted
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        pageThumbnailId
        pageCreatedById
      }
      precedence
      deleted
      createdAt
      createdBy {
        id
        courses {
          nextToken
        }
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        website
        zoneinfo
        verification_code
        group
        deleted
        createdAt
        createdBy
        updatedAt
      }
      updatedAt
      menuItemsId
      menuItemPageId
      menuItemCreatedById
    }
  }
`;
export const updateMenuItem = /* GraphQL */ `
  mutation UpdateMenuItem(
    $input: UpdateMenuItemInput!
    $condition: ModelMenuItemConditionInput
  ) {
    updateMenuItem(input: $input, condition: $condition) {
      id
      type
      page {
        id
        title
        slug
        status
        content
        excerpt
        parentId
        thumbnail {
          id
          fileurl
          alternativeText
          createdAt
          updatedAt
          mediaAttachmentsId
          attachmentCreatedById
        }
        visibility
        deleted
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        pageThumbnailId
        pageCreatedById
      }
      precedence
      deleted
      createdAt
      createdBy {
        id
        courses {
          nextToken
        }
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        website
        zoneinfo
        verification_code
        group
        deleted
        createdAt
        createdBy
        updatedAt
      }
      updatedAt
      menuItemsId
      menuItemPageId
      menuItemCreatedById
    }
  }
`;
export const deleteMenuItem = /* GraphQL */ `
  mutation DeleteMenuItem(
    $input: DeleteMenuItemInput!
    $condition: ModelMenuItemConditionInput
  ) {
    deleteMenuItem(input: $input, condition: $condition) {
      id
      type
      page {
        id
        title
        slug
        status
        content
        excerpt
        parentId
        thumbnail {
          id
          fileurl
          alternativeText
          createdAt
          updatedAt
          mediaAttachmentsId
          attachmentCreatedById
        }
        visibility
        deleted
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        pageThumbnailId
        pageCreatedById
      }
      precedence
      deleted
      createdAt
      createdBy {
        id
        courses {
          nextToken
        }
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        website
        zoneinfo
        verification_code
        group
        deleted
        createdAt
        createdBy
        updatedAt
      }
      updatedAt
      menuItemsId
      menuItemPageId
      menuItemCreatedById
    }
  }
`;
export const createPage = /* GraphQL */ `
  mutation CreatePage(
    $input: CreatePageInput!
    $condition: ModelPageConditionInput
  ) {
    createPage(input: $input, condition: $condition) {
      id
      title
      slug
      status
      content
      excerpt
      parentId
      thumbnail {
        id
        fileurl
        alternativeText
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        mediaAttachmentsId
        attachmentCreatedById
      }
      visibility
      deleted
      createdAt
      createdBy {
        id
        courses {
          nextToken
        }
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        website
        zoneinfo
        verification_code
        group
        deleted
        createdAt
        createdBy
        updatedAt
      }
      updatedAt
      pageThumbnailId
      pageCreatedById
    }
  }
`;
export const updatePage = /* GraphQL */ `
  mutation UpdatePage(
    $input: UpdatePageInput!
    $condition: ModelPageConditionInput
  ) {
    updatePage(input: $input, condition: $condition) {
      id
      title
      slug
      status
      content
      excerpt
      parentId
      thumbnail {
        id
        fileurl
        alternativeText
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        mediaAttachmentsId
        attachmentCreatedById
      }
      visibility
      deleted
      createdAt
      createdBy {
        id
        courses {
          nextToken
        }
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        website
        zoneinfo
        verification_code
        group
        deleted
        createdAt
        createdBy
        updatedAt
      }
      updatedAt
      pageThumbnailId
      pageCreatedById
    }
  }
`;
export const deletePage = /* GraphQL */ `
  mutation DeletePage(
    $input: DeletePageInput!
    $condition: ModelPageConditionInput
  ) {
    deletePage(input: $input, condition: $condition) {
      id
      title
      slug
      status
      content
      excerpt
      parentId
      thumbnail {
        id
        fileurl
        alternativeText
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        mediaAttachmentsId
        attachmentCreatedById
      }
      visibility
      deleted
      createdAt
      createdBy {
        id
        courses {
          nextToken
        }
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        website
        zoneinfo
        verification_code
        group
        deleted
        createdAt
        createdBy
        updatedAt
      }
      updatedAt
      pageThumbnailId
      pageCreatedById
    }
  }
`;
export const createCategory = /* GraphQL */ `
  mutation CreateCategory(
    $input: CreateCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    createCategory(input: $input, condition: $condition) {
      id
      title
      slug
      type
      description
      thumbnail {
        id
        fileurl
        alternativeText
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        mediaAttachmentsId
        attachmentCreatedById
      }
      visibility
      posts {
        items {
          id
          categoryID
          postID
          createdAt
          updatedAt
        }
        nextToken
      }
      deleted
      createdAt
      createdBy {
        id
        courses {
          nextToken
        }
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        website
        zoneinfo
        verification_code
        group
        deleted
        createdAt
        createdBy
        updatedAt
      }
      updatedAt
      categoryThumbnailId
      categoryCreatedById
    }
  }
`;
export const updateCategory = /* GraphQL */ `
  mutation UpdateCategory(
    $input: UpdateCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    updateCategory(input: $input, condition: $condition) {
      id
      title
      slug
      type
      description
      thumbnail {
        id
        fileurl
        alternativeText
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        mediaAttachmentsId
        attachmentCreatedById
      }
      visibility
      posts {
        items {
          id
          categoryID
          postID
          createdAt
          updatedAt
        }
        nextToken
      }
      deleted
      createdAt
      createdBy {
        id
        courses {
          nextToken
        }
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        website
        zoneinfo
        verification_code
        group
        deleted
        createdAt
        createdBy
        updatedAt
      }
      updatedAt
      categoryThumbnailId
      categoryCreatedById
    }
  }
`;
export const deleteCategory = /* GraphQL */ `
  mutation DeleteCategory(
    $input: DeleteCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    deleteCategory(input: $input, condition: $condition) {
      id
      title
      slug
      type
      description
      thumbnail {
        id
        fileurl
        alternativeText
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        mediaAttachmentsId
        attachmentCreatedById
      }
      visibility
      posts {
        items {
          id
          categoryID
          postID
          createdAt
          updatedAt
        }
        nextToken
      }
      deleted
      createdAt
      createdBy {
        id
        courses {
          nextToken
        }
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        website
        zoneinfo
        verification_code
        group
        deleted
        createdAt
        createdBy
        updatedAt
      }
      updatedAt
      categoryThumbnailId
      categoryCreatedById
    }
  }
`;
export const createPost = /* GraphQL */ `
  mutation CreatePost(
    $input: CreatePostInput!
    $condition: ModelPostConditionInput
  ) {
    createPost(input: $input, condition: $condition) {
      id
      title
      slug
      content
      excerpt
      status
      thumbnail {
        id
        fileurl
        alternativeText
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        mediaAttachmentsId
        attachmentCreatedById
      }
      visibility
      categories {
        items {
          id
          categoryID
          postID
          createdAt
          updatedAt
        }
        nextToken
      }
      deleted
      createdAt
      createdBy {
        id
        courses {
          nextToken
        }
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        website
        zoneinfo
        verification_code
        group
        deleted
        createdAt
        createdBy
        updatedAt
      }
      updatedAt
      postThumbnailId
      postCreatedById
    }
  }
`;
export const updatePost = /* GraphQL */ `
  mutation UpdatePost(
    $input: UpdatePostInput!
    $condition: ModelPostConditionInput
  ) {
    updatePost(input: $input, condition: $condition) {
      id
      title
      slug
      content
      excerpt
      status
      thumbnail {
        id
        fileurl
        alternativeText
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        mediaAttachmentsId
        attachmentCreatedById
      }
      visibility
      categories {
        items {
          id
          categoryID
          postID
          createdAt
          updatedAt
        }
        nextToken
      }
      deleted
      createdAt
      createdBy {
        id
        courses {
          nextToken
        }
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        website
        zoneinfo
        verification_code
        group
        deleted
        createdAt
        createdBy
        updatedAt
      }
      updatedAt
      postThumbnailId
      postCreatedById
    }
  }
`;
export const deletePost = /* GraphQL */ `
  mutation DeletePost(
    $input: DeletePostInput!
    $condition: ModelPostConditionInput
  ) {
    deletePost(input: $input, condition: $condition) {
      id
      title
      slug
      content
      excerpt
      status
      thumbnail {
        id
        fileurl
        alternativeText
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        mediaAttachmentsId
        attachmentCreatedById
      }
      visibility
      categories {
        items {
          id
          categoryID
          postID
          createdAt
          updatedAt
        }
        nextToken
      }
      deleted
      createdAt
      createdBy {
        id
        courses {
          nextToken
        }
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        website
        zoneinfo
        verification_code
        group
        deleted
        createdAt
        createdBy
        updatedAt
      }
      updatedAt
      postThumbnailId
      postCreatedById
    }
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage(
    $input: CreateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    createMessage(input: $input, condition: $condition) {
      id
      name
      email
      subject
      message
      deleted
      createdAt
      updatedAt
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage(
    $input: UpdateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    updateMessage(input: $input, condition: $condition) {
      id
      name
      email
      subject
      message
      deleted
      createdAt
      updatedAt
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage(
    $input: DeleteMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    deleteMessage(input: $input, condition: $condition) {
      id
      name
      email
      subject
      message
      deleted
      createdAt
      updatedAt
    }
  }
`;
export const createSlide = /* GraphQL */ `
  mutation CreateSlide(
    $input: CreateSlideInput!
    $condition: ModelSlideConditionInput
  ) {
    createSlide(input: $input, condition: $condition) {
      id
      title
      precedence
      content
      image {
        id
        fileurl
        alternativeText
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        mediaAttachmentsId
        attachmentCreatedById
      }
      mobImage {
        id
        fileurl
        alternativeText
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        mediaAttachmentsId
        attachmentCreatedById
      }
      deleted
      createdAt
      createdBy {
        id
        courses {
          nextToken
        }
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        website
        zoneinfo
        verification_code
        group
        deleted
        createdAt
        createdBy
        updatedAt
      }
      updatedAt
      slideImageId
      slideMobImageId
      slideCreatedById
    }
  }
`;
export const updateSlide = /* GraphQL */ `
  mutation UpdateSlide(
    $input: UpdateSlideInput!
    $condition: ModelSlideConditionInput
  ) {
    updateSlide(input: $input, condition: $condition) {
      id
      title
      precedence
      content
      image {
        id
        fileurl
        alternativeText
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        mediaAttachmentsId
        attachmentCreatedById
      }
      mobImage {
        id
        fileurl
        alternativeText
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        mediaAttachmentsId
        attachmentCreatedById
      }
      deleted
      createdAt
      createdBy {
        id
        courses {
          nextToken
        }
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        website
        zoneinfo
        verification_code
        group
        deleted
        createdAt
        createdBy
        updatedAt
      }
      updatedAt
      slideImageId
      slideMobImageId
      slideCreatedById
    }
  }
`;
export const deleteSlide = /* GraphQL */ `
  mutation DeleteSlide(
    $input: DeleteSlideInput!
    $condition: ModelSlideConditionInput
  ) {
    deleteSlide(input: $input, condition: $condition) {
      id
      title
      precedence
      content
      image {
        id
        fileurl
        alternativeText
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        mediaAttachmentsId
        attachmentCreatedById
      }
      mobImage {
        id
        fileurl
        alternativeText
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        mediaAttachmentsId
        attachmentCreatedById
      }
      deleted
      createdAt
      createdBy {
        id
        courses {
          nextToken
        }
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        website
        zoneinfo
        verification_code
        group
        deleted
        createdAt
        createdBy
        updatedAt
      }
      updatedAt
      slideImageId
      slideMobImageId
      slideCreatedById
    }
  }
`;
export const createCourse = /* GraphQL */ `
  mutation CreateCourse(
    $input: CreateCourseInput!
    $condition: ModelCourseConditionInput
  ) {
    createCourse(input: $input, condition: $condition) {
      id
      name
      slug
      description
      image {
        id
        fileurl
        alternativeText
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        mediaAttachmentsId
        attachmentCreatedById
      }
      modules {
        items {
          id
          name
          parentId
          content
          precedence
          prerequisite
          prerequisiteTitle
          afterModuleQuiz
          nextQuiz
          nextQuizTitle
          nextQuizSlug
          nextModule
          nextModuleTitle
          nextModuleId
          nextCourse
          nextCourseTitle
          nextCourseSlug
          deleted
          createdAt
          updatedAt
          courseModulesId
          moduleImageId
          moduleVrContentId
          moduleCreatedById
        }
        nextToken
      }
      students {
        items {
          id
          userID
          courseID
          createdAt
          updatedAt
        }
        nextToken
      }
      prerequisiteQuiz
      prerequisiteQuizTitle
      prerequisiteQuizSlug
      prerequisiteModule
      prerequisiteModuleTitle
      prerequisiteModuleId
      deleted
      createdAt
      createdBy {
        id
        courses {
          nextToken
        }
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        website
        zoneinfo
        verification_code
        group
        deleted
        createdAt
        createdBy
        updatedAt
      }
      updatedAt
      courseImageId
      courseCreatedById
    }
  }
`;
export const updateCourse = /* GraphQL */ `
  mutation UpdateCourse(
    $input: UpdateCourseInput!
    $condition: ModelCourseConditionInput
  ) {
    updateCourse(input: $input, condition: $condition) {
      id
      name
      slug
      description
      image {
        id
        fileurl
        alternativeText
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        mediaAttachmentsId
        attachmentCreatedById
      }
      modules {
        items {
          id
          name
          parentId
          content
          precedence
          prerequisite
          prerequisiteTitle
          afterModuleQuiz
          nextQuiz
          nextQuizTitle
          nextQuizSlug
          nextModule
          nextModuleTitle
          nextModuleId
          nextCourse
          nextCourseTitle
          nextCourseSlug
          deleted
          createdAt
          updatedAt
          courseModulesId
          moduleImageId
          moduleVrContentId
          moduleCreatedById
        }
        nextToken
      }
      students {
        items {
          id
          userID
          courseID
          createdAt
          updatedAt
        }
        nextToken
      }
      prerequisiteQuiz
      prerequisiteQuizTitle
      prerequisiteQuizSlug
      prerequisiteModule
      prerequisiteModuleTitle
      prerequisiteModuleId
      deleted
      createdAt
      createdBy {
        id
        courses {
          nextToken
        }
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        website
        zoneinfo
        verification_code
        group
        deleted
        createdAt
        createdBy
        updatedAt
      }
      updatedAt
      courseImageId
      courseCreatedById
    }
  }
`;
export const deleteCourse = /* GraphQL */ `
  mutation DeleteCourse(
    $input: DeleteCourseInput!
    $condition: ModelCourseConditionInput
  ) {
    deleteCourse(input: $input, condition: $condition) {
      id
      name
      slug
      description
      image {
        id
        fileurl
        alternativeText
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        mediaAttachmentsId
        attachmentCreatedById
      }
      modules {
        items {
          id
          name
          parentId
          content
          precedence
          prerequisite
          prerequisiteTitle
          afterModuleQuiz
          nextQuiz
          nextQuizTitle
          nextQuizSlug
          nextModule
          nextModuleTitle
          nextModuleId
          nextCourse
          nextCourseTitle
          nextCourseSlug
          deleted
          createdAt
          updatedAt
          courseModulesId
          moduleImageId
          moduleVrContentId
          moduleCreatedById
        }
        nextToken
      }
      students {
        items {
          id
          userID
          courseID
          createdAt
          updatedAt
        }
        nextToken
      }
      prerequisiteQuiz
      prerequisiteQuizTitle
      prerequisiteQuizSlug
      prerequisiteModule
      prerequisiteModuleTitle
      prerequisiteModuleId
      deleted
      createdAt
      createdBy {
        id
        courses {
          nextToken
        }
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        website
        zoneinfo
        verification_code
        group
        deleted
        createdAt
        createdBy
        updatedAt
      }
      updatedAt
      courseImageId
      courseCreatedById
    }
  }
`;
export const createModule = /* GraphQL */ `
  mutation CreateModule(
    $input: CreateModuleInput!
    $condition: ModelModuleConditionInput
  ) {
    createModule(input: $input, condition: $condition) {
      id
      name
      parentId
      content
      precedence
      image {
        id
        fileurl
        alternativeText
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        mediaAttachmentsId
        attachmentCreatedById
      }
      quizzes {
        items {
          id
          title
          slug
          description
          quizQuestions
          prerequisite
          prerequisiteTitle
          prerequisiteSlug
          prerequisiteModule
          prerequisiteModuleTitle
          nextQuiz
          nextQuizTitle
          nextQuizSlug
          nextCourse
          nextCourseTitle
          nextCourseSlug
          hasFeedback
          deleted
          createdAt
          updatedAt
          moduleQuizzesId
          quizTypeId
          quizCreatedById
        }
        nextToken
      }
      prerequisite
      prerequisiteTitle
      afterModuleQuiz
      nextQuiz
      nextQuizTitle
      nextQuizSlug
      nextModule
      nextModuleTitle
      nextModuleId
      nextCourse
      nextCourseTitle
      nextCourseSlug
      vrContent {
        id
        fileurl
        alternativeText
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        mediaAttachmentsId
        attachmentCreatedById
      }
      deleted
      createdAt
      createdBy {
        id
        courses {
          nextToken
        }
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        website
        zoneinfo
        verification_code
        group
        deleted
        createdAt
        createdBy
        updatedAt
      }
      updatedAt
      courseModulesId
      moduleImageId
      moduleVrContentId
      moduleCreatedById
    }
  }
`;
export const updateModule = /* GraphQL */ `
  mutation UpdateModule(
    $input: UpdateModuleInput!
    $condition: ModelModuleConditionInput
  ) {
    updateModule(input: $input, condition: $condition) {
      id
      name
      parentId
      content
      precedence
      image {
        id
        fileurl
        alternativeText
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        mediaAttachmentsId
        attachmentCreatedById
      }
      quizzes {
        items {
          id
          title
          slug
          description
          quizQuestions
          prerequisite
          prerequisiteTitle
          prerequisiteSlug
          prerequisiteModule
          prerequisiteModuleTitle
          nextQuiz
          nextQuizTitle
          nextQuizSlug
          nextCourse
          nextCourseTitle
          nextCourseSlug
          hasFeedback
          deleted
          createdAt
          updatedAt
          moduleQuizzesId
          quizTypeId
          quizCreatedById
        }
        nextToken
      }
      prerequisite
      prerequisiteTitle
      afterModuleQuiz
      nextQuiz
      nextQuizTitle
      nextQuizSlug
      nextModule
      nextModuleTitle
      nextModuleId
      nextCourse
      nextCourseTitle
      nextCourseSlug
      vrContent {
        id
        fileurl
        alternativeText
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        mediaAttachmentsId
        attachmentCreatedById
      }
      deleted
      createdAt
      createdBy {
        id
        courses {
          nextToken
        }
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        website
        zoneinfo
        verification_code
        group
        deleted
        createdAt
        createdBy
        updatedAt
      }
      updatedAt
      courseModulesId
      moduleImageId
      moduleVrContentId
      moduleCreatedById
    }
  }
`;
export const deleteModule = /* GraphQL */ `
  mutation DeleteModule(
    $input: DeleteModuleInput!
    $condition: ModelModuleConditionInput
  ) {
    deleteModule(input: $input, condition: $condition) {
      id
      name
      parentId
      content
      precedence
      image {
        id
        fileurl
        alternativeText
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        mediaAttachmentsId
        attachmentCreatedById
      }
      quizzes {
        items {
          id
          title
          slug
          description
          quizQuestions
          prerequisite
          prerequisiteTitle
          prerequisiteSlug
          prerequisiteModule
          prerequisiteModuleTitle
          nextQuiz
          nextQuizTitle
          nextQuizSlug
          nextCourse
          nextCourseTitle
          nextCourseSlug
          hasFeedback
          deleted
          createdAt
          updatedAt
          moduleQuizzesId
          quizTypeId
          quizCreatedById
        }
        nextToken
      }
      prerequisite
      prerequisiteTitle
      afterModuleQuiz
      nextQuiz
      nextQuizTitle
      nextQuizSlug
      nextModule
      nextModuleTitle
      nextModuleId
      nextCourse
      nextCourseTitle
      nextCourseSlug
      vrContent {
        id
        fileurl
        alternativeText
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        mediaAttachmentsId
        attachmentCreatedById
      }
      deleted
      createdAt
      createdBy {
        id
        courses {
          nextToken
        }
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        website
        zoneinfo
        verification_code
        group
        deleted
        createdAt
        createdBy
        updatedAt
      }
      updatedAt
      courseModulesId
      moduleImageId
      moduleVrContentId
      moduleCreatedById
    }
  }
`;
export const createModuleStatus = /* GraphQL */ `
  mutation CreateModuleStatus(
    $input: CreateModuleStatusInput!
    $condition: ModelModuleStatusConditionInput
  ) {
    createModuleStatus(input: $input, condition: $condition) {
      id
      module {
        id
        name
        parentId
        content
        precedence
        image {
          id
          fileurl
          alternativeText
          createdAt
          updatedAt
          mediaAttachmentsId
          attachmentCreatedById
        }
        quizzes {
          nextToken
        }
        prerequisite
        prerequisiteTitle
        afterModuleQuiz
        nextQuiz
        nextQuizTitle
        nextQuizSlug
        nextModule
        nextModuleTitle
        nextModuleId
        nextCourse
        nextCourseTitle
        nextCourseSlug
        vrContent {
          id
          fileurl
          alternativeText
          createdAt
          updatedAt
          mediaAttachmentsId
          attachmentCreatedById
        }
        deleted
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        courseModulesId
        moduleImageId
        moduleVrContentId
        moduleCreatedById
      }
      student {
        id
        courses {
          nextToken
        }
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        website
        zoneinfo
        verification_code
        group
        deleted
        createdAt
        createdBy
        updatedAt
      }
      deleted
      isCompleted
      createdAt
      updatedAt
      moduleStatusModuleId
      moduleStatusStudentId
    }
  }
`;
export const updateModuleStatus = /* GraphQL */ `
  mutation UpdateModuleStatus(
    $input: UpdateModuleStatusInput!
    $condition: ModelModuleStatusConditionInput
  ) {
    updateModuleStatus(input: $input, condition: $condition) {
      id
      module {
        id
        name
        parentId
        content
        precedence
        image {
          id
          fileurl
          alternativeText
          createdAt
          updatedAt
          mediaAttachmentsId
          attachmentCreatedById
        }
        quizzes {
          nextToken
        }
        prerequisite
        prerequisiteTitle
        afterModuleQuiz
        nextQuiz
        nextQuizTitle
        nextQuizSlug
        nextModule
        nextModuleTitle
        nextModuleId
        nextCourse
        nextCourseTitle
        nextCourseSlug
        vrContent {
          id
          fileurl
          alternativeText
          createdAt
          updatedAt
          mediaAttachmentsId
          attachmentCreatedById
        }
        deleted
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        courseModulesId
        moduleImageId
        moduleVrContentId
        moduleCreatedById
      }
      student {
        id
        courses {
          nextToken
        }
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        website
        zoneinfo
        verification_code
        group
        deleted
        createdAt
        createdBy
        updatedAt
      }
      deleted
      isCompleted
      createdAt
      updatedAt
      moduleStatusModuleId
      moduleStatusStudentId
    }
  }
`;
export const deleteModuleStatus = /* GraphQL */ `
  mutation DeleteModuleStatus(
    $input: DeleteModuleStatusInput!
    $condition: ModelModuleStatusConditionInput
  ) {
    deleteModuleStatus(input: $input, condition: $condition) {
      id
      module {
        id
        name
        parentId
        content
        precedence
        image {
          id
          fileurl
          alternativeText
          createdAt
          updatedAt
          mediaAttachmentsId
          attachmentCreatedById
        }
        quizzes {
          nextToken
        }
        prerequisite
        prerequisiteTitle
        afterModuleQuiz
        nextQuiz
        nextQuizTitle
        nextQuizSlug
        nextModule
        nextModuleTitle
        nextModuleId
        nextCourse
        nextCourseTitle
        nextCourseSlug
        vrContent {
          id
          fileurl
          alternativeText
          createdAt
          updatedAt
          mediaAttachmentsId
          attachmentCreatedById
        }
        deleted
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        courseModulesId
        moduleImageId
        moduleVrContentId
        moduleCreatedById
      }
      student {
        id
        courses {
          nextToken
        }
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        website
        zoneinfo
        verification_code
        group
        deleted
        createdAt
        createdBy
        updatedAt
      }
      deleted
      isCompleted
      createdAt
      updatedAt
      moduleStatusModuleId
      moduleStatusStudentId
    }
  }
`;
export const createQuiz = /* GraphQL */ `
  mutation CreateQuiz(
    $input: CreateQuizInput!
    $condition: ModelQuizConditionInput
  ) {
    createQuiz(input: $input, condition: $condition) {
      id
      title
      slug
      description
      type {
        id
        name
        deleted
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        quizTypeCreatedById
      }
      questions {
        items {
          id
          quizID
          questionID
          createdAt
          updatedAt
        }
        nextToken
      }
      quizQuestions
      prerequisite
      prerequisiteTitle
      prerequisiteSlug
      prerequisiteModule
      prerequisiteModuleTitle
      nextQuiz
      nextQuizTitle
      nextQuizSlug
      nextCourse
      nextCourseTitle
      nextCourseSlug
      hasFeedback
      deleted
      createdAt
      createdBy {
        id
        courses {
          nextToken
        }
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        website
        zoneinfo
        verification_code
        group
        deleted
        createdAt
        createdBy
        updatedAt
      }
      updatedAt
      moduleQuizzesId
      quizTypeId
      quizCreatedById
    }
  }
`;
export const updateQuiz = /* GraphQL */ `
  mutation UpdateQuiz(
    $input: UpdateQuizInput!
    $condition: ModelQuizConditionInput
  ) {
    updateQuiz(input: $input, condition: $condition) {
      id
      title
      slug
      description
      type {
        id
        name
        deleted
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        quizTypeCreatedById
      }
      questions {
        items {
          id
          quizID
          questionID
          createdAt
          updatedAt
        }
        nextToken
      }
      quizQuestions
      prerequisite
      prerequisiteTitle
      prerequisiteSlug
      prerequisiteModule
      prerequisiteModuleTitle
      nextQuiz
      nextQuizTitle
      nextQuizSlug
      nextCourse
      nextCourseTitle
      nextCourseSlug
      hasFeedback
      deleted
      createdAt
      createdBy {
        id
        courses {
          nextToken
        }
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        website
        zoneinfo
        verification_code
        group
        deleted
        createdAt
        createdBy
        updatedAt
      }
      updatedAt
      moduleQuizzesId
      quizTypeId
      quizCreatedById
    }
  }
`;
export const deleteQuiz = /* GraphQL */ `
  mutation DeleteQuiz(
    $input: DeleteQuizInput!
    $condition: ModelQuizConditionInput
  ) {
    deleteQuiz(input: $input, condition: $condition) {
      id
      title
      slug
      description
      type {
        id
        name
        deleted
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        quizTypeCreatedById
      }
      questions {
        items {
          id
          quizID
          questionID
          createdAt
          updatedAt
        }
        nextToken
      }
      quizQuestions
      prerequisite
      prerequisiteTitle
      prerequisiteSlug
      prerequisiteModule
      prerequisiteModuleTitle
      nextQuiz
      nextQuizTitle
      nextQuizSlug
      nextCourse
      nextCourseTitle
      nextCourseSlug
      hasFeedback
      deleted
      createdAt
      createdBy {
        id
        courses {
          nextToken
        }
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        website
        zoneinfo
        verification_code
        group
        deleted
        createdAt
        createdBy
        updatedAt
      }
      updatedAt
      moduleQuizzesId
      quizTypeId
      quizCreatedById
    }
  }
`;
export const createQuizType = /* GraphQL */ `
  mutation CreateQuizType(
    $input: CreateQuizTypeInput!
    $condition: ModelQuizTypeConditionInput
  ) {
    createQuizType(input: $input, condition: $condition) {
      id
      name
      deleted
      createdAt
      createdBy {
        id
        courses {
          nextToken
        }
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        website
        zoneinfo
        verification_code
        group
        deleted
        createdAt
        createdBy
        updatedAt
      }
      updatedAt
      quizTypeCreatedById
    }
  }
`;
export const updateQuizType = /* GraphQL */ `
  mutation UpdateQuizType(
    $input: UpdateQuizTypeInput!
    $condition: ModelQuizTypeConditionInput
  ) {
    updateQuizType(input: $input, condition: $condition) {
      id
      name
      deleted
      createdAt
      createdBy {
        id
        courses {
          nextToken
        }
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        website
        zoneinfo
        verification_code
        group
        deleted
        createdAt
        createdBy
        updatedAt
      }
      updatedAt
      quizTypeCreatedById
    }
  }
`;
export const deleteQuizType = /* GraphQL */ `
  mutation DeleteQuizType(
    $input: DeleteQuizTypeInput!
    $condition: ModelQuizTypeConditionInput
  ) {
    deleteQuizType(input: $input, condition: $condition) {
      id
      name
      deleted
      createdAt
      createdBy {
        id
        courses {
          nextToken
        }
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        website
        zoneinfo
        verification_code
        group
        deleted
        createdAt
        createdBy
        updatedAt
      }
      updatedAt
      quizTypeCreatedById
    }
  }
`;
export const createQuizStatus = /* GraphQL */ `
  mutation CreateQuizStatus(
    $input: CreateQuizStatusInput!
    $condition: ModelQuizStatusConditionInput
  ) {
    createQuizStatus(input: $input, condition: $condition) {
      id
      quiz {
        id
        title
        slug
        description
        type {
          id
          name
          deleted
          createdAt
          updatedAt
          quizTypeCreatedById
        }
        questions {
          nextToken
        }
        quizQuestions
        prerequisite
        prerequisiteTitle
        prerequisiteSlug
        prerequisiteModule
        prerequisiteModuleTitle
        nextQuiz
        nextQuizTitle
        nextQuizSlug
        nextCourse
        nextCourseTitle
        nextCourseSlug
        hasFeedback
        deleted
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        moduleQuizzesId
        quizTypeId
        quizCreatedById
      }
      student {
        id
        courses {
          nextToken
        }
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        website
        zoneinfo
        verification_code
        group
        deleted
        createdAt
        createdBy
        updatedAt
      }
      isCompleted
      totalScore
      studentScore
      deleted
      createdAt
      updatedAt
      quizStatusQuizId
      quizStatusStudentId
    }
  }
`;
export const updateQuizStatus = /* GraphQL */ `
  mutation UpdateQuizStatus(
    $input: UpdateQuizStatusInput!
    $condition: ModelQuizStatusConditionInput
  ) {
    updateQuizStatus(input: $input, condition: $condition) {
      id
      quiz {
        id
        title
        slug
        description
        type {
          id
          name
          deleted
          createdAt
          updatedAt
          quizTypeCreatedById
        }
        questions {
          nextToken
        }
        quizQuestions
        prerequisite
        prerequisiteTitle
        prerequisiteSlug
        prerequisiteModule
        prerequisiteModuleTitle
        nextQuiz
        nextQuizTitle
        nextQuizSlug
        nextCourse
        nextCourseTitle
        nextCourseSlug
        hasFeedback
        deleted
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        moduleQuizzesId
        quizTypeId
        quizCreatedById
      }
      student {
        id
        courses {
          nextToken
        }
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        website
        zoneinfo
        verification_code
        group
        deleted
        createdAt
        createdBy
        updatedAt
      }
      isCompleted
      totalScore
      studentScore
      deleted
      createdAt
      updatedAt
      quizStatusQuizId
      quizStatusStudentId
    }
  }
`;
export const deleteQuizStatus = /* GraphQL */ `
  mutation DeleteQuizStatus(
    $input: DeleteQuizStatusInput!
    $condition: ModelQuizStatusConditionInput
  ) {
    deleteQuizStatus(input: $input, condition: $condition) {
      id
      quiz {
        id
        title
        slug
        description
        type {
          id
          name
          deleted
          createdAt
          updatedAt
          quizTypeCreatedById
        }
        questions {
          nextToken
        }
        quizQuestions
        prerequisite
        prerequisiteTitle
        prerequisiteSlug
        prerequisiteModule
        prerequisiteModuleTitle
        nextQuiz
        nextQuizTitle
        nextQuizSlug
        nextCourse
        nextCourseTitle
        nextCourseSlug
        hasFeedback
        deleted
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        moduleQuizzesId
        quizTypeId
        quizCreatedById
      }
      student {
        id
        courses {
          nextToken
        }
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        website
        zoneinfo
        verification_code
        group
        deleted
        createdAt
        createdBy
        updatedAt
      }
      isCompleted
      totalScore
      studentScore
      deleted
      createdAt
      updatedAt
      quizStatusQuizId
      quizStatusStudentId
    }
  }
`;
export const createQuestionType = /* GraphQL */ `
  mutation CreateQuestionType(
    $input: CreateQuestionTypeInput!
    $condition: ModelQuestionTypeConditionInput
  ) {
    createQuestionType(input: $input, condition: $condition) {
      id
      name
      deleted
      createdAt
      createdBy {
        id
        courses {
          nextToken
        }
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        website
        zoneinfo
        verification_code
        group
        deleted
        createdAt
        createdBy
        updatedAt
      }
      updatedAt
      questionTypeCreatedById
    }
  }
`;
export const updateQuestionType = /* GraphQL */ `
  mutation UpdateQuestionType(
    $input: UpdateQuestionTypeInput!
    $condition: ModelQuestionTypeConditionInput
  ) {
    updateQuestionType(input: $input, condition: $condition) {
      id
      name
      deleted
      createdAt
      createdBy {
        id
        courses {
          nextToken
        }
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        website
        zoneinfo
        verification_code
        group
        deleted
        createdAt
        createdBy
        updatedAt
      }
      updatedAt
      questionTypeCreatedById
    }
  }
`;
export const deleteQuestionType = /* GraphQL */ `
  mutation DeleteQuestionType(
    $input: DeleteQuestionTypeInput!
    $condition: ModelQuestionTypeConditionInput
  ) {
    deleteQuestionType(input: $input, condition: $condition) {
      id
      name
      deleted
      createdAt
      createdBy {
        id
        courses {
          nextToken
        }
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        website
        zoneinfo
        verification_code
        group
        deleted
        createdAt
        createdBy
        updatedAt
      }
      updatedAt
      questionTypeCreatedById
    }
  }
`;
export const createQuestion = /* GraphQL */ `
  mutation CreateQuestion(
    $input: CreateQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    createQuestion(input: $input, condition: $condition) {
      id
      title
      type {
        id
        name
        deleted
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        questionTypeCreatedById
      }
      correctAnswer
      options
      image {
        id
        fileurl
        alternativeText
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        mediaAttachmentsId
        attachmentCreatedById
      }
      quizzes {
        items {
          id
          quizID
          questionID
          createdAt
          updatedAt
        }
        nextToken
      }
      deleted
      createdAt
      createdBy {
        id
        courses {
          nextToken
        }
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        website
        zoneinfo
        verification_code
        group
        deleted
        createdAt
        createdBy
        updatedAt
      }
      updatedAt
      questionTypeId
      questionImageId
      questionCreatedById
    }
  }
`;
export const updateQuestion = /* GraphQL */ `
  mutation UpdateQuestion(
    $input: UpdateQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    updateQuestion(input: $input, condition: $condition) {
      id
      title
      type {
        id
        name
        deleted
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        questionTypeCreatedById
      }
      correctAnswer
      options
      image {
        id
        fileurl
        alternativeText
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        mediaAttachmentsId
        attachmentCreatedById
      }
      quizzes {
        items {
          id
          quizID
          questionID
          createdAt
          updatedAt
        }
        nextToken
      }
      deleted
      createdAt
      createdBy {
        id
        courses {
          nextToken
        }
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        website
        zoneinfo
        verification_code
        group
        deleted
        createdAt
        createdBy
        updatedAt
      }
      updatedAt
      questionTypeId
      questionImageId
      questionCreatedById
    }
  }
`;
export const deleteQuestion = /* GraphQL */ `
  mutation DeleteQuestion(
    $input: DeleteQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    deleteQuestion(input: $input, condition: $condition) {
      id
      title
      type {
        id
        name
        deleted
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        questionTypeCreatedById
      }
      correctAnswer
      options
      image {
        id
        fileurl
        alternativeText
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        mediaAttachmentsId
        attachmentCreatedById
      }
      quizzes {
        items {
          id
          quizID
          questionID
          createdAt
          updatedAt
        }
        nextToken
      }
      deleted
      createdAt
      createdBy {
        id
        courses {
          nextToken
        }
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        website
        zoneinfo
        verification_code
        group
        deleted
        createdAt
        createdBy
        updatedAt
      }
      updatedAt
      questionTypeId
      questionImageId
      questionCreatedById
    }
  }
`;
export const createAnswer = /* GraphQL */ `
  mutation CreateAnswer(
    $input: CreateAnswerInput!
    $condition: ModelAnswerConditionInput
  ) {
    createAnswer(input: $input, condition: $condition) {
      id
      question {
        id
        title
        type {
          id
          name
          deleted
          createdAt
          updatedAt
          questionTypeCreatedById
        }
        correctAnswer
        options
        image {
          id
          fileurl
          alternativeText
          createdAt
          updatedAt
          mediaAttachmentsId
          attachmentCreatedById
        }
        quizzes {
          nextToken
        }
        deleted
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        questionTypeId
        questionImageId
        questionCreatedById
      }
      student {
        id
        courses {
          nextToken
        }
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        website
        zoneinfo
        verification_code
        group
        deleted
        createdAt
        createdBy
        updatedAt
      }
      values
      questionID
      studentID
      deleted
      isCorrect
      createdAt
      updatedAt
      answerQuestionId
      answerStudentId
    }
  }
`;
export const updateAnswer = /* GraphQL */ `
  mutation UpdateAnswer(
    $input: UpdateAnswerInput!
    $condition: ModelAnswerConditionInput
  ) {
    updateAnswer(input: $input, condition: $condition) {
      id
      question {
        id
        title
        type {
          id
          name
          deleted
          createdAt
          updatedAt
          questionTypeCreatedById
        }
        correctAnswer
        options
        image {
          id
          fileurl
          alternativeText
          createdAt
          updatedAt
          mediaAttachmentsId
          attachmentCreatedById
        }
        quizzes {
          nextToken
        }
        deleted
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        questionTypeId
        questionImageId
        questionCreatedById
      }
      student {
        id
        courses {
          nextToken
        }
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        website
        zoneinfo
        verification_code
        group
        deleted
        createdAt
        createdBy
        updatedAt
      }
      values
      questionID
      studentID
      deleted
      isCorrect
      createdAt
      updatedAt
      answerQuestionId
      answerStudentId
    }
  }
`;
export const deleteAnswer = /* GraphQL */ `
  mutation DeleteAnswer(
    $input: DeleteAnswerInput!
    $condition: ModelAnswerConditionInput
  ) {
    deleteAnswer(input: $input, condition: $condition) {
      id
      question {
        id
        title
        type {
          id
          name
          deleted
          createdAt
          updatedAt
          questionTypeCreatedById
        }
        correctAnswer
        options
        image {
          id
          fileurl
          alternativeText
          createdAt
          updatedAt
          mediaAttachmentsId
          attachmentCreatedById
        }
        quizzes {
          nextToken
        }
        deleted
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        questionTypeId
        questionImageId
        questionCreatedById
      }
      student {
        id
        courses {
          nextToken
        }
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        website
        zoneinfo
        verification_code
        group
        deleted
        createdAt
        createdBy
        updatedAt
      }
      values
      questionID
      studentID
      deleted
      isCorrect
      createdAt
      updatedAt
      answerQuestionId
      answerStudentId
    }
  }
`;
export const createCourseStudent = /* GraphQL */ `
  mutation CreateCourseStudent(
    $input: CreateCourseStudentInput!
    $condition: ModelCourseStudentConditionInput
  ) {
    createCourseStudent(input: $input, condition: $condition) {
      id
      userID
      courseID
      user {
        id
        courses {
          nextToken
        }
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        website
        zoneinfo
        verification_code
        group
        deleted
        createdAt
        createdBy
        updatedAt
      }
      course {
        id
        name
        slug
        description
        image {
          id
          fileurl
          alternativeText
          createdAt
          updatedAt
          mediaAttachmentsId
          attachmentCreatedById
        }
        modules {
          nextToken
        }
        students {
          nextToken
        }
        prerequisiteQuiz
        prerequisiteQuizTitle
        prerequisiteQuizSlug
        prerequisiteModule
        prerequisiteModuleTitle
        prerequisiteModuleId
        deleted
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        courseImageId
        courseCreatedById
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCourseStudent = /* GraphQL */ `
  mutation UpdateCourseStudent(
    $input: UpdateCourseStudentInput!
    $condition: ModelCourseStudentConditionInput
  ) {
    updateCourseStudent(input: $input, condition: $condition) {
      id
      userID
      courseID
      user {
        id
        courses {
          nextToken
        }
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        website
        zoneinfo
        verification_code
        group
        deleted
        createdAt
        createdBy
        updatedAt
      }
      course {
        id
        name
        slug
        description
        image {
          id
          fileurl
          alternativeText
          createdAt
          updatedAt
          mediaAttachmentsId
          attachmentCreatedById
        }
        modules {
          nextToken
        }
        students {
          nextToken
        }
        prerequisiteQuiz
        prerequisiteQuizTitle
        prerequisiteQuizSlug
        prerequisiteModule
        prerequisiteModuleTitle
        prerequisiteModuleId
        deleted
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        courseImageId
        courseCreatedById
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCourseStudent = /* GraphQL */ `
  mutation DeleteCourseStudent(
    $input: DeleteCourseStudentInput!
    $condition: ModelCourseStudentConditionInput
  ) {
    deleteCourseStudent(input: $input, condition: $condition) {
      id
      userID
      courseID
      user {
        id
        courses {
          nextToken
        }
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        website
        zoneinfo
        verification_code
        group
        deleted
        createdAt
        createdBy
        updatedAt
      }
      course {
        id
        name
        slug
        description
        image {
          id
          fileurl
          alternativeText
          createdAt
          updatedAt
          mediaAttachmentsId
          attachmentCreatedById
        }
        modules {
          nextToken
        }
        students {
          nextToken
        }
        prerequisiteQuiz
        prerequisiteQuizTitle
        prerequisiteQuizSlug
        prerequisiteModule
        prerequisiteModuleTitle
        prerequisiteModuleId
        deleted
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        courseImageId
        courseCreatedById
      }
      createdAt
      updatedAt
    }
  }
`;
export const createAccountLanguage = /* GraphQL */ `
  mutation CreateAccountLanguage(
    $input: CreateAccountLanguageInput!
    $condition: ModelAccountLanguageConditionInput
  ) {
    createAccountLanguage(input: $input, condition: $condition) {
      id
      accountID
      languageID
      account {
        id
        logo {
          id
          fileurl
          alternativeText
          createdAt
          updatedAt
          mediaAttachmentsId
          attachmentCreatedById
        }
        siteTitle
        tagline
        description
        siteAddress
        defaultLanguage
        languages {
          nextToken
        }
        status
        socialLinks {
          nextToken
        }
        deleted
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        accountLogoId
        accountCreatedById
      }
      language {
        id
        name
        code
        accounts {
          nextToken
        }
        deleted
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        languageCreatedById
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateAccountLanguage = /* GraphQL */ `
  mutation UpdateAccountLanguage(
    $input: UpdateAccountLanguageInput!
    $condition: ModelAccountLanguageConditionInput
  ) {
    updateAccountLanguage(input: $input, condition: $condition) {
      id
      accountID
      languageID
      account {
        id
        logo {
          id
          fileurl
          alternativeText
          createdAt
          updatedAt
          mediaAttachmentsId
          attachmentCreatedById
        }
        siteTitle
        tagline
        description
        siteAddress
        defaultLanguage
        languages {
          nextToken
        }
        status
        socialLinks {
          nextToken
        }
        deleted
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        accountLogoId
        accountCreatedById
      }
      language {
        id
        name
        code
        accounts {
          nextToken
        }
        deleted
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        languageCreatedById
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteAccountLanguage = /* GraphQL */ `
  mutation DeleteAccountLanguage(
    $input: DeleteAccountLanguageInput!
    $condition: ModelAccountLanguageConditionInput
  ) {
    deleteAccountLanguage(input: $input, condition: $condition) {
      id
      accountID
      languageID
      account {
        id
        logo {
          id
          fileurl
          alternativeText
          createdAt
          updatedAt
          mediaAttachmentsId
          attachmentCreatedById
        }
        siteTitle
        tagline
        description
        siteAddress
        defaultLanguage
        languages {
          nextToken
        }
        status
        socialLinks {
          nextToken
        }
        deleted
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        accountLogoId
        accountCreatedById
      }
      language {
        id
        name
        code
        accounts {
          nextToken
        }
        deleted
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        languageCreatedById
      }
      createdAt
      updatedAt
    }
  }
`;
export const createPostCategory = /* GraphQL */ `
  mutation CreatePostCategory(
    $input: CreatePostCategoryInput!
    $condition: ModelPostCategoryConditionInput
  ) {
    createPostCategory(input: $input, condition: $condition) {
      id
      categoryID
      postID
      category {
        id
        title
        slug
        type
        description
        thumbnail {
          id
          fileurl
          alternativeText
          createdAt
          updatedAt
          mediaAttachmentsId
          attachmentCreatedById
        }
        visibility
        posts {
          nextToken
        }
        deleted
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        categoryThumbnailId
        categoryCreatedById
      }
      post {
        id
        title
        slug
        content
        excerpt
        status
        thumbnail {
          id
          fileurl
          alternativeText
          createdAt
          updatedAt
          mediaAttachmentsId
          attachmentCreatedById
        }
        visibility
        categories {
          nextToken
        }
        deleted
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        postThumbnailId
        postCreatedById
      }
      createdAt
      updatedAt
    }
  }
`;
export const updatePostCategory = /* GraphQL */ `
  mutation UpdatePostCategory(
    $input: UpdatePostCategoryInput!
    $condition: ModelPostCategoryConditionInput
  ) {
    updatePostCategory(input: $input, condition: $condition) {
      id
      categoryID
      postID
      category {
        id
        title
        slug
        type
        description
        thumbnail {
          id
          fileurl
          alternativeText
          createdAt
          updatedAt
          mediaAttachmentsId
          attachmentCreatedById
        }
        visibility
        posts {
          nextToken
        }
        deleted
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        categoryThumbnailId
        categoryCreatedById
      }
      post {
        id
        title
        slug
        content
        excerpt
        status
        thumbnail {
          id
          fileurl
          alternativeText
          createdAt
          updatedAt
          mediaAttachmentsId
          attachmentCreatedById
        }
        visibility
        categories {
          nextToken
        }
        deleted
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        postThumbnailId
        postCreatedById
      }
      createdAt
      updatedAt
    }
  }
`;
export const deletePostCategory = /* GraphQL */ `
  mutation DeletePostCategory(
    $input: DeletePostCategoryInput!
    $condition: ModelPostCategoryConditionInput
  ) {
    deletePostCategory(input: $input, condition: $condition) {
      id
      categoryID
      postID
      category {
        id
        title
        slug
        type
        description
        thumbnail {
          id
          fileurl
          alternativeText
          createdAt
          updatedAt
          mediaAttachmentsId
          attachmentCreatedById
        }
        visibility
        posts {
          nextToken
        }
        deleted
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        categoryThumbnailId
        categoryCreatedById
      }
      post {
        id
        title
        slug
        content
        excerpt
        status
        thumbnail {
          id
          fileurl
          alternativeText
          createdAt
          updatedAt
          mediaAttachmentsId
          attachmentCreatedById
        }
        visibility
        categories {
          nextToken
        }
        deleted
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        postThumbnailId
        postCreatedById
      }
      createdAt
      updatedAt
    }
  }
`;
export const createQuizQuestion = /* GraphQL */ `
  mutation CreateQuizQuestion(
    $input: CreateQuizQuestionInput!
    $condition: ModelQuizQuestionConditionInput
  ) {
    createQuizQuestion(input: $input, condition: $condition) {
      id
      quizID
      questionID
      quiz {
        id
        title
        slug
        description
        type {
          id
          name
          deleted
          createdAt
          updatedAt
          quizTypeCreatedById
        }
        questions {
          nextToken
        }
        quizQuestions
        prerequisite
        prerequisiteTitle
        prerequisiteSlug
        prerequisiteModule
        prerequisiteModuleTitle
        nextQuiz
        nextQuizTitle
        nextQuizSlug
        nextCourse
        nextCourseTitle
        nextCourseSlug
        hasFeedback
        deleted
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        moduleQuizzesId
        quizTypeId
        quizCreatedById
      }
      question {
        id
        title
        type {
          id
          name
          deleted
          createdAt
          updatedAt
          questionTypeCreatedById
        }
        correctAnswer
        options
        image {
          id
          fileurl
          alternativeText
          createdAt
          updatedAt
          mediaAttachmentsId
          attachmentCreatedById
        }
        quizzes {
          nextToken
        }
        deleted
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        questionTypeId
        questionImageId
        questionCreatedById
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateQuizQuestion = /* GraphQL */ `
  mutation UpdateQuizQuestion(
    $input: UpdateQuizQuestionInput!
    $condition: ModelQuizQuestionConditionInput
  ) {
    updateQuizQuestion(input: $input, condition: $condition) {
      id
      quizID
      questionID
      quiz {
        id
        title
        slug
        description
        type {
          id
          name
          deleted
          createdAt
          updatedAt
          quizTypeCreatedById
        }
        questions {
          nextToken
        }
        quizQuestions
        prerequisite
        prerequisiteTitle
        prerequisiteSlug
        prerequisiteModule
        prerequisiteModuleTitle
        nextQuiz
        nextQuizTitle
        nextQuizSlug
        nextCourse
        nextCourseTitle
        nextCourseSlug
        hasFeedback
        deleted
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        moduleQuizzesId
        quizTypeId
        quizCreatedById
      }
      question {
        id
        title
        type {
          id
          name
          deleted
          createdAt
          updatedAt
          questionTypeCreatedById
        }
        correctAnswer
        options
        image {
          id
          fileurl
          alternativeText
          createdAt
          updatedAt
          mediaAttachmentsId
          attachmentCreatedById
        }
        quizzes {
          nextToken
        }
        deleted
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        questionTypeId
        questionImageId
        questionCreatedById
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteQuizQuestion = /* GraphQL */ `
  mutation DeleteQuizQuestion(
    $input: DeleteQuizQuestionInput!
    $condition: ModelQuizQuestionConditionInput
  ) {
    deleteQuizQuestion(input: $input, condition: $condition) {
      id
      quizID
      questionID
      quiz {
        id
        title
        slug
        description
        type {
          id
          name
          deleted
          createdAt
          updatedAt
          quizTypeCreatedById
        }
        questions {
          nextToken
        }
        quizQuestions
        prerequisite
        prerequisiteTitle
        prerequisiteSlug
        prerequisiteModule
        prerequisiteModuleTitle
        nextQuiz
        nextQuizTitle
        nextQuizSlug
        nextCourse
        nextCourseTitle
        nextCourseSlug
        hasFeedback
        deleted
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        moduleQuizzesId
        quizTypeId
        quizCreatedById
      }
      question {
        id
        title
        type {
          id
          name
          deleted
          createdAt
          updatedAt
          questionTypeCreatedById
        }
        correctAnswer
        options
        image {
          id
          fileurl
          alternativeText
          createdAt
          updatedAt
          mediaAttachmentsId
          attachmentCreatedById
        }
        quizzes {
          nextToken
        }
        deleted
        createdAt
        createdBy {
          id
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          group
          deleted
          createdAt
          createdBy
          updatedAt
        }
        updatedAt
        questionTypeId
        questionImageId
        questionCreatedById
      }
      createdAt
      updatedAt
    }
  }
`;
