/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://yrjqxu5if4.execute-api.us-east-2.amazonaws.com/dev",
            "region": "us-east-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://udqozzjx5je4vijm2lp45iwih4.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "us-east-2:45d66b82-5448-4609-849c-70404626350e",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_hgzwzpdhG",
    "aws_user_pools_web_client_id": "1q93s5vr009mq2jid2jfl7lvrb",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "newmultisites-20220502023403-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "us-east-2",
    "aws_content_delivery_url": "https://d3dp5bmn7cchz2.cloudfront.net",
    "aws_user_files_s3_bucket": "newmultisitesed36ebaed90648269357bf9cb64dc50314835-dev",
    "aws_user_files_s3_bucket_region": "us-east-2"
};


export default awsmobile;
