import { Course } from "../../models/api";

// actions
const SET = "courses/SET";
const SET_SEARCH_TEXT = "courses/SET_SEARCH_TEXT";
const SET_DATES = "courses/SET_DATES";

const DEFAULT_STATE = {
  courses: [],
  searchText: "",
  datesFilter: [null, null],
};

// reducer
const courseReducer = (state = DEFAULT_STATE, action: any = {}) => {
  switch (action.type) {
    case SET:
      return Object.assign({}, state, { courses: action.courses });
    case SET_SEARCH_TEXT:
      return Object.assign({}, state, { searchText: action.searchText });
    case SET_DATES:
      return Object.assign({}, state, { datesFilter: action.dates });
    default:
      return state;
  }
};

export default courseReducer;

// action creators
export const setCourses = (courses: Course[]) => {
  return { courses, type: SET };
};

export const setSearchText = (searchText: string) => {
  return { searchText, type: SET_SEARCH_TEXT };
};

export const setDates = (dates: any[]) => {
  return { dates, type: SET_DATES };
};
