import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { Auth, API, graphqlOperation } from "aws-amplify";
import { createUser, updateUser } from "../graphql/mutations";
import { getUser, activeUsers, listQuizStatuses } from "../graphql/queries";
import {
  CreateUserInput,
  ModelSortDirection,
  UpdateUserInput,
} from "../models/api";
import { Token } from "../models/pagination";

export async function confirmUserSignUp(username: string) {
  try {
    const apiName = "AdminQueries";
    const path = "/confirmUserSignUp";
    const request = {
      body: {
        username: username,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    };

    const result = await API.post(apiName, path, request);
    return result;
  } catch (error: any) {
    throw new Error(error.errors[0].message);
  }
}

export async function listUsersInGroup(groupname: string) {
  try {
    const apiName = "AdminQueries";
    const path = "/listUsersInGroup";
    const request = {
      queryStringParameters: {
        groupname: groupname,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    };
    const result = await API.get(apiName, path, request);

    return result.Users;
  } catch (error: any) {
    throw new Error(error.errors[0].message);
  }
}

export async function listGroupsForUser(
  username: string,
  limit: number,
  nextToken: string
) {
  try {
    const apiName = "AdminQueries";
    const path = "/listGroupsForUser";
    const request = {
      body: {
        username: username,
        limit: limit,
        token: nextToken,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    };

    const result = await API.post(apiName, path, request);
    return result;
  } catch (error: any) {
    throw new Error(error.errors[0].message);
  }
}

export const list = async (
  searchTerm: string,
  limit: number,
  nextToken: Token,
  group?: string
) => {
  const filters: any = { name: { contains: searchTerm } };

  if (group) filters["group"] = { eq: group };

  try {
    const result: any = await API.graphql(
      graphqlOperation(activeUsers, {
        limit,
        nextToken,
        deleted: "0",
        sortDirection: ModelSortDirection.DESC,
        filter: filters,
      })
    );

    return result.data.activeUsers;
  } catch (error: any) {
    throw new Error(error.errors[0].message);
  }
};

export const get = async (id: string) => {
  try {
    const result: any = await API.graphql(graphqlOperation(getUser, { id }));

    return result.data.getUser;
  } catch (error: any) {
    throw new Error(error.errors[0].message);
  }
};

export const create = async (data: CreateUserInput) => {
  try {
    const result: any = await API.graphql(
      graphqlOperation(createUser, { input: data })
    );

    return result.data.createUser;
  } catch (error: any) {
    throw new Error(error.errors[0].message);
  }
};

export const update = async (data: UpdateUserInput) => {
  try {
    const result: any = await API.graphql(
      graphqlOperation(updateUser, { input: data })
    );

    return result.data.updateUser;
  } catch (error: any) {
    throw new Error(error.errors[0].message);
  }
};

export const softDelete = async (id: string) => {
  try {
    const result: any = await API.graphql(
      graphqlOperation(updateUser, { input: { id, deleted: "1" } })
    );

    return result.data.updateUser;
  } catch (error: any) {
    throw new Error(error.errors[0].message);
  }
};

export const quizzes = async (studentId: string) => {
  try {
    const result: any = await API.graphql({
      query: listQuizStatuses,
      variables: {
        limit: 1000,
        filter: {
          quizStatusStudentId: { eq: studentId },
          deleted: { eq: "0" },
        },
      },
      authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
    });

    console.log({
      studentQuizzesTokens: result.data.listQuizStatuses.nextToken,
    });

    return result.data.listQuizStatuses;
  } catch (error: any) {
    throw new Error(error);
  }
};
