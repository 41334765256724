import { Token } from "../../models/pagination";

// actions
const SET = "questions/SET";
const SET_SEARCH_TEXT = "questions/SET_SEARCH_TEXT";
const SET_DATES = "questions/SET_DATES";
const SET_TOKEN = "questions/SET_TOKEN";
const SET_PAGE = "questions/SET_PAGE";
const SET_PER_PAGE = "questions/SET_PER_PAGE";

const DEFAULT_STATE = {
  questions: [],
  searchText: "",
  datesFilter: [null, null],
  nextToken: null,
  page: 1,
  perPage: 10,
};

// reducer
const moduleReducer = (state = DEFAULT_STATE, action: any = {}) => {
  switch (action.type) {
    case SET:
      return Object.assign({}, state, { questions: action.questions });
    case SET_SEARCH_TEXT:
      return Object.assign({}, state, { searchText: action.searchText });
    case SET_DATES:
      return Object.assign({}, state, { datesFilter: action.dates });
    case SET_TOKEN:
      return Object.assign({}, state, { nextToken: action.token });
    case SET_PAGE:
      return Object.assign({}, state, { page: action.page });
    case SET_PER_PAGE:
      return Object.assign({}, state, { perPage: action.perPage });
    default:
      return state;
  }
};

export default moduleReducer;

// action creators
export const setQuestions = (questions: any) => {
  return { questions, type: SET };
};

export const setSearchText = (searchText: string) => {
  return { searchText, type: SET_SEARCH_TEXT };
};

export const setDates = (dates: any[]) => {
  return { dates, type: SET_DATES };
};

export const setNextToken = (token: Token) => {
  return { token, type: SET_TOKEN };
};

export const setPage = (page: number) => {
  return { page, type: SET_PAGE };
};
export const setPerPage = (perPage: number) => {
  return { perPage, type: SET_PER_PAGE };
};
