import { Feature } from "../../models/api";

// actions
const SET = "features/SET";
const SET_LIST = "features/SET_LIST";
const SET_SEARCH_TEXT = "features/SET_SEARCH_TEXT";
const SET_DATES = "features/SET_DATES";

const DEFAULT_STATE = {
  features: [],
  featuresList: [],
  searchText: "",
  datesFilter: [null, null],
};

// reducer
const featureReducer = (state = DEFAULT_STATE, action: any = {}) => {
  switch (action.type) {
    case SET:
      return Object.assign({}, state, { features: action.features });
    case SET_LIST:
      return Object.assign({}, state, { featuresList: action.list });
    case SET_SEARCH_TEXT:
      return Object.assign({}, state, { searchText: action.searchText });
    case SET_DATES:
      return Object.assign({}, state, { datesFilter: action.dates });
    default:
      return state;
  }
};

export default featureReducer;

// action creators
export const setFeatures = (features: Feature[]) => {
  return { features, type: SET };
};

export const setFeaturesList = (list: Feature[]) => ({
  list,
  type: SET_LIST,
});

export const setSearchText = (searchText: string) => {
  return { searchText, type: SET_SEARCH_TEXT };
};

export const setDates = (dates: any[]) => {
  return { dates, type: SET_DATES };
};
