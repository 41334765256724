import { Language } from "../../models/api";

// actions
const SET = "languages/SET";
const SET_SEARCH_TEXT = "languages/SET_SEARCH_TEXT";
const SET_DATES = "languages/SET_DATES";

const DEFAULT_STATE = {
  languages: [],
  searchText: "",
  datesFilter: [null, null],
};

// reducer
const languagaReducer = (state = DEFAULT_STATE, action: any = {}) => {
  switch (action.type) {
    case SET:
      return Object.assign({}, state, { languages: action.languages });
    case SET_SEARCH_TEXT:
      return Object.assign({}, state, { searchText: action.searchText });
    case SET_DATES:
      return Object.assign({}, state, { datesFilter: action.dates });
    default:
      return state;
  }
};

export default languagaReducer;

// action creators
export const setLanguages = (languages: Language[]) => {
  return { languages, type: SET };
};

export const setSearchText = (searchText: string) => {
  return { searchText, type: SET_SEARCH_TEXT };
};

export const setDates = (dates: any[]) => {
  return { dates, type: SET_DATES };
};
