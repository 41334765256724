import { User } from "../../models/api";

// actions
const SET = "users/SET";
const SET_GROUPS = "users/SET_GROUPS";
const SET_SEARCH_TEXT = "users/SET_SEARCH_TEXT";

const DEFAULT_STATE = {
  users: [],
  groups: [],
  searchText: "",
};

// reducer
const userReducer = (state = DEFAULT_STATE, action: any = {}) => {
  switch (action.type) {
    case SET:
      return Object.assign({}, state, { users: action.users });
    case SET_GROUPS:
      return Object.assign({}, state, { groups: action.groups });
    case SET_SEARCH_TEXT:
      return Object.assign({}, state, { searchText: action.searchText });
    default:
      return state;
  }
};

export default userReducer;

// action creators
export const setUsers = (users: User[]) => {
  return { users, type: SET };
};

export const setGroups = (groups: any) => {
  return { groups, type: SET_GROUPS };
};

export const setSearchText = (searchText: string) => {
  return { searchText, type: SET_SEARCH_TEXT };
};
