// actions
const SET = "quizzes/SET";
const SET_QUESTIONS = "quizzes/SET_QUESTIONS";
const SET_SEARCH_TEXT = "quizzes/SET_SEARCH_TEXT";
const SET_DATES = "quizzes/SET_DATES";
const SET_TOKENS = "quizzes/SET_TOKENS";
const SET_PAGE = "quizzes/SET_PAGE";
const SET_PER_PAGE = "quizzes/SET_PER_PAGE";

const DEFAULT_STATE = {
  quizzes: [],
  searchText: "",
  datesFilter: [null, null],
  nextTokens: [null],
  page: 1,
  perPage: 10,
  questions: [],
};

// reducer
const quizReducer = (state = DEFAULT_STATE, action: any = {}) => {
  switch (action.type) {
    case SET:
      return Object.assign({}, state, { quizzes: action.quizzes });
    case SET_QUESTIONS:
      return Object.assign({}, state, { questions: action.questions });
    case SET_SEARCH_TEXT:
      return Object.assign({}, state, { searchText: action.searchText });
    case SET_DATES:
      return Object.assign({}, state, { datesFilter: action.dates });
    case SET_TOKENS:
      return Object.assign({}, state, { nextTokens: action.tokens });
    case SET_PAGE:
      return Object.assign({}, state, { page: action.page });
    case SET_PER_PAGE:
      return Object.assign({}, state, { perPage: action.perPage });
    default:
      return state;
  }
};

export default quizReducer;

// action creators
export const setQuizzes = (quizzes: any) => {
  return { quizzes, type: SET };
};

export const setQuestions = (questions: any) => {
  return { questions, type: SET_QUESTIONS };
};

export const setSearchText = (searchText: string) => {
  return { searchText, type: SET_SEARCH_TEXT };
};

export const setDates = (dates: any[]) => {
  return { dates, type: SET_DATES };
};

export const setTokens = (tokens: any[]) => {
  return { tokens, type: SET_TOKENS };
};

export const setPage = (page: number) => {
  return { page, type: SET_PAGE };
};
export const setPerPage = (perPage: number) => {
  return { perPage, type: SET_PER_PAGE };
};
