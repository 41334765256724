/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateUserInput = {
  id?: string | null,
  username: string,
  preferred_username: string,
  email: string,
  email_verified?: boolean | null,
  phone_number?: string | null,
  phone_number_verified?: boolean | null,
  name?: string | null,
  nickname?: string | null,
  given_name?: string | null,
  middle_name?: string | null,
  family_name?: string | null,
  address?: string | null,
  birthdate?: string | null,
  gender?: string | null,
  locale?: string | null,
  picture?: string | null,
  website?: string | null,
  zoneinfo?: string | null,
  verification_code?: string | null,
  group?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdBy?: string | null,
};

export type ModelUserConditionInput = {
  username?: ModelStringInput | null,
  preferred_username?: ModelStringInput | null,
  email?: ModelStringInput | null,
  email_verified?: ModelBooleanInput | null,
  phone_number?: ModelStringInput | null,
  phone_number_verified?: ModelBooleanInput | null,
  name?: ModelStringInput | null,
  nickname?: ModelStringInput | null,
  given_name?: ModelStringInput | null,
  middle_name?: ModelStringInput | null,
  family_name?: ModelStringInput | null,
  address?: ModelStringInput | null,
  birthdate?: ModelStringInput | null,
  gender?: ModelStringInput | null,
  locale?: ModelStringInput | null,
  picture?: ModelStringInput | null,
  website?: ModelStringInput | null,
  zoneinfo?: ModelStringInput | null,
  verification_code?: ModelStringInput | null,
  group?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  and?: Array< ModelUserConditionInput | null > | null,
  or?: Array< ModelUserConditionInput | null > | null,
  not?: ModelUserConditionInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type User = {
  __typename: "User",
  id: string,
  courses?: ModelCourseStudentConnection | null,
  username: string,
  preferred_username: string,
  email: string,
  email_verified?: boolean | null,
  phone_number?: string | null,
  phone_number_verified?: boolean | null,
  name?: string | null,
  nickname?: string | null,
  given_name?: string | null,
  middle_name?: string | null,
  family_name?: string | null,
  address?: string | null,
  birthdate?: string | null,
  gender?: string | null,
  locale?: string | null,
  picture?: string | null,
  website?: string | null,
  zoneinfo?: string | null,
  verification_code?: string | null,
  group?: string | null,
  deleted?: string | null,
  createdAt: string,
  createdBy?: string | null,
  updatedAt: string,
};

export type ModelCourseStudentConnection = {
  __typename: "ModelCourseStudentConnection",
  items:  Array<CourseStudent | null >,
  nextToken?: string | null,
};

export type CourseStudent = {
  __typename: "CourseStudent",
  id: string,
  userID: string,
  courseID: string,
  user: User,
  course: Course,
  createdAt: string,
  updatedAt: string,
};

export type Course = {
  __typename: "Course",
  id: string,
  name: string,
  slug: string,
  description?: string | null,
  image?: Attachment | null,
  modules?: ModelModuleConnection | null,
  students?: ModelCourseStudentConnection | null,
  prerequisiteQuiz?: string | null,
  prerequisiteQuizTitle?: string | null,
  prerequisiteQuizSlug?: string | null,
  prerequisiteModule?: string | null,
  prerequisiteModuleTitle?: string | null,
  prerequisiteModuleId?: string | null,
  deleted?: string | null,
  createdAt: string,
  createdBy?: User | null,
  updatedAt: string,
  courseImageId?: string | null,
  courseCreatedById?: string | null,
};

export type Attachment = {
  __typename: "Attachment",
  id: string,
  fileurl: string,
  alternativeText?: string | null,
  createdAt: string,
  createdBy?: User | null,
  updatedAt: string,
  mediaAttachmentsId?: string | null,
  attachmentCreatedById?: string | null,
};

export type ModelModuleConnection = {
  __typename: "ModelModuleConnection",
  items:  Array<Module | null >,
  nextToken?: string | null,
};

export type Module = {
  __typename: "Module",
  id: string,
  name: string,
  parentId?: string | null,
  content: string,
  precedence?: string | null,
  image?: Attachment | null,
  quizzes?: ModelQuizConnection | null,
  prerequisite?: string | null,
  prerequisiteTitle?: string | null,
  afterModuleQuiz?: string | null,
  nextQuiz?: string | null,
  nextQuizTitle?: string | null,
  nextQuizSlug?: string | null,
  nextModule?: string | null,
  nextModuleTitle?: string | null,
  nextModuleId?: string | null,
  nextCourse?: string | null,
  nextCourseTitle?: string | null,
  nextCourseSlug?: string | null,
  vrContent?: Attachment | null,
  deleted?: string | null,
  createdAt: string,
  createdBy?: User | null,
  updatedAt: string,
  courseModulesId?: string | null,
  moduleImageId?: string | null,
  moduleVrContentId?: string | null,
  moduleCreatedById?: string | null,
};

export type ModelQuizConnection = {
  __typename: "ModelQuizConnection",
  items:  Array<Quiz | null >,
  nextToken?: string | null,
};

export type Quiz = {
  __typename: "Quiz",
  id: string,
  title: string,
  slug?: string | null,
  description?: string | null,
  type?: QuizType | null,
  questions?: ModelQuizQuestionConnection | null,
  quizQuestions?: Array< string | null > | null,
  prerequisite?: string | null,
  prerequisiteTitle?: string | null,
  prerequisiteSlug?: string | null,
  prerequisiteModule?: string | null,
  prerequisiteModuleTitle?: string | null,
  nextQuiz?: string | null,
  nextQuizTitle?: string | null,
  nextQuizSlug?: string | null,
  nextCourse?: string | null,
  nextCourseTitle?: string | null,
  nextCourseSlug?: string | null,
  hasFeedback?: boolean | null,
  deleted?: string | null,
  createdAt: string,
  createdBy?: User | null,
  updatedAt: string,
  moduleQuizzesId?: string | null,
  quizTypeId?: string | null,
  quizCreatedById?: string | null,
};

export type QuizType = {
  __typename: "QuizType",
  id: string,
  name: string,
  deleted?: string | null,
  createdAt: string,
  createdBy?: User | null,
  updatedAt: string,
  quizTypeCreatedById?: string | null,
};

export type ModelQuizQuestionConnection = {
  __typename: "ModelQuizQuestionConnection",
  items:  Array<QuizQuestion | null >,
  nextToken?: string | null,
};

export type QuizQuestion = {
  __typename: "QuizQuestion",
  id: string,
  quizID: string,
  questionID: string,
  quiz: Quiz,
  question: Question,
  createdAt: string,
  updatedAt: string,
};

export type Question = {
  __typename: "Question",
  id: string,
  title: string,
  type?: QuestionType | null,
  correctAnswer?: string | null,
  options?: Array< string | null > | null,
  image?: Attachment | null,
  quizzes?: ModelQuizQuestionConnection | null,
  deleted?: string | null,
  createdAt: string,
  createdBy?: User | null,
  updatedAt: string,
  questionTypeId?: string | null,
  questionImageId?: string | null,
  questionCreatedById?: string | null,
};

export type QuestionType = {
  __typename: "QuestionType",
  id: string,
  name: string,
  deleted?: string | null,
  createdAt: string,
  createdBy?: User | null,
  updatedAt: string,
  questionTypeCreatedById?: string | null,
};

export type UpdateUserInput = {
  id: string,
  username?: string | null,
  preferred_username?: string | null,
  email?: string | null,
  email_verified?: boolean | null,
  phone_number?: string | null,
  phone_number_verified?: boolean | null,
  name?: string | null,
  nickname?: string | null,
  given_name?: string | null,
  middle_name?: string | null,
  family_name?: string | null,
  address?: string | null,
  birthdate?: string | null,
  gender?: string | null,
  locale?: string | null,
  picture?: string | null,
  website?: string | null,
  zoneinfo?: string | null,
  verification_code?: string | null,
  group?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdBy?: string | null,
};

export type DeleteUserInput = {
  id: string,
};

export type CreateEmailVerificationInput = {
  id?: string | null,
  name: string,
  email: string,
  verification_code: string,
  createdAt?: string | null,
  deleted?: string | null,
};

export type ModelEmailVerificationConditionInput = {
  name?: ModelStringInput | null,
  email?: ModelStringInput | null,
  verification_code?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  and?: Array< ModelEmailVerificationConditionInput | null > | null,
  or?: Array< ModelEmailVerificationConditionInput | null > | null,
  not?: ModelEmailVerificationConditionInput | null,
};

export type EmailVerification = {
  __typename: "EmailVerification",
  id: string,
  name: string,
  email: string,
  verification_code: string,
  createdAt: string,
  deleted?: string | null,
  updatedAt: string,
};

export type UpdateEmailVerificationInput = {
  id: string,
  name?: string | null,
  email?: string | null,
  verification_code?: string | null,
  createdAt?: string | null,
  deleted?: string | null,
};

export type DeleteEmailVerificationInput = {
  id: string,
};

export type CreateAccountInput = {
  id?: string | null,
  siteTitle: string,
  tagline: string,
  description: string,
  siteAddress: string,
  defaultLanguage: string,
  status: string,
  deleted?: string | null,
  createdAt?: string | null,
  accountLogoId?: string | null,
  accountCreatedById?: string | null,
};

export type ModelAccountConditionInput = {
  siteTitle?: ModelStringInput | null,
  tagline?: ModelStringInput | null,
  description?: ModelStringInput | null,
  siteAddress?: ModelStringInput | null,
  defaultLanguage?: ModelStringInput | null,
  status?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelAccountConditionInput | null > | null,
  or?: Array< ModelAccountConditionInput | null > | null,
  not?: ModelAccountConditionInput | null,
  accountLogoId?: ModelIDInput | null,
  accountCreatedById?: ModelIDInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type Account = {
  __typename: "Account",
  id: string,
  logo?: Attachment | null,
  siteTitle: string,
  tagline: string,
  description: string,
  siteAddress: string,
  defaultLanguage: string,
  languages?: ModelAccountLanguageConnection | null,
  status: string,
  socialLinks?: ModelSocialLinkConnection | null,
  deleted?: string | null,
  createdAt: string,
  createdBy?: User | null,
  updatedAt: string,
  accountLogoId?: string | null,
  accountCreatedById?: string | null,
};

export type ModelAccountLanguageConnection = {
  __typename: "ModelAccountLanguageConnection",
  items:  Array<AccountLanguage | null >,
  nextToken?: string | null,
};

export type AccountLanguage = {
  __typename: "AccountLanguage",
  id: string,
  accountID: string,
  languageID: string,
  account: Account,
  language: Language,
  createdAt: string,
  updatedAt: string,
};

export type Language = {
  __typename: "Language",
  id: string,
  name: string,
  code: string,
  accounts?: ModelAccountLanguageConnection | null,
  deleted?: string | null,
  createdAt: string,
  createdBy?: User | null,
  updatedAt: string,
  languageCreatedById?: string | null,
};

export type ModelSocialLinkConnection = {
  __typename: "ModelSocialLinkConnection",
  items:  Array<SocialLink | null >,
  nextToken?: string | null,
};

export type SocialLink = {
  __typename: "SocialLink",
  id: string,
  name: string,
  icon: string,
  slug: string,
  deleted?: string | null,
  createdAt: string,
  createdBy?: User | null,
  updatedAt: string,
  accountSocialLinksId?: string | null,
  socialLinkCreatedById?: string | null,
};

export type UpdateAccountInput = {
  id: string,
  siteTitle?: string | null,
  tagline?: string | null,
  description?: string | null,
  siteAddress?: string | null,
  defaultLanguage?: string | null,
  status?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  accountLogoId?: string | null,
  accountCreatedById?: string | null,
};

export type DeleteAccountInput = {
  id: string,
};

export type CreateSocialLinkInput = {
  id?: string | null,
  name: string,
  icon: string,
  slug: string,
  deleted?: string | null,
  createdAt?: string | null,
  accountSocialLinksId?: string | null,
  socialLinkCreatedById?: string | null,
};

export type ModelSocialLinkConditionInput = {
  name?: ModelStringInput | null,
  icon?: ModelStringInput | null,
  slug?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelSocialLinkConditionInput | null > | null,
  or?: Array< ModelSocialLinkConditionInput | null > | null,
  not?: ModelSocialLinkConditionInput | null,
  accountSocialLinksId?: ModelIDInput | null,
  socialLinkCreatedById?: ModelIDInput | null,
};

export type UpdateSocialLinkInput = {
  id: string,
  name?: string | null,
  icon?: string | null,
  slug?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  accountSocialLinksId?: string | null,
  socialLinkCreatedById?: string | null,
};

export type DeleteSocialLinkInput = {
  id: string,
};

export type CreateFeatureInput = {
  id?: string | null,
  name: string,
  icon: string,
  slug?: string | null,
  isDashboard: string,
  precedence?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  featureCreatedById?: string | null,
};

export type ModelFeatureConditionInput = {
  name?: ModelStringInput | null,
  icon?: ModelStringInput | null,
  slug?: ModelStringInput | null,
  isDashboard?: ModelStringInput | null,
  precedence?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelFeatureConditionInput | null > | null,
  or?: Array< ModelFeatureConditionInput | null > | null,
  not?: ModelFeatureConditionInput | null,
  featureCreatedById?: ModelIDInput | null,
};

export type Feature = {
  __typename: "Feature",
  id: string,
  name: string,
  icon: string,
  slug?: string | null,
  isDashboard: string,
  precedence?: string | null,
  deleted?: string | null,
  createdAt: string,
  createdBy?: User | null,
  updatedAt: string,
  featureCreatedById?: string | null,
};

export type UpdateFeatureInput = {
  id: string,
  name?: string | null,
  icon?: string | null,
  slug?: string | null,
  isDashboard?: string | null,
  precedence?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  featureCreatedById?: string | null,
};

export type DeleteFeatureInput = {
  id: string,
};

export type CreateLanguageInput = {
  id?: string | null,
  name: string,
  code: string,
  deleted?: string | null,
  createdAt?: string | null,
  languageCreatedById?: string | null,
};

export type ModelLanguageConditionInput = {
  name?: ModelStringInput | null,
  code?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelLanguageConditionInput | null > | null,
  or?: Array< ModelLanguageConditionInput | null > | null,
  not?: ModelLanguageConditionInput | null,
  languageCreatedById?: ModelIDInput | null,
};

export type UpdateLanguageInput = {
  id: string,
  name?: string | null,
  code?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  languageCreatedById?: string | null,
};

export type DeleteLanguageInput = {
  id: string,
};

export type CreateMediaInput = {
  id?: string | null,
  fileurl: string,
  filename: string,
  filetype?: string | null,
  filesize?: number | null,
  alternativeText?: string | null,
  caption?: string | null,
  description?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  mediaCreatedById?: string | null,
};

export type ModelMediaConditionInput = {
  fileurl?: ModelStringInput | null,
  filename?: ModelStringInput | null,
  filetype?: ModelStringInput | null,
  filesize?: ModelIntInput | null,
  alternativeText?: ModelStringInput | null,
  caption?: ModelStringInput | null,
  description?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelMediaConditionInput | null > | null,
  or?: Array< ModelMediaConditionInput | null > | null,
  not?: ModelMediaConditionInput | null,
  mediaCreatedById?: ModelIDInput | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type Media = {
  __typename: "Media",
  id: string,
  fileurl: string,
  filename: string,
  filetype?: string | null,
  filesize?: number | null,
  alternativeText?: string | null,
  caption?: string | null,
  description?: string | null,
  attachments?: ModelAttachmentConnection | null,
  deleted?: string | null,
  createdAt: string,
  createdBy?: User | null,
  updatedAt: string,
  mediaCreatedById?: string | null,
};

export type ModelAttachmentConnection = {
  __typename: "ModelAttachmentConnection",
  items:  Array<Attachment | null >,
  nextToken?: string | null,
};

export type UpdateMediaInput = {
  id: string,
  fileurl?: string | null,
  filename?: string | null,
  filetype?: string | null,
  filesize?: number | null,
  alternativeText?: string | null,
  caption?: string | null,
  description?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  mediaCreatedById?: string | null,
};

export type DeleteMediaInput = {
  id: string,
};

export type CreateAttachmentInput = {
  id?: string | null,
  fileurl: string,
  alternativeText?: string | null,
  createdAt?: string | null,
  mediaAttachmentsId?: string | null,
  attachmentCreatedById?: string | null,
};

export type ModelAttachmentConditionInput = {
  fileurl?: ModelStringInput | null,
  alternativeText?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelAttachmentConditionInput | null > | null,
  or?: Array< ModelAttachmentConditionInput | null > | null,
  not?: ModelAttachmentConditionInput | null,
  mediaAttachmentsId?: ModelIDInput | null,
  attachmentCreatedById?: ModelIDInput | null,
};

export type UpdateAttachmentInput = {
  id: string,
  fileurl?: string | null,
  alternativeText?: string | null,
  createdAt?: string | null,
  mediaAttachmentsId?: string | null,
  attachmentCreatedById?: string | null,
};

export type DeleteAttachmentInput = {
  id: string,
};

export type CreateMenuInput = {
  id?: string | null,
  name: string,
  locations: Array< string | null >,
  deleted?: string | null,
  createdAt?: string | null,
  menuCreatedById?: string | null,
};

export type ModelMenuConditionInput = {
  name?: ModelStringInput | null,
  locations?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelMenuConditionInput | null > | null,
  or?: Array< ModelMenuConditionInput | null > | null,
  not?: ModelMenuConditionInput | null,
  menuCreatedById?: ModelIDInput | null,
};

export type Menu = {
  __typename: "Menu",
  id: string,
  name: string,
  locations: Array< string | null >,
  items?: ModelMenuItemConnection | null,
  deleted?: string | null,
  createdAt: string,
  createdBy?: User | null,
  updatedAt: string,
  menuCreatedById?: string | null,
};

export type ModelMenuItemConnection = {
  __typename: "ModelMenuItemConnection",
  items:  Array<MenuItem | null >,
  nextToken?: string | null,
};

export type MenuItem = {
  __typename: "MenuItem",
  id: string,
  type: string,
  page?: Page | null,
  precedence?: string | null,
  deleted?: string | null,
  createdAt: string,
  createdBy?: User | null,
  updatedAt: string,
  menuItemsId?: string | null,
  menuItemPageId?: string | null,
  menuItemCreatedById?: string | null,
};

export type Page = {
  __typename: "Page",
  id: string,
  title: string,
  slug: string,
  status?: string | null,
  content?: string | null,
  excerpt?: string | null,
  parentId?: string | null,
  thumbnail?: Attachment | null,
  visibility?: string | null,
  deleted?: string | null,
  createdAt: string,
  createdBy?: User | null,
  updatedAt: string,
  pageThumbnailId?: string | null,
  pageCreatedById?: string | null,
};

export type UpdateMenuInput = {
  id: string,
  name?: string | null,
  locations?: Array< string | null > | null,
  deleted?: string | null,
  createdAt?: string | null,
  menuCreatedById?: string | null,
};

export type DeleteMenuInput = {
  id: string,
};

export type CreateMenuItemInput = {
  id?: string | null,
  type: string,
  precedence?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  menuItemsId?: string | null,
  menuItemPageId?: string | null,
  menuItemCreatedById?: string | null,
};

export type ModelMenuItemConditionInput = {
  type?: ModelStringInput | null,
  precedence?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelMenuItemConditionInput | null > | null,
  or?: Array< ModelMenuItemConditionInput | null > | null,
  not?: ModelMenuItemConditionInput | null,
  menuItemsId?: ModelIDInput | null,
  menuItemPageId?: ModelIDInput | null,
  menuItemCreatedById?: ModelIDInput | null,
};

export type UpdateMenuItemInput = {
  id: string,
  type?: string | null,
  precedence?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  menuItemsId?: string | null,
  menuItemPageId?: string | null,
  menuItemCreatedById?: string | null,
};

export type DeleteMenuItemInput = {
  id: string,
};

export type CreatePageInput = {
  id?: string | null,
  title: string,
  slug: string,
  status?: string | null,
  content?: string | null,
  excerpt?: string | null,
  parentId?: string | null,
  visibility?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  pageThumbnailId?: string | null,
  pageCreatedById?: string | null,
};

export type ModelPageConditionInput = {
  title?: ModelStringInput | null,
  slug?: ModelStringInput | null,
  status?: ModelStringInput | null,
  content?: ModelStringInput | null,
  excerpt?: ModelStringInput | null,
  parentId?: ModelStringInput | null,
  visibility?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelPageConditionInput | null > | null,
  or?: Array< ModelPageConditionInput | null > | null,
  not?: ModelPageConditionInput | null,
  pageThumbnailId?: ModelIDInput | null,
  pageCreatedById?: ModelIDInput | null,
};

export type UpdatePageInput = {
  id: string,
  title?: string | null,
  slug?: string | null,
  status?: string | null,
  content?: string | null,
  excerpt?: string | null,
  parentId?: string | null,
  visibility?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  pageThumbnailId?: string | null,
  pageCreatedById?: string | null,
};

export type DeletePageInput = {
  id: string,
};

export type CreateCategoryInput = {
  id?: string | null,
  title: string,
  slug: string,
  type: string,
  description?: string | null,
  visibility?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  categoryThumbnailId?: string | null,
  categoryCreatedById?: string | null,
};

export type ModelCategoryConditionInput = {
  title?: ModelStringInput | null,
  slug?: ModelStringInput | null,
  type?: ModelStringInput | null,
  description?: ModelStringInput | null,
  visibility?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelCategoryConditionInput | null > | null,
  or?: Array< ModelCategoryConditionInput | null > | null,
  not?: ModelCategoryConditionInput | null,
  categoryThumbnailId?: ModelIDInput | null,
  categoryCreatedById?: ModelIDInput | null,
};

export type Category = {
  __typename: "Category",
  id: string,
  title: string,
  slug: string,
  type: string,
  description?: string | null,
  thumbnail?: Attachment | null,
  visibility?: string | null,
  posts?: ModelPostCategoryConnection | null,
  deleted?: string | null,
  createdAt: string,
  createdBy?: User | null,
  updatedAt: string,
  categoryThumbnailId?: string | null,
  categoryCreatedById?: string | null,
};

export type ModelPostCategoryConnection = {
  __typename: "ModelPostCategoryConnection",
  items:  Array<PostCategory | null >,
  nextToken?: string | null,
};

export type PostCategory = {
  __typename: "PostCategory",
  id: string,
  categoryID: string,
  postID: string,
  category: Category,
  post: Post,
  createdAt: string,
  updatedAt: string,
};

export type Post = {
  __typename: "Post",
  id: string,
  title: string,
  slug: string,
  content?: string | null,
  excerpt?: string | null,
  status?: string | null,
  thumbnail?: Attachment | null,
  visibility?: string | null,
  categories?: ModelPostCategoryConnection | null,
  deleted?: string | null,
  createdAt: string,
  createdBy?: User | null,
  updatedAt: string,
  postThumbnailId?: string | null,
  postCreatedById?: string | null,
};

export type UpdateCategoryInput = {
  id: string,
  title?: string | null,
  slug?: string | null,
  type?: string | null,
  description?: string | null,
  visibility?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  categoryThumbnailId?: string | null,
  categoryCreatedById?: string | null,
};

export type DeleteCategoryInput = {
  id: string,
};

export type CreatePostInput = {
  id?: string | null,
  title: string,
  slug: string,
  content?: string | null,
  excerpt?: string | null,
  status?: string | null,
  visibility?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  postThumbnailId?: string | null,
  postCreatedById?: string | null,
};

export type ModelPostConditionInput = {
  title?: ModelStringInput | null,
  slug?: ModelStringInput | null,
  content?: ModelStringInput | null,
  excerpt?: ModelStringInput | null,
  status?: ModelStringInput | null,
  visibility?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelPostConditionInput | null > | null,
  or?: Array< ModelPostConditionInput | null > | null,
  not?: ModelPostConditionInput | null,
  postThumbnailId?: ModelIDInput | null,
  postCreatedById?: ModelIDInput | null,
};

export type UpdatePostInput = {
  id: string,
  title?: string | null,
  slug?: string | null,
  content?: string | null,
  excerpt?: string | null,
  status?: string | null,
  visibility?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  postThumbnailId?: string | null,
  postCreatedById?: string | null,
};

export type DeletePostInput = {
  id: string,
};

export type CreateMessageInput = {
  id?: string | null,
  name: string,
  email: string,
  subject?: string | null,
  message: string,
  deleted?: string | null,
  createdAt?: string | null,
};

export type ModelMessageConditionInput = {
  name?: ModelStringInput | null,
  email?: ModelStringInput | null,
  subject?: ModelStringInput | null,
  message?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelMessageConditionInput | null > | null,
  or?: Array< ModelMessageConditionInput | null > | null,
  not?: ModelMessageConditionInput | null,
};

export type Message = {
  __typename: "Message",
  id: string,
  name: string,
  email: string,
  subject?: string | null,
  message: string,
  deleted?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateMessageInput = {
  id: string,
  name?: string | null,
  email?: string | null,
  subject?: string | null,
  message?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
};

export type DeleteMessageInput = {
  id: string,
};

export type CreateSlideInput = {
  id?: string | null,
  title: string,
  precedence: string,
  content?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  slideImageId?: string | null,
  slideMobImageId?: string | null,
  slideCreatedById?: string | null,
};

export type ModelSlideConditionInput = {
  title?: ModelStringInput | null,
  precedence?: ModelStringInput | null,
  content?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelSlideConditionInput | null > | null,
  or?: Array< ModelSlideConditionInput | null > | null,
  not?: ModelSlideConditionInput | null,
  slideImageId?: ModelIDInput | null,
  slideMobImageId?: ModelIDInput | null,
  slideCreatedById?: ModelIDInput | null,
};

export type Slide = {
  __typename: "Slide",
  id: string,
  title: string,
  precedence: string,
  content?: string | null,
  image?: Attachment | null,
  mobImage?: Attachment | null,
  deleted?: string | null,
  createdAt: string,
  createdBy?: User | null,
  updatedAt: string,
  slideImageId?: string | null,
  slideMobImageId?: string | null,
  slideCreatedById?: string | null,
};

export type UpdateSlideInput = {
  id: string,
  title?: string | null,
  precedence?: string | null,
  content?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  slideImageId?: string | null,
  slideMobImageId?: string | null,
  slideCreatedById?: string | null,
};

export type DeleteSlideInput = {
  id: string,
};

export type CreateCourseInput = {
  id?: string | null,
  name: string,
  slug: string,
  description?: string | null,
  prerequisiteQuiz?: string | null,
  prerequisiteQuizTitle?: string | null,
  prerequisiteQuizSlug?: string | null,
  prerequisiteModule?: string | null,
  prerequisiteModuleTitle?: string | null,
  prerequisiteModuleId?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  courseImageId?: string | null,
  courseCreatedById?: string | null,
};

export type ModelCourseConditionInput = {
  name?: ModelStringInput | null,
  slug?: ModelStringInput | null,
  description?: ModelStringInput | null,
  prerequisiteQuiz?: ModelStringInput | null,
  prerequisiteQuizTitle?: ModelStringInput | null,
  prerequisiteQuizSlug?: ModelStringInput | null,
  prerequisiteModule?: ModelStringInput | null,
  prerequisiteModuleTitle?: ModelStringInput | null,
  prerequisiteModuleId?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelCourseConditionInput | null > | null,
  or?: Array< ModelCourseConditionInput | null > | null,
  not?: ModelCourseConditionInput | null,
  courseImageId?: ModelIDInput | null,
  courseCreatedById?: ModelIDInput | null,
};

export type UpdateCourseInput = {
  id: string,
  name?: string | null,
  slug?: string | null,
  description?: string | null,
  prerequisiteQuiz?: string | null,
  prerequisiteQuizTitle?: string | null,
  prerequisiteQuizSlug?: string | null,
  prerequisiteModule?: string | null,
  prerequisiteModuleTitle?: string | null,
  prerequisiteModuleId?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  courseImageId?: string | null,
  courseCreatedById?: string | null,
};

export type DeleteCourseInput = {
  id: string,
};

export type CreateModuleInput = {
  id?: string | null,
  name: string,
  parentId?: string | null,
  content: string,
  precedence?: string | null,
  prerequisite?: string | null,
  prerequisiteTitle?: string | null,
  afterModuleQuiz?: string | null,
  nextQuiz?: string | null,
  nextQuizTitle?: string | null,
  nextQuizSlug?: string | null,
  nextModule?: string | null,
  nextModuleTitle?: string | null,
  nextModuleId?: string | null,
  nextCourse?: string | null,
  nextCourseTitle?: string | null,
  nextCourseSlug?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  courseModulesId?: string | null,
  moduleImageId?: string | null,
  moduleVrContentId?: string | null,
  moduleCreatedById?: string | null,
};

export type ModelModuleConditionInput = {
  name?: ModelStringInput | null,
  parentId?: ModelStringInput | null,
  content?: ModelStringInput | null,
  precedence?: ModelStringInput | null,
  prerequisite?: ModelStringInput | null,
  prerequisiteTitle?: ModelStringInput | null,
  afterModuleQuiz?: ModelStringInput | null,
  nextQuiz?: ModelStringInput | null,
  nextQuizTitle?: ModelStringInput | null,
  nextQuizSlug?: ModelStringInput | null,
  nextModule?: ModelStringInput | null,
  nextModuleTitle?: ModelStringInput | null,
  nextModuleId?: ModelStringInput | null,
  nextCourse?: ModelStringInput | null,
  nextCourseTitle?: ModelStringInput | null,
  nextCourseSlug?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelModuleConditionInput | null > | null,
  or?: Array< ModelModuleConditionInput | null > | null,
  not?: ModelModuleConditionInput | null,
  courseModulesId?: ModelIDInput | null,
  moduleImageId?: ModelIDInput | null,
  moduleVrContentId?: ModelIDInput | null,
  moduleCreatedById?: ModelIDInput | null,
};

export type UpdateModuleInput = {
  id: string,
  name?: string | null,
  parentId?: string | null,
  content?: string | null,
  precedence?: string | null,
  prerequisite?: string | null,
  prerequisiteTitle?: string | null,
  afterModuleQuiz?: string | null,
  nextQuiz?: string | null,
  nextQuizTitle?: string | null,
  nextQuizSlug?: string | null,
  nextModule?: string | null,
  nextModuleTitle?: string | null,
  nextModuleId?: string | null,
  nextCourse?: string | null,
  nextCourseTitle?: string | null,
  nextCourseSlug?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  courseModulesId?: string | null,
  moduleImageId?: string | null,
  moduleVrContentId?: string | null,
  moduleCreatedById?: string | null,
};

export type DeleteModuleInput = {
  id: string,
};

export type CreateModuleStatusInput = {
  id?: string | null,
  deleted?: string | null,
  isCompleted?: boolean | null,
  createdAt?: string | null,
  moduleStatusModuleId: string,
  moduleStatusStudentId: string,
};

export type ModelModuleStatusConditionInput = {
  deleted?: ModelStringInput | null,
  isCompleted?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelModuleStatusConditionInput | null > | null,
  or?: Array< ModelModuleStatusConditionInput | null > | null,
  not?: ModelModuleStatusConditionInput | null,
  moduleStatusModuleId?: ModelIDInput | null,
  moduleStatusStudentId?: ModelIDInput | null,
};

export type ModuleStatus = {
  __typename: "ModuleStatus",
  id: string,
  module: Module,
  student: User,
  deleted?: string | null,
  isCompleted?: boolean | null,
  createdAt: string,
  updatedAt: string,
  moduleStatusModuleId: string,
  moduleStatusStudentId: string,
};

export type UpdateModuleStatusInput = {
  id: string,
  deleted?: string | null,
  isCompleted?: boolean | null,
  createdAt?: string | null,
  moduleStatusModuleId: string,
  moduleStatusStudentId: string,
};

export type DeleteModuleStatusInput = {
  id: string,
};

export type CreateQuizInput = {
  id?: string | null,
  title: string,
  slug?: string | null,
  description?: string | null,
  quizQuestions?: Array< string | null > | null,
  prerequisite?: string | null,
  prerequisiteTitle?: string | null,
  prerequisiteSlug?: string | null,
  prerequisiteModule?: string | null,
  prerequisiteModuleTitle?: string | null,
  nextQuiz?: string | null,
  nextQuizTitle?: string | null,
  nextQuizSlug?: string | null,
  nextCourse?: string | null,
  nextCourseTitle?: string | null,
  nextCourseSlug?: string | null,
  hasFeedback?: boolean | null,
  deleted?: string | null,
  createdAt?: string | null,
  moduleQuizzesId?: string | null,
  quizTypeId?: string | null,
  quizCreatedById?: string | null,
};

export type ModelQuizConditionInput = {
  title?: ModelStringInput | null,
  slug?: ModelStringInput | null,
  description?: ModelStringInput | null,
  quizQuestions?: ModelStringInput | null,
  prerequisite?: ModelStringInput | null,
  prerequisiteTitle?: ModelStringInput | null,
  prerequisiteSlug?: ModelStringInput | null,
  prerequisiteModule?: ModelStringInput | null,
  prerequisiteModuleTitle?: ModelStringInput | null,
  nextQuiz?: ModelStringInput | null,
  nextQuizTitle?: ModelStringInput | null,
  nextQuizSlug?: ModelStringInput | null,
  nextCourse?: ModelStringInput | null,
  nextCourseTitle?: ModelStringInput | null,
  nextCourseSlug?: ModelStringInput | null,
  hasFeedback?: ModelBooleanInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelQuizConditionInput | null > | null,
  or?: Array< ModelQuizConditionInput | null > | null,
  not?: ModelQuizConditionInput | null,
  moduleQuizzesId?: ModelIDInput | null,
  quizTypeId?: ModelIDInput | null,
  quizCreatedById?: ModelIDInput | null,
};

export type UpdateQuizInput = {
  id: string,
  title?: string | null,
  slug?: string | null,
  description?: string | null,
  quizQuestions?: Array< string | null > | null,
  prerequisite?: string | null,
  prerequisiteTitle?: string | null,
  prerequisiteSlug?: string | null,
  prerequisiteModule?: string | null,
  prerequisiteModuleTitle?: string | null,
  nextQuiz?: string | null,
  nextQuizTitle?: string | null,
  nextQuizSlug?: string | null,
  nextCourse?: string | null,
  nextCourseTitle?: string | null,
  nextCourseSlug?: string | null,
  hasFeedback?: boolean | null,
  deleted?: string | null,
  createdAt?: string | null,
  moduleQuizzesId?: string | null,
  quizTypeId?: string | null,
  quizCreatedById?: string | null,
};

export type DeleteQuizInput = {
  id: string,
};

export type CreateQuizTypeInput = {
  id?: string | null,
  name: string,
  deleted?: string | null,
  createdAt?: string | null,
  quizTypeCreatedById?: string | null,
};

export type ModelQuizTypeConditionInput = {
  name?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelQuizTypeConditionInput | null > | null,
  or?: Array< ModelQuizTypeConditionInput | null > | null,
  not?: ModelQuizTypeConditionInput | null,
  quizTypeCreatedById?: ModelIDInput | null,
};

export type UpdateQuizTypeInput = {
  id: string,
  name?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  quizTypeCreatedById?: string | null,
};

export type DeleteQuizTypeInput = {
  id: string,
};

export type CreateQuizStatusInput = {
  id?: string | null,
  isCompleted: boolean,
  totalScore: number,
  studentScore: number,
  deleted?: string | null,
  createdAt?: string | null,
  quizStatusQuizId: string,
  quizStatusStudentId: string,
};

export type ModelQuizStatusConditionInput = {
  isCompleted?: ModelBooleanInput | null,
  totalScore?: ModelIntInput | null,
  studentScore?: ModelIntInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelQuizStatusConditionInput | null > | null,
  or?: Array< ModelQuizStatusConditionInput | null > | null,
  not?: ModelQuizStatusConditionInput | null,
  quizStatusQuizId?: ModelIDInput | null,
  quizStatusStudentId?: ModelIDInput | null,
};

export type QuizStatus = {
  __typename: "QuizStatus",
  id: string,
  quiz: Quiz,
  student: User,
  isCompleted: boolean,
  totalScore: number,
  studentScore: number,
  deleted?: string | null,
  createdAt: string,
  updatedAt: string,
  quizStatusQuizId: string,
  quizStatusStudentId: string,
};

export type UpdateQuizStatusInput = {
  id: string,
  isCompleted?: boolean | null,
  totalScore?: number | null,
  studentScore?: number | null,
  deleted?: string | null,
  createdAt?: string | null,
  quizStatusQuizId: string,
  quizStatusStudentId: string,
};

export type DeleteQuizStatusInput = {
  id: string,
};

export type CreateQuestionTypeInput = {
  id?: string | null,
  name: string,
  deleted?: string | null,
  createdAt?: string | null,
  questionTypeCreatedById?: string | null,
};

export type ModelQuestionTypeConditionInput = {
  name?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelQuestionTypeConditionInput | null > | null,
  or?: Array< ModelQuestionTypeConditionInput | null > | null,
  not?: ModelQuestionTypeConditionInput | null,
  questionTypeCreatedById?: ModelIDInput | null,
};

export type UpdateQuestionTypeInput = {
  id: string,
  name?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  questionTypeCreatedById?: string | null,
};

export type DeleteQuestionTypeInput = {
  id: string,
};

export type CreateQuestionInput = {
  id?: string | null,
  title: string,
  correctAnswer?: string | null,
  options?: Array< string | null > | null,
  deleted?: string | null,
  createdAt?: string | null,
  questionTypeId?: string | null,
  questionImageId?: string | null,
  questionCreatedById?: string | null,
};

export type ModelQuestionConditionInput = {
  title?: ModelStringInput | null,
  correctAnswer?: ModelStringInput | null,
  options?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelQuestionConditionInput | null > | null,
  or?: Array< ModelQuestionConditionInput | null > | null,
  not?: ModelQuestionConditionInput | null,
  questionTypeId?: ModelIDInput | null,
  questionImageId?: ModelIDInput | null,
  questionCreatedById?: ModelIDInput | null,
};

export type UpdateQuestionInput = {
  id: string,
  title?: string | null,
  correctAnswer?: string | null,
  options?: Array< string | null > | null,
  deleted?: string | null,
  createdAt?: string | null,
  questionTypeId?: string | null,
  questionImageId?: string | null,
  questionCreatedById?: string | null,
};

export type DeleteQuestionInput = {
  id: string,
};

export type CreateAnswerInput = {
  id?: string | null,
  values: Array< string | null >,
  questionID?: string | null,
  studentID?: string | null,
  deleted?: string | null,
  isCorrect?: boolean | null,
  createdAt?: string | null,
  answerQuestionId: string,
  answerStudentId: string,
};

export type ModelAnswerConditionInput = {
  values?: ModelStringInput | null,
  questionID?: ModelStringInput | null,
  studentID?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  isCorrect?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelAnswerConditionInput | null > | null,
  or?: Array< ModelAnswerConditionInput | null > | null,
  not?: ModelAnswerConditionInput | null,
  answerQuestionId?: ModelIDInput | null,
  answerStudentId?: ModelIDInput | null,
};

export type Answer = {
  __typename: "Answer",
  id: string,
  question: Question,
  student: User,
  values: Array< string | null >,
  questionID?: string | null,
  studentID?: string | null,
  deleted?: string | null,
  isCorrect?: boolean | null,
  createdAt: string,
  updatedAt: string,
  answerQuestionId: string,
  answerStudentId: string,
};

export type UpdateAnswerInput = {
  id: string,
  values?: Array< string | null > | null,
  questionID?: string | null,
  studentID?: string | null,
  deleted?: string | null,
  isCorrect?: boolean | null,
  createdAt?: string | null,
  answerQuestionId: string,
  answerStudentId: string,
};

export type DeleteAnswerInput = {
  id: string,
};

export type CreateCourseStudentInput = {
  id?: string | null,
  userID: string,
  courseID: string,
};

export type ModelCourseStudentConditionInput = {
  userID?: ModelIDInput | null,
  courseID?: ModelIDInput | null,
  and?: Array< ModelCourseStudentConditionInput | null > | null,
  or?: Array< ModelCourseStudentConditionInput | null > | null,
  not?: ModelCourseStudentConditionInput | null,
};

export type UpdateCourseStudentInput = {
  id: string,
  userID?: string | null,
  courseID?: string | null,
};

export type DeleteCourseStudentInput = {
  id: string,
};

export type CreateAccountLanguageInput = {
  id?: string | null,
  accountID: string,
  languageID: string,
};

export type ModelAccountLanguageConditionInput = {
  accountID?: ModelIDInput | null,
  languageID?: ModelIDInput | null,
  and?: Array< ModelAccountLanguageConditionInput | null > | null,
  or?: Array< ModelAccountLanguageConditionInput | null > | null,
  not?: ModelAccountLanguageConditionInput | null,
};

export type UpdateAccountLanguageInput = {
  id: string,
  accountID?: string | null,
  languageID?: string | null,
};

export type DeleteAccountLanguageInput = {
  id: string,
};

export type CreatePostCategoryInput = {
  id?: string | null,
  categoryID: string,
  postID: string,
};

export type ModelPostCategoryConditionInput = {
  categoryID?: ModelIDInput | null,
  postID?: ModelIDInput | null,
  and?: Array< ModelPostCategoryConditionInput | null > | null,
  or?: Array< ModelPostCategoryConditionInput | null > | null,
  not?: ModelPostCategoryConditionInput | null,
};

export type UpdatePostCategoryInput = {
  id: string,
  categoryID?: string | null,
  postID?: string | null,
};

export type DeletePostCategoryInput = {
  id: string,
};

export type CreateQuizQuestionInput = {
  id?: string | null,
  quizID: string,
  questionID: string,
};

export type ModelQuizQuestionConditionInput = {
  quizID?: ModelIDInput | null,
  questionID?: ModelIDInput | null,
  and?: Array< ModelQuizQuestionConditionInput | null > | null,
  or?: Array< ModelQuizQuestionConditionInput | null > | null,
  not?: ModelQuizQuestionConditionInput | null,
};

export type UpdateQuizQuestionInput = {
  id: string,
  quizID?: string | null,
  questionID?: string | null,
};

export type DeleteQuizQuestionInput = {
  id: string,
};

export type ModelUserFilterInput = {
  id?: ModelIDInput | null,
  username?: ModelStringInput | null,
  preferred_username?: ModelStringInput | null,
  email?: ModelStringInput | null,
  email_verified?: ModelBooleanInput | null,
  phone_number?: ModelStringInput | null,
  phone_number_verified?: ModelBooleanInput | null,
  name?: ModelStringInput | null,
  nickname?: ModelStringInput | null,
  given_name?: ModelStringInput | null,
  middle_name?: ModelStringInput | null,
  family_name?: ModelStringInput | null,
  address?: ModelStringInput | null,
  birthdate?: ModelStringInput | null,
  gender?: ModelStringInput | null,
  locale?: ModelStringInput | null,
  picture?: ModelStringInput | null,
  website?: ModelStringInput | null,
  zoneinfo?: ModelStringInput | null,
  verification_code?: ModelStringInput | null,
  group?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  and?: Array< ModelUserFilterInput | null > | null,
  or?: Array< ModelUserFilterInput | null > | null,
  not?: ModelUserFilterInput | null,
};

export type ModelUserConnection = {
  __typename: "ModelUserConnection",
  items:  Array<User | null >,
  nextToken?: string | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelEmailVerificationFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  email?: ModelStringInput | null,
  verification_code?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  and?: Array< ModelEmailVerificationFilterInput | null > | null,
  or?: Array< ModelEmailVerificationFilterInput | null > | null,
  not?: ModelEmailVerificationFilterInput | null,
};

export type ModelEmailVerificationConnection = {
  __typename: "ModelEmailVerificationConnection",
  items:  Array<EmailVerification | null >,
  nextToken?: string | null,
};

export type ModelAccountFilterInput = {
  id?: ModelIDInput | null,
  siteTitle?: ModelStringInput | null,
  tagline?: ModelStringInput | null,
  description?: ModelStringInput | null,
  siteAddress?: ModelStringInput | null,
  defaultLanguage?: ModelStringInput | null,
  status?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelAccountFilterInput | null > | null,
  or?: Array< ModelAccountFilterInput | null > | null,
  not?: ModelAccountFilterInput | null,
  accountLogoId?: ModelIDInput | null,
  accountCreatedById?: ModelIDInput | null,
};

export type ModelAccountConnection = {
  __typename: "ModelAccountConnection",
  items:  Array<Account | null >,
  nextToken?: string | null,
};

export type ModelSocialLinkFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  icon?: ModelStringInput | null,
  slug?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelSocialLinkFilterInput | null > | null,
  or?: Array< ModelSocialLinkFilterInput | null > | null,
  not?: ModelSocialLinkFilterInput | null,
  accountSocialLinksId?: ModelIDInput | null,
  socialLinkCreatedById?: ModelIDInput | null,
};

export type ModelFeatureFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  icon?: ModelStringInput | null,
  slug?: ModelStringInput | null,
  isDashboard?: ModelStringInput | null,
  precedence?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelFeatureFilterInput | null > | null,
  or?: Array< ModelFeatureFilterInput | null > | null,
  not?: ModelFeatureFilterInput | null,
  featureCreatedById?: ModelIDInput | null,
};

export type ModelFeatureConnection = {
  __typename: "ModelFeatureConnection",
  items:  Array<Feature | null >,
  nextToken?: string | null,
};

export type ModelLanguageFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  code?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelLanguageFilterInput | null > | null,
  or?: Array< ModelLanguageFilterInput | null > | null,
  not?: ModelLanguageFilterInput | null,
  languageCreatedById?: ModelIDInput | null,
};

export type ModelLanguageConnection = {
  __typename: "ModelLanguageConnection",
  items:  Array<Language | null >,
  nextToken?: string | null,
};

export type ModelMediaFilterInput = {
  id?: ModelIDInput | null,
  fileurl?: ModelStringInput | null,
  filename?: ModelStringInput | null,
  filetype?: ModelStringInput | null,
  filesize?: ModelIntInput | null,
  alternativeText?: ModelStringInput | null,
  caption?: ModelStringInput | null,
  description?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelMediaFilterInput | null > | null,
  or?: Array< ModelMediaFilterInput | null > | null,
  not?: ModelMediaFilterInput | null,
  mediaCreatedById?: ModelIDInput | null,
};

export type ModelMediaConnection = {
  __typename: "ModelMediaConnection",
  items:  Array<Media | null >,
  nextToken?: string | null,
};

export type ModelAttachmentFilterInput = {
  id?: ModelIDInput | null,
  fileurl?: ModelStringInput | null,
  alternativeText?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelAttachmentFilterInput | null > | null,
  or?: Array< ModelAttachmentFilterInput | null > | null,
  not?: ModelAttachmentFilterInput | null,
  mediaAttachmentsId?: ModelIDInput | null,
  attachmentCreatedById?: ModelIDInput | null,
};

export type ModelMenuFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  locations?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelMenuFilterInput | null > | null,
  or?: Array< ModelMenuFilterInput | null > | null,
  not?: ModelMenuFilterInput | null,
  menuCreatedById?: ModelIDInput | null,
};

export type ModelMenuConnection = {
  __typename: "ModelMenuConnection",
  items:  Array<Menu | null >,
  nextToken?: string | null,
};

export type ModelMenuItemFilterInput = {
  id?: ModelIDInput | null,
  type?: ModelStringInput | null,
  precedence?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelMenuItemFilterInput | null > | null,
  or?: Array< ModelMenuItemFilterInput | null > | null,
  not?: ModelMenuItemFilterInput | null,
  menuItemsId?: ModelIDInput | null,
  menuItemPageId?: ModelIDInput | null,
  menuItemCreatedById?: ModelIDInput | null,
};

export type ModelPageFilterInput = {
  id?: ModelIDInput | null,
  title?: ModelStringInput | null,
  slug?: ModelStringInput | null,
  status?: ModelStringInput | null,
  content?: ModelStringInput | null,
  excerpt?: ModelStringInput | null,
  parentId?: ModelStringInput | null,
  visibility?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelPageFilterInput | null > | null,
  or?: Array< ModelPageFilterInput | null > | null,
  not?: ModelPageFilterInput | null,
  pageThumbnailId?: ModelIDInput | null,
  pageCreatedById?: ModelIDInput | null,
};

export type ModelPageConnection = {
  __typename: "ModelPageConnection",
  items:  Array<Page | null >,
  nextToken?: string | null,
};

export type ModelCategoryFilterInput = {
  id?: ModelIDInput | null,
  title?: ModelStringInput | null,
  slug?: ModelStringInput | null,
  type?: ModelStringInput | null,
  description?: ModelStringInput | null,
  visibility?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelCategoryFilterInput | null > | null,
  or?: Array< ModelCategoryFilterInput | null > | null,
  not?: ModelCategoryFilterInput | null,
  categoryThumbnailId?: ModelIDInput | null,
  categoryCreatedById?: ModelIDInput | null,
};

export type ModelCategoryConnection = {
  __typename: "ModelCategoryConnection",
  items:  Array<Category | null >,
  nextToken?: string | null,
};

export type ModelPostFilterInput = {
  id?: ModelIDInput | null,
  title?: ModelStringInput | null,
  slug?: ModelStringInput | null,
  content?: ModelStringInput | null,
  excerpt?: ModelStringInput | null,
  status?: ModelStringInput | null,
  visibility?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelPostFilterInput | null > | null,
  or?: Array< ModelPostFilterInput | null > | null,
  not?: ModelPostFilterInput | null,
  postThumbnailId?: ModelIDInput | null,
  postCreatedById?: ModelIDInput | null,
};

export type ModelPostConnection = {
  __typename: "ModelPostConnection",
  items:  Array<Post | null >,
  nextToken?: string | null,
};

export type ModelMessageFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  email?: ModelStringInput | null,
  subject?: ModelStringInput | null,
  message?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelMessageFilterInput | null > | null,
  or?: Array< ModelMessageFilterInput | null > | null,
  not?: ModelMessageFilterInput | null,
};

export type ModelMessageConnection = {
  __typename: "ModelMessageConnection",
  items:  Array<Message | null >,
  nextToken?: string | null,
};

export type ModelSlideFilterInput = {
  id?: ModelIDInput | null,
  title?: ModelStringInput | null,
  precedence?: ModelStringInput | null,
  content?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelSlideFilterInput | null > | null,
  or?: Array< ModelSlideFilterInput | null > | null,
  not?: ModelSlideFilterInput | null,
  slideImageId?: ModelIDInput | null,
  slideMobImageId?: ModelIDInput | null,
  slideCreatedById?: ModelIDInput | null,
};

export type ModelSlideConnection = {
  __typename: "ModelSlideConnection",
  items:  Array<Slide | null >,
  nextToken?: string | null,
};

export type ModelCourseFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  slug?: ModelStringInput | null,
  description?: ModelStringInput | null,
  prerequisiteQuiz?: ModelStringInput | null,
  prerequisiteQuizTitle?: ModelStringInput | null,
  prerequisiteQuizSlug?: ModelStringInput | null,
  prerequisiteModule?: ModelStringInput | null,
  prerequisiteModuleTitle?: ModelStringInput | null,
  prerequisiteModuleId?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelCourseFilterInput | null > | null,
  or?: Array< ModelCourseFilterInput | null > | null,
  not?: ModelCourseFilterInput | null,
  courseImageId?: ModelIDInput | null,
  courseCreatedById?: ModelIDInput | null,
};

export type ModelCourseConnection = {
  __typename: "ModelCourseConnection",
  items:  Array<Course | null >,
  nextToken?: string | null,
};

export type ModelModuleFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  parentId?: ModelStringInput | null,
  content?: ModelStringInput | null,
  precedence?: ModelStringInput | null,
  prerequisite?: ModelStringInput | null,
  prerequisiteTitle?: ModelStringInput | null,
  afterModuleQuiz?: ModelStringInput | null,
  nextQuiz?: ModelStringInput | null,
  nextQuizTitle?: ModelStringInput | null,
  nextQuizSlug?: ModelStringInput | null,
  nextModule?: ModelStringInput | null,
  nextModuleTitle?: ModelStringInput | null,
  nextModuleId?: ModelStringInput | null,
  nextCourse?: ModelStringInput | null,
  nextCourseTitle?: ModelStringInput | null,
  nextCourseSlug?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelModuleFilterInput | null > | null,
  or?: Array< ModelModuleFilterInput | null > | null,
  not?: ModelModuleFilterInput | null,
  courseModulesId?: ModelIDInput | null,
  moduleImageId?: ModelIDInput | null,
  moduleVrContentId?: ModelIDInput | null,
  moduleCreatedById?: ModelIDInput | null,
};

export type ModelModuleStatusFilterInput = {
  id?: ModelIDInput | null,
  deleted?: ModelStringInput | null,
  isCompleted?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelModuleStatusFilterInput | null > | null,
  or?: Array< ModelModuleStatusFilterInput | null > | null,
  not?: ModelModuleStatusFilterInput | null,
  moduleStatusModuleId?: ModelIDInput | null,
  moduleStatusStudentId?: ModelIDInput | null,
};

export type ModelModuleStatusConnection = {
  __typename: "ModelModuleStatusConnection",
  items:  Array<ModuleStatus | null >,
  nextToken?: string | null,
};

export type ModelQuizFilterInput = {
  id?: ModelIDInput | null,
  title?: ModelStringInput | null,
  slug?: ModelStringInput | null,
  description?: ModelStringInput | null,
  quizQuestions?: ModelStringInput | null,
  prerequisite?: ModelStringInput | null,
  prerequisiteTitle?: ModelStringInput | null,
  prerequisiteSlug?: ModelStringInput | null,
  prerequisiteModule?: ModelStringInput | null,
  prerequisiteModuleTitle?: ModelStringInput | null,
  nextQuiz?: ModelStringInput | null,
  nextQuizTitle?: ModelStringInput | null,
  nextQuizSlug?: ModelStringInput | null,
  nextCourse?: ModelStringInput | null,
  nextCourseTitle?: ModelStringInput | null,
  nextCourseSlug?: ModelStringInput | null,
  hasFeedback?: ModelBooleanInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelQuizFilterInput | null > | null,
  or?: Array< ModelQuizFilterInput | null > | null,
  not?: ModelQuizFilterInput | null,
  moduleQuizzesId?: ModelIDInput | null,
  quizTypeId?: ModelIDInput | null,
  quizCreatedById?: ModelIDInput | null,
};

export type ModelQuizTypeFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelQuizTypeFilterInput | null > | null,
  or?: Array< ModelQuizTypeFilterInput | null > | null,
  not?: ModelQuizTypeFilterInput | null,
  quizTypeCreatedById?: ModelIDInput | null,
};

export type ModelQuizTypeConnection = {
  __typename: "ModelQuizTypeConnection",
  items:  Array<QuizType | null >,
  nextToken?: string | null,
};

export type ModelQuizStatusFilterInput = {
  id?: ModelIDInput | null,
  isCompleted?: ModelBooleanInput | null,
  totalScore?: ModelIntInput | null,
  studentScore?: ModelIntInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelQuizStatusFilterInput | null > | null,
  or?: Array< ModelQuizStatusFilterInput | null > | null,
  not?: ModelQuizStatusFilterInput | null,
  quizStatusQuizId?: ModelIDInput | null,
  quizStatusStudentId?: ModelIDInput | null,
};

export type ModelQuizStatusConnection = {
  __typename: "ModelQuizStatusConnection",
  items:  Array<QuizStatus | null >,
  nextToken?: string | null,
};

export type ModelQuestionTypeFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelQuestionTypeFilterInput | null > | null,
  or?: Array< ModelQuestionTypeFilterInput | null > | null,
  not?: ModelQuestionTypeFilterInput | null,
  questionTypeCreatedById?: ModelIDInput | null,
};

export type ModelQuestionTypeConnection = {
  __typename: "ModelQuestionTypeConnection",
  items:  Array<QuestionType | null >,
  nextToken?: string | null,
};

export type ModelQuestionFilterInput = {
  id?: ModelIDInput | null,
  title?: ModelStringInput | null,
  correctAnswer?: ModelStringInput | null,
  options?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelQuestionFilterInput | null > | null,
  or?: Array< ModelQuestionFilterInput | null > | null,
  not?: ModelQuestionFilterInput | null,
  questionTypeId?: ModelIDInput | null,
  questionImageId?: ModelIDInput | null,
  questionCreatedById?: ModelIDInput | null,
};

export type ModelQuestionConnection = {
  __typename: "ModelQuestionConnection",
  items:  Array<Question | null >,
  nextToken?: string | null,
};

export type ModelAnswerFilterInput = {
  id?: ModelIDInput | null,
  values?: ModelStringInput | null,
  questionID?: ModelStringInput | null,
  studentID?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  isCorrect?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelAnswerFilterInput | null > | null,
  or?: Array< ModelAnswerFilterInput | null > | null,
  not?: ModelAnswerFilterInput | null,
  answerQuestionId?: ModelIDInput | null,
  answerStudentId?: ModelIDInput | null,
};

export type ModelAnswerConnection = {
  __typename: "ModelAnswerConnection",
  items:  Array<Answer | null >,
  nextToken?: string | null,
};

export type ModelCourseStudentFilterInput = {
  id?: ModelIDInput | null,
  userID?: ModelIDInput | null,
  courseID?: ModelIDInput | null,
  and?: Array< ModelCourseStudentFilterInput | null > | null,
  or?: Array< ModelCourseStudentFilterInput | null > | null,
  not?: ModelCourseStudentFilterInput | null,
};

export type ModelAccountLanguageFilterInput = {
  id?: ModelIDInput | null,
  accountID?: ModelIDInput | null,
  languageID?: ModelIDInput | null,
  and?: Array< ModelAccountLanguageFilterInput | null > | null,
  or?: Array< ModelAccountLanguageFilterInput | null > | null,
  not?: ModelAccountLanguageFilterInput | null,
};

export type ModelPostCategoryFilterInput = {
  id?: ModelIDInput | null,
  categoryID?: ModelIDInput | null,
  postID?: ModelIDInput | null,
  and?: Array< ModelPostCategoryFilterInput | null > | null,
  or?: Array< ModelPostCategoryFilterInput | null > | null,
  not?: ModelPostCategoryFilterInput | null,
};

export type ModelQuizQuestionFilterInput = {
  id?: ModelIDInput | null,
  quizID?: ModelIDInput | null,
  questionID?: ModelIDInput | null,
  and?: Array< ModelQuizQuestionFilterInput | null > | null,
  or?: Array< ModelQuizQuestionFilterInput | null > | null,
  not?: ModelQuizQuestionFilterInput | null,
};

export type CreateUserMutationVariables = {
  input: CreateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type CreateUserMutation = {
  createUser?:  {
    __typename: "User",
    id: string,
    courses?:  {
      __typename: "ModelCourseStudentConnection",
      items:  Array< {
        __typename: "CourseStudent",
        id: string,
        userID: string,
        courseID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    username: string,
    preferred_username: string,
    email: string,
    email_verified?: boolean | null,
    phone_number?: string | null,
    phone_number_verified?: boolean | null,
    name?: string | null,
    nickname?: string | null,
    given_name?: string | null,
    middle_name?: string | null,
    family_name?: string | null,
    address?: string | null,
    birthdate?: string | null,
    gender?: string | null,
    locale?: string | null,
    picture?: string | null,
    website?: string | null,
    zoneinfo?: string | null,
    verification_code?: string | null,
    group?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?: string | null,
    updatedAt: string,
  } | null,
};

export type UpdateUserMutationVariables = {
  input: UpdateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type UpdateUserMutation = {
  updateUser?:  {
    __typename: "User",
    id: string,
    courses?:  {
      __typename: "ModelCourseStudentConnection",
      items:  Array< {
        __typename: "CourseStudent",
        id: string,
        userID: string,
        courseID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    username: string,
    preferred_username: string,
    email: string,
    email_verified?: boolean | null,
    phone_number?: string | null,
    phone_number_verified?: boolean | null,
    name?: string | null,
    nickname?: string | null,
    given_name?: string | null,
    middle_name?: string | null,
    family_name?: string | null,
    address?: string | null,
    birthdate?: string | null,
    gender?: string | null,
    locale?: string | null,
    picture?: string | null,
    website?: string | null,
    zoneinfo?: string | null,
    verification_code?: string | null,
    group?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?: string | null,
    updatedAt: string,
  } | null,
};

export type DeleteUserMutationVariables = {
  input: DeleteUserInput,
  condition?: ModelUserConditionInput | null,
};

export type DeleteUserMutation = {
  deleteUser?:  {
    __typename: "User",
    id: string,
    courses?:  {
      __typename: "ModelCourseStudentConnection",
      items:  Array< {
        __typename: "CourseStudent",
        id: string,
        userID: string,
        courseID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    username: string,
    preferred_username: string,
    email: string,
    email_verified?: boolean | null,
    phone_number?: string | null,
    phone_number_verified?: boolean | null,
    name?: string | null,
    nickname?: string | null,
    given_name?: string | null,
    middle_name?: string | null,
    family_name?: string | null,
    address?: string | null,
    birthdate?: string | null,
    gender?: string | null,
    locale?: string | null,
    picture?: string | null,
    website?: string | null,
    zoneinfo?: string | null,
    verification_code?: string | null,
    group?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?: string | null,
    updatedAt: string,
  } | null,
};

export type CreateEmailVerificationMutationVariables = {
  input: CreateEmailVerificationInput,
  condition?: ModelEmailVerificationConditionInput | null,
};

export type CreateEmailVerificationMutation = {
  createEmailVerification?:  {
    __typename: "EmailVerification",
    id: string,
    name: string,
    email: string,
    verification_code: string,
    createdAt: string,
    deleted?: string | null,
    updatedAt: string,
  } | null,
};

export type UpdateEmailVerificationMutationVariables = {
  input: UpdateEmailVerificationInput,
  condition?: ModelEmailVerificationConditionInput | null,
};

export type UpdateEmailVerificationMutation = {
  updateEmailVerification?:  {
    __typename: "EmailVerification",
    id: string,
    name: string,
    email: string,
    verification_code: string,
    createdAt: string,
    deleted?: string | null,
    updatedAt: string,
  } | null,
};

export type DeleteEmailVerificationMutationVariables = {
  input: DeleteEmailVerificationInput,
  condition?: ModelEmailVerificationConditionInput | null,
};

export type DeleteEmailVerificationMutation = {
  deleteEmailVerification?:  {
    __typename: "EmailVerification",
    id: string,
    name: string,
    email: string,
    verification_code: string,
    createdAt: string,
    deleted?: string | null,
    updatedAt: string,
  } | null,
};

export type CreateAccountMutationVariables = {
  input: CreateAccountInput,
  condition?: ModelAccountConditionInput | null,
};

export type CreateAccountMutation = {
  createAccount?:  {
    __typename: "Account",
    id: string,
    logo?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    siteTitle: string,
    tagline: string,
    description: string,
    siteAddress: string,
    defaultLanguage: string,
    languages?:  {
      __typename: "ModelAccountLanguageConnection",
      items:  Array< {
        __typename: "AccountLanguage",
        id: string,
        accountID: string,
        languageID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    status: string,
    socialLinks?:  {
      __typename: "ModelSocialLinkConnection",
      items:  Array< {
        __typename: "SocialLink",
        id: string,
        name: string,
        icon: string,
        slug: string,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        accountSocialLinksId?: string | null,
        socialLinkCreatedById?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    accountLogoId?: string | null,
    accountCreatedById?: string | null,
  } | null,
};

export type UpdateAccountMutationVariables = {
  input: UpdateAccountInput,
  condition?: ModelAccountConditionInput | null,
};

export type UpdateAccountMutation = {
  updateAccount?:  {
    __typename: "Account",
    id: string,
    logo?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    siteTitle: string,
    tagline: string,
    description: string,
    siteAddress: string,
    defaultLanguage: string,
    languages?:  {
      __typename: "ModelAccountLanguageConnection",
      items:  Array< {
        __typename: "AccountLanguage",
        id: string,
        accountID: string,
        languageID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    status: string,
    socialLinks?:  {
      __typename: "ModelSocialLinkConnection",
      items:  Array< {
        __typename: "SocialLink",
        id: string,
        name: string,
        icon: string,
        slug: string,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        accountSocialLinksId?: string | null,
        socialLinkCreatedById?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    accountLogoId?: string | null,
    accountCreatedById?: string | null,
  } | null,
};

export type DeleteAccountMutationVariables = {
  input: DeleteAccountInput,
  condition?: ModelAccountConditionInput | null,
};

export type DeleteAccountMutation = {
  deleteAccount?:  {
    __typename: "Account",
    id: string,
    logo?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    siteTitle: string,
    tagline: string,
    description: string,
    siteAddress: string,
    defaultLanguage: string,
    languages?:  {
      __typename: "ModelAccountLanguageConnection",
      items:  Array< {
        __typename: "AccountLanguage",
        id: string,
        accountID: string,
        languageID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    status: string,
    socialLinks?:  {
      __typename: "ModelSocialLinkConnection",
      items:  Array< {
        __typename: "SocialLink",
        id: string,
        name: string,
        icon: string,
        slug: string,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        accountSocialLinksId?: string | null,
        socialLinkCreatedById?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    accountLogoId?: string | null,
    accountCreatedById?: string | null,
  } | null,
};

export type CreateSocialLinkMutationVariables = {
  input: CreateSocialLinkInput,
  condition?: ModelSocialLinkConditionInput | null,
};

export type CreateSocialLinkMutation = {
  createSocialLink?:  {
    __typename: "SocialLink",
    id: string,
    name: string,
    icon: string,
    slug: string,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    accountSocialLinksId?: string | null,
    socialLinkCreatedById?: string | null,
  } | null,
};

export type UpdateSocialLinkMutationVariables = {
  input: UpdateSocialLinkInput,
  condition?: ModelSocialLinkConditionInput | null,
};

export type UpdateSocialLinkMutation = {
  updateSocialLink?:  {
    __typename: "SocialLink",
    id: string,
    name: string,
    icon: string,
    slug: string,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    accountSocialLinksId?: string | null,
    socialLinkCreatedById?: string | null,
  } | null,
};

export type DeleteSocialLinkMutationVariables = {
  input: DeleteSocialLinkInput,
  condition?: ModelSocialLinkConditionInput | null,
};

export type DeleteSocialLinkMutation = {
  deleteSocialLink?:  {
    __typename: "SocialLink",
    id: string,
    name: string,
    icon: string,
    slug: string,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    accountSocialLinksId?: string | null,
    socialLinkCreatedById?: string | null,
  } | null,
};

export type CreateFeatureMutationVariables = {
  input: CreateFeatureInput,
  condition?: ModelFeatureConditionInput | null,
};

export type CreateFeatureMutation = {
  createFeature?:  {
    __typename: "Feature",
    id: string,
    name: string,
    icon: string,
    slug?: string | null,
    isDashboard: string,
    precedence?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    featureCreatedById?: string | null,
  } | null,
};

export type UpdateFeatureMutationVariables = {
  input: UpdateFeatureInput,
  condition?: ModelFeatureConditionInput | null,
};

export type UpdateFeatureMutation = {
  updateFeature?:  {
    __typename: "Feature",
    id: string,
    name: string,
    icon: string,
    slug?: string | null,
    isDashboard: string,
    precedence?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    featureCreatedById?: string | null,
  } | null,
};

export type DeleteFeatureMutationVariables = {
  input: DeleteFeatureInput,
  condition?: ModelFeatureConditionInput | null,
};

export type DeleteFeatureMutation = {
  deleteFeature?:  {
    __typename: "Feature",
    id: string,
    name: string,
    icon: string,
    slug?: string | null,
    isDashboard: string,
    precedence?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    featureCreatedById?: string | null,
  } | null,
};

export type CreateLanguageMutationVariables = {
  input: CreateLanguageInput,
  condition?: ModelLanguageConditionInput | null,
};

export type CreateLanguageMutation = {
  createLanguage?:  {
    __typename: "Language",
    id: string,
    name: string,
    code: string,
    accounts?:  {
      __typename: "ModelAccountLanguageConnection",
      items:  Array< {
        __typename: "AccountLanguage",
        id: string,
        accountID: string,
        languageID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    languageCreatedById?: string | null,
  } | null,
};

export type UpdateLanguageMutationVariables = {
  input: UpdateLanguageInput,
  condition?: ModelLanguageConditionInput | null,
};

export type UpdateLanguageMutation = {
  updateLanguage?:  {
    __typename: "Language",
    id: string,
    name: string,
    code: string,
    accounts?:  {
      __typename: "ModelAccountLanguageConnection",
      items:  Array< {
        __typename: "AccountLanguage",
        id: string,
        accountID: string,
        languageID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    languageCreatedById?: string | null,
  } | null,
};

export type DeleteLanguageMutationVariables = {
  input: DeleteLanguageInput,
  condition?: ModelLanguageConditionInput | null,
};

export type DeleteLanguageMutation = {
  deleteLanguage?:  {
    __typename: "Language",
    id: string,
    name: string,
    code: string,
    accounts?:  {
      __typename: "ModelAccountLanguageConnection",
      items:  Array< {
        __typename: "AccountLanguage",
        id: string,
        accountID: string,
        languageID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    languageCreatedById?: string | null,
  } | null,
};

export type CreateMediaMutationVariables = {
  input: CreateMediaInput,
  condition?: ModelMediaConditionInput | null,
};

export type CreateMediaMutation = {
  createMedia?:  {
    __typename: "Media",
    id: string,
    fileurl: string,
    filename: string,
    filetype?: string | null,
    filesize?: number | null,
    alternativeText?: string | null,
    caption?: string | null,
    description?: string | null,
    attachments?:  {
      __typename: "ModelAttachmentConnection",
      items:  Array< {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    mediaCreatedById?: string | null,
  } | null,
};

export type UpdateMediaMutationVariables = {
  input: UpdateMediaInput,
  condition?: ModelMediaConditionInput | null,
};

export type UpdateMediaMutation = {
  updateMedia?:  {
    __typename: "Media",
    id: string,
    fileurl: string,
    filename: string,
    filetype?: string | null,
    filesize?: number | null,
    alternativeText?: string | null,
    caption?: string | null,
    description?: string | null,
    attachments?:  {
      __typename: "ModelAttachmentConnection",
      items:  Array< {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    mediaCreatedById?: string | null,
  } | null,
};

export type DeleteMediaMutationVariables = {
  input: DeleteMediaInput,
  condition?: ModelMediaConditionInput | null,
};

export type DeleteMediaMutation = {
  deleteMedia?:  {
    __typename: "Media",
    id: string,
    fileurl: string,
    filename: string,
    filetype?: string | null,
    filesize?: number | null,
    alternativeText?: string | null,
    caption?: string | null,
    description?: string | null,
    attachments?:  {
      __typename: "ModelAttachmentConnection",
      items:  Array< {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    mediaCreatedById?: string | null,
  } | null,
};

export type CreateAttachmentMutationVariables = {
  input: CreateAttachmentInput,
  condition?: ModelAttachmentConditionInput | null,
};

export type CreateAttachmentMutation = {
  createAttachment?:  {
    __typename: "Attachment",
    id: string,
    fileurl: string,
    alternativeText?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    mediaAttachmentsId?: string | null,
    attachmentCreatedById?: string | null,
  } | null,
};

export type UpdateAttachmentMutationVariables = {
  input: UpdateAttachmentInput,
  condition?: ModelAttachmentConditionInput | null,
};

export type UpdateAttachmentMutation = {
  updateAttachment?:  {
    __typename: "Attachment",
    id: string,
    fileurl: string,
    alternativeText?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    mediaAttachmentsId?: string | null,
    attachmentCreatedById?: string | null,
  } | null,
};

export type DeleteAttachmentMutationVariables = {
  input: DeleteAttachmentInput,
  condition?: ModelAttachmentConditionInput | null,
};

export type DeleteAttachmentMutation = {
  deleteAttachment?:  {
    __typename: "Attachment",
    id: string,
    fileurl: string,
    alternativeText?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    mediaAttachmentsId?: string | null,
    attachmentCreatedById?: string | null,
  } | null,
};

export type CreateMenuMutationVariables = {
  input: CreateMenuInput,
  condition?: ModelMenuConditionInput | null,
};

export type CreateMenuMutation = {
  createMenu?:  {
    __typename: "Menu",
    id: string,
    name: string,
    locations: Array< string | null >,
    items?:  {
      __typename: "ModelMenuItemConnection",
      items:  Array< {
        __typename: "MenuItem",
        id: string,
        type: string,
        precedence?: string | null,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        menuItemsId?: string | null,
        menuItemPageId?: string | null,
        menuItemCreatedById?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    menuCreatedById?: string | null,
  } | null,
};

export type UpdateMenuMutationVariables = {
  input: UpdateMenuInput,
  condition?: ModelMenuConditionInput | null,
};

export type UpdateMenuMutation = {
  updateMenu?:  {
    __typename: "Menu",
    id: string,
    name: string,
    locations: Array< string | null >,
    items?:  {
      __typename: "ModelMenuItemConnection",
      items:  Array< {
        __typename: "MenuItem",
        id: string,
        type: string,
        precedence?: string | null,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        menuItemsId?: string | null,
        menuItemPageId?: string | null,
        menuItemCreatedById?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    menuCreatedById?: string | null,
  } | null,
};

export type DeleteMenuMutationVariables = {
  input: DeleteMenuInput,
  condition?: ModelMenuConditionInput | null,
};

export type DeleteMenuMutation = {
  deleteMenu?:  {
    __typename: "Menu",
    id: string,
    name: string,
    locations: Array< string | null >,
    items?:  {
      __typename: "ModelMenuItemConnection",
      items:  Array< {
        __typename: "MenuItem",
        id: string,
        type: string,
        precedence?: string | null,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        menuItemsId?: string | null,
        menuItemPageId?: string | null,
        menuItemCreatedById?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    menuCreatedById?: string | null,
  } | null,
};

export type CreateMenuItemMutationVariables = {
  input: CreateMenuItemInput,
  condition?: ModelMenuItemConditionInput | null,
};

export type CreateMenuItemMutation = {
  createMenuItem?:  {
    __typename: "MenuItem",
    id: string,
    type: string,
    page?:  {
      __typename: "Page",
      id: string,
      title: string,
      slug: string,
      status?: string | null,
      content?: string | null,
      excerpt?: string | null,
      parentId?: string | null,
      thumbnail?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      visibility?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      pageThumbnailId?: string | null,
      pageCreatedById?: string | null,
    } | null,
    precedence?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    menuItemsId?: string | null,
    menuItemPageId?: string | null,
    menuItemCreatedById?: string | null,
  } | null,
};

export type UpdateMenuItemMutationVariables = {
  input: UpdateMenuItemInput,
  condition?: ModelMenuItemConditionInput | null,
};

export type UpdateMenuItemMutation = {
  updateMenuItem?:  {
    __typename: "MenuItem",
    id: string,
    type: string,
    page?:  {
      __typename: "Page",
      id: string,
      title: string,
      slug: string,
      status?: string | null,
      content?: string | null,
      excerpt?: string | null,
      parentId?: string | null,
      thumbnail?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      visibility?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      pageThumbnailId?: string | null,
      pageCreatedById?: string | null,
    } | null,
    precedence?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    menuItemsId?: string | null,
    menuItemPageId?: string | null,
    menuItemCreatedById?: string | null,
  } | null,
};

export type DeleteMenuItemMutationVariables = {
  input: DeleteMenuItemInput,
  condition?: ModelMenuItemConditionInput | null,
};

export type DeleteMenuItemMutation = {
  deleteMenuItem?:  {
    __typename: "MenuItem",
    id: string,
    type: string,
    page?:  {
      __typename: "Page",
      id: string,
      title: string,
      slug: string,
      status?: string | null,
      content?: string | null,
      excerpt?: string | null,
      parentId?: string | null,
      thumbnail?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      visibility?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      pageThumbnailId?: string | null,
      pageCreatedById?: string | null,
    } | null,
    precedence?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    menuItemsId?: string | null,
    menuItemPageId?: string | null,
    menuItemCreatedById?: string | null,
  } | null,
};

export type CreatePageMutationVariables = {
  input: CreatePageInput,
  condition?: ModelPageConditionInput | null,
};

export type CreatePageMutation = {
  createPage?:  {
    __typename: "Page",
    id: string,
    title: string,
    slug: string,
    status?: string | null,
    content?: string | null,
    excerpt?: string | null,
    parentId?: string | null,
    thumbnail?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    visibility?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    pageThumbnailId?: string | null,
    pageCreatedById?: string | null,
  } | null,
};

export type UpdatePageMutationVariables = {
  input: UpdatePageInput,
  condition?: ModelPageConditionInput | null,
};

export type UpdatePageMutation = {
  updatePage?:  {
    __typename: "Page",
    id: string,
    title: string,
    slug: string,
    status?: string | null,
    content?: string | null,
    excerpt?: string | null,
    parentId?: string | null,
    thumbnail?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    visibility?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    pageThumbnailId?: string | null,
    pageCreatedById?: string | null,
  } | null,
};

export type DeletePageMutationVariables = {
  input: DeletePageInput,
  condition?: ModelPageConditionInput | null,
};

export type DeletePageMutation = {
  deletePage?:  {
    __typename: "Page",
    id: string,
    title: string,
    slug: string,
    status?: string | null,
    content?: string | null,
    excerpt?: string | null,
    parentId?: string | null,
    thumbnail?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    visibility?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    pageThumbnailId?: string | null,
    pageCreatedById?: string | null,
  } | null,
};

export type CreateCategoryMutationVariables = {
  input: CreateCategoryInput,
  condition?: ModelCategoryConditionInput | null,
};

export type CreateCategoryMutation = {
  createCategory?:  {
    __typename: "Category",
    id: string,
    title: string,
    slug: string,
    type: string,
    description?: string | null,
    thumbnail?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    visibility?: string | null,
    posts?:  {
      __typename: "ModelPostCategoryConnection",
      items:  Array< {
        __typename: "PostCategory",
        id: string,
        categoryID: string,
        postID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    categoryThumbnailId?: string | null,
    categoryCreatedById?: string | null,
  } | null,
};

export type UpdateCategoryMutationVariables = {
  input: UpdateCategoryInput,
  condition?: ModelCategoryConditionInput | null,
};

export type UpdateCategoryMutation = {
  updateCategory?:  {
    __typename: "Category",
    id: string,
    title: string,
    slug: string,
    type: string,
    description?: string | null,
    thumbnail?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    visibility?: string | null,
    posts?:  {
      __typename: "ModelPostCategoryConnection",
      items:  Array< {
        __typename: "PostCategory",
        id: string,
        categoryID: string,
        postID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    categoryThumbnailId?: string | null,
    categoryCreatedById?: string | null,
  } | null,
};

export type DeleteCategoryMutationVariables = {
  input: DeleteCategoryInput,
  condition?: ModelCategoryConditionInput | null,
};

export type DeleteCategoryMutation = {
  deleteCategory?:  {
    __typename: "Category",
    id: string,
    title: string,
    slug: string,
    type: string,
    description?: string | null,
    thumbnail?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    visibility?: string | null,
    posts?:  {
      __typename: "ModelPostCategoryConnection",
      items:  Array< {
        __typename: "PostCategory",
        id: string,
        categoryID: string,
        postID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    categoryThumbnailId?: string | null,
    categoryCreatedById?: string | null,
  } | null,
};

export type CreatePostMutationVariables = {
  input: CreatePostInput,
  condition?: ModelPostConditionInput | null,
};

export type CreatePostMutation = {
  createPost?:  {
    __typename: "Post",
    id: string,
    title: string,
    slug: string,
    content?: string | null,
    excerpt?: string | null,
    status?: string | null,
    thumbnail?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    visibility?: string | null,
    categories?:  {
      __typename: "ModelPostCategoryConnection",
      items:  Array< {
        __typename: "PostCategory",
        id: string,
        categoryID: string,
        postID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    postThumbnailId?: string | null,
    postCreatedById?: string | null,
  } | null,
};

export type UpdatePostMutationVariables = {
  input: UpdatePostInput,
  condition?: ModelPostConditionInput | null,
};

export type UpdatePostMutation = {
  updatePost?:  {
    __typename: "Post",
    id: string,
    title: string,
    slug: string,
    content?: string | null,
    excerpt?: string | null,
    status?: string | null,
    thumbnail?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    visibility?: string | null,
    categories?:  {
      __typename: "ModelPostCategoryConnection",
      items:  Array< {
        __typename: "PostCategory",
        id: string,
        categoryID: string,
        postID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    postThumbnailId?: string | null,
    postCreatedById?: string | null,
  } | null,
};

export type DeletePostMutationVariables = {
  input: DeletePostInput,
  condition?: ModelPostConditionInput | null,
};

export type DeletePostMutation = {
  deletePost?:  {
    __typename: "Post",
    id: string,
    title: string,
    slug: string,
    content?: string | null,
    excerpt?: string | null,
    status?: string | null,
    thumbnail?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    visibility?: string | null,
    categories?:  {
      __typename: "ModelPostCategoryConnection",
      items:  Array< {
        __typename: "PostCategory",
        id: string,
        categoryID: string,
        postID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    postThumbnailId?: string | null,
    postCreatedById?: string | null,
  } | null,
};

export type CreateMessageMutationVariables = {
  input: CreateMessageInput,
  condition?: ModelMessageConditionInput | null,
};

export type CreateMessageMutation = {
  createMessage?:  {
    __typename: "Message",
    id: string,
    name: string,
    email: string,
    subject?: string | null,
    message: string,
    deleted?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateMessageMutationVariables = {
  input: UpdateMessageInput,
  condition?: ModelMessageConditionInput | null,
};

export type UpdateMessageMutation = {
  updateMessage?:  {
    __typename: "Message",
    id: string,
    name: string,
    email: string,
    subject?: string | null,
    message: string,
    deleted?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteMessageMutationVariables = {
  input: DeleteMessageInput,
  condition?: ModelMessageConditionInput | null,
};

export type DeleteMessageMutation = {
  deleteMessage?:  {
    __typename: "Message",
    id: string,
    name: string,
    email: string,
    subject?: string | null,
    message: string,
    deleted?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateSlideMutationVariables = {
  input: CreateSlideInput,
  condition?: ModelSlideConditionInput | null,
};

export type CreateSlideMutation = {
  createSlide?:  {
    __typename: "Slide",
    id: string,
    title: string,
    precedence: string,
    content?: string | null,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    mobImage?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    slideImageId?: string | null,
    slideMobImageId?: string | null,
    slideCreatedById?: string | null,
  } | null,
};

export type UpdateSlideMutationVariables = {
  input: UpdateSlideInput,
  condition?: ModelSlideConditionInput | null,
};

export type UpdateSlideMutation = {
  updateSlide?:  {
    __typename: "Slide",
    id: string,
    title: string,
    precedence: string,
    content?: string | null,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    mobImage?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    slideImageId?: string | null,
    slideMobImageId?: string | null,
    slideCreatedById?: string | null,
  } | null,
};

export type DeleteSlideMutationVariables = {
  input: DeleteSlideInput,
  condition?: ModelSlideConditionInput | null,
};

export type DeleteSlideMutation = {
  deleteSlide?:  {
    __typename: "Slide",
    id: string,
    title: string,
    precedence: string,
    content?: string | null,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    mobImage?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    slideImageId?: string | null,
    slideMobImageId?: string | null,
    slideCreatedById?: string | null,
  } | null,
};

export type CreateCourseMutationVariables = {
  input: CreateCourseInput,
  condition?: ModelCourseConditionInput | null,
};

export type CreateCourseMutation = {
  createCourse?:  {
    __typename: "Course",
    id: string,
    name: string,
    slug: string,
    description?: string | null,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    modules?:  {
      __typename: "ModelModuleConnection",
      items:  Array< {
        __typename: "Module",
        id: string,
        name: string,
        parentId?: string | null,
        content: string,
        precedence?: string | null,
        prerequisite?: string | null,
        prerequisiteTitle?: string | null,
        afterModuleQuiz?: string | null,
        nextQuiz?: string | null,
        nextQuizTitle?: string | null,
        nextQuizSlug?: string | null,
        nextModule?: string | null,
        nextModuleTitle?: string | null,
        nextModuleId?: string | null,
        nextCourse?: string | null,
        nextCourseTitle?: string | null,
        nextCourseSlug?: string | null,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        courseModulesId?: string | null,
        moduleImageId?: string | null,
        moduleVrContentId?: string | null,
        moduleCreatedById?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    students?:  {
      __typename: "ModelCourseStudentConnection",
      items:  Array< {
        __typename: "CourseStudent",
        id: string,
        userID: string,
        courseID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    prerequisiteQuiz?: string | null,
    prerequisiteQuizTitle?: string | null,
    prerequisiteQuizSlug?: string | null,
    prerequisiteModule?: string | null,
    prerequisiteModuleTitle?: string | null,
    prerequisiteModuleId?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    courseImageId?: string | null,
    courseCreatedById?: string | null,
  } | null,
};

export type UpdateCourseMutationVariables = {
  input: UpdateCourseInput,
  condition?: ModelCourseConditionInput | null,
};

export type UpdateCourseMutation = {
  updateCourse?:  {
    __typename: "Course",
    id: string,
    name: string,
    slug: string,
    description?: string | null,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    modules?:  {
      __typename: "ModelModuleConnection",
      items:  Array< {
        __typename: "Module",
        id: string,
        name: string,
        parentId?: string | null,
        content: string,
        precedence?: string | null,
        prerequisite?: string | null,
        prerequisiteTitle?: string | null,
        afterModuleQuiz?: string | null,
        nextQuiz?: string | null,
        nextQuizTitle?: string | null,
        nextQuizSlug?: string | null,
        nextModule?: string | null,
        nextModuleTitle?: string | null,
        nextModuleId?: string | null,
        nextCourse?: string | null,
        nextCourseTitle?: string | null,
        nextCourseSlug?: string | null,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        courseModulesId?: string | null,
        moduleImageId?: string | null,
        moduleVrContentId?: string | null,
        moduleCreatedById?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    students?:  {
      __typename: "ModelCourseStudentConnection",
      items:  Array< {
        __typename: "CourseStudent",
        id: string,
        userID: string,
        courseID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    prerequisiteQuiz?: string | null,
    prerequisiteQuizTitle?: string | null,
    prerequisiteQuizSlug?: string | null,
    prerequisiteModule?: string | null,
    prerequisiteModuleTitle?: string | null,
    prerequisiteModuleId?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    courseImageId?: string | null,
    courseCreatedById?: string | null,
  } | null,
};

export type DeleteCourseMutationVariables = {
  input: DeleteCourseInput,
  condition?: ModelCourseConditionInput | null,
};

export type DeleteCourseMutation = {
  deleteCourse?:  {
    __typename: "Course",
    id: string,
    name: string,
    slug: string,
    description?: string | null,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    modules?:  {
      __typename: "ModelModuleConnection",
      items:  Array< {
        __typename: "Module",
        id: string,
        name: string,
        parentId?: string | null,
        content: string,
        precedence?: string | null,
        prerequisite?: string | null,
        prerequisiteTitle?: string | null,
        afterModuleQuiz?: string | null,
        nextQuiz?: string | null,
        nextQuizTitle?: string | null,
        nextQuizSlug?: string | null,
        nextModule?: string | null,
        nextModuleTitle?: string | null,
        nextModuleId?: string | null,
        nextCourse?: string | null,
        nextCourseTitle?: string | null,
        nextCourseSlug?: string | null,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        courseModulesId?: string | null,
        moduleImageId?: string | null,
        moduleVrContentId?: string | null,
        moduleCreatedById?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    students?:  {
      __typename: "ModelCourseStudentConnection",
      items:  Array< {
        __typename: "CourseStudent",
        id: string,
        userID: string,
        courseID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    prerequisiteQuiz?: string | null,
    prerequisiteQuizTitle?: string | null,
    prerequisiteQuizSlug?: string | null,
    prerequisiteModule?: string | null,
    prerequisiteModuleTitle?: string | null,
    prerequisiteModuleId?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    courseImageId?: string | null,
    courseCreatedById?: string | null,
  } | null,
};

export type CreateModuleMutationVariables = {
  input: CreateModuleInput,
  condition?: ModelModuleConditionInput | null,
};

export type CreateModuleMutation = {
  createModule?:  {
    __typename: "Module",
    id: string,
    name: string,
    parentId?: string | null,
    content: string,
    precedence?: string | null,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    quizzes?:  {
      __typename: "ModelQuizConnection",
      items:  Array< {
        __typename: "Quiz",
        id: string,
        title: string,
        slug?: string | null,
        description?: string | null,
        quizQuestions?: Array< string | null > | null,
        prerequisite?: string | null,
        prerequisiteTitle?: string | null,
        prerequisiteSlug?: string | null,
        prerequisiteModule?: string | null,
        prerequisiteModuleTitle?: string | null,
        nextQuiz?: string | null,
        nextQuizTitle?: string | null,
        nextQuizSlug?: string | null,
        nextCourse?: string | null,
        nextCourseTitle?: string | null,
        nextCourseSlug?: string | null,
        hasFeedback?: boolean | null,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        moduleQuizzesId?: string | null,
        quizTypeId?: string | null,
        quizCreatedById?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    prerequisite?: string | null,
    prerequisiteTitle?: string | null,
    afterModuleQuiz?: string | null,
    nextQuiz?: string | null,
    nextQuizTitle?: string | null,
    nextQuizSlug?: string | null,
    nextModule?: string | null,
    nextModuleTitle?: string | null,
    nextModuleId?: string | null,
    nextCourse?: string | null,
    nextCourseTitle?: string | null,
    nextCourseSlug?: string | null,
    vrContent?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    courseModulesId?: string | null,
    moduleImageId?: string | null,
    moduleVrContentId?: string | null,
    moduleCreatedById?: string | null,
  } | null,
};

export type UpdateModuleMutationVariables = {
  input: UpdateModuleInput,
  condition?: ModelModuleConditionInput | null,
};

export type UpdateModuleMutation = {
  updateModule?:  {
    __typename: "Module",
    id: string,
    name: string,
    parentId?: string | null,
    content: string,
    precedence?: string | null,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    quizzes?:  {
      __typename: "ModelQuizConnection",
      items:  Array< {
        __typename: "Quiz",
        id: string,
        title: string,
        slug?: string | null,
        description?: string | null,
        quizQuestions?: Array< string | null > | null,
        prerequisite?: string | null,
        prerequisiteTitle?: string | null,
        prerequisiteSlug?: string | null,
        prerequisiteModule?: string | null,
        prerequisiteModuleTitle?: string | null,
        nextQuiz?: string | null,
        nextQuizTitle?: string | null,
        nextQuizSlug?: string | null,
        nextCourse?: string | null,
        nextCourseTitle?: string | null,
        nextCourseSlug?: string | null,
        hasFeedback?: boolean | null,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        moduleQuizzesId?: string | null,
        quizTypeId?: string | null,
        quizCreatedById?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    prerequisite?: string | null,
    prerequisiteTitle?: string | null,
    afterModuleQuiz?: string | null,
    nextQuiz?: string | null,
    nextQuizTitle?: string | null,
    nextQuizSlug?: string | null,
    nextModule?: string | null,
    nextModuleTitle?: string | null,
    nextModuleId?: string | null,
    nextCourse?: string | null,
    nextCourseTitle?: string | null,
    nextCourseSlug?: string | null,
    vrContent?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    courseModulesId?: string | null,
    moduleImageId?: string | null,
    moduleVrContentId?: string | null,
    moduleCreatedById?: string | null,
  } | null,
};

export type DeleteModuleMutationVariables = {
  input: DeleteModuleInput,
  condition?: ModelModuleConditionInput | null,
};

export type DeleteModuleMutation = {
  deleteModule?:  {
    __typename: "Module",
    id: string,
    name: string,
    parentId?: string | null,
    content: string,
    precedence?: string | null,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    quizzes?:  {
      __typename: "ModelQuizConnection",
      items:  Array< {
        __typename: "Quiz",
        id: string,
        title: string,
        slug?: string | null,
        description?: string | null,
        quizQuestions?: Array< string | null > | null,
        prerequisite?: string | null,
        prerequisiteTitle?: string | null,
        prerequisiteSlug?: string | null,
        prerequisiteModule?: string | null,
        prerequisiteModuleTitle?: string | null,
        nextQuiz?: string | null,
        nextQuizTitle?: string | null,
        nextQuizSlug?: string | null,
        nextCourse?: string | null,
        nextCourseTitle?: string | null,
        nextCourseSlug?: string | null,
        hasFeedback?: boolean | null,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        moduleQuizzesId?: string | null,
        quizTypeId?: string | null,
        quizCreatedById?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    prerequisite?: string | null,
    prerequisiteTitle?: string | null,
    afterModuleQuiz?: string | null,
    nextQuiz?: string | null,
    nextQuizTitle?: string | null,
    nextQuizSlug?: string | null,
    nextModule?: string | null,
    nextModuleTitle?: string | null,
    nextModuleId?: string | null,
    nextCourse?: string | null,
    nextCourseTitle?: string | null,
    nextCourseSlug?: string | null,
    vrContent?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    courseModulesId?: string | null,
    moduleImageId?: string | null,
    moduleVrContentId?: string | null,
    moduleCreatedById?: string | null,
  } | null,
};

export type CreateModuleStatusMutationVariables = {
  input: CreateModuleStatusInput,
  condition?: ModelModuleStatusConditionInput | null,
};

export type CreateModuleStatusMutation = {
  createModuleStatus?:  {
    __typename: "ModuleStatus",
    id: string,
    module:  {
      __typename: "Module",
      id: string,
      name: string,
      parentId?: string | null,
      content: string,
      precedence?: string | null,
      image?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      quizzes?:  {
        __typename: "ModelQuizConnection",
        nextToken?: string | null,
      } | null,
      prerequisite?: string | null,
      prerequisiteTitle?: string | null,
      afterModuleQuiz?: string | null,
      nextQuiz?: string | null,
      nextQuizTitle?: string | null,
      nextQuizSlug?: string | null,
      nextModule?: string | null,
      nextModuleTitle?: string | null,
      nextModuleId?: string | null,
      nextCourse?: string | null,
      nextCourseTitle?: string | null,
      nextCourseSlug?: string | null,
      vrContent?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      courseModulesId?: string | null,
      moduleImageId?: string | null,
      moduleVrContentId?: string | null,
      moduleCreatedById?: string | null,
    },
    student:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    },
    deleted?: string | null,
    isCompleted?: boolean | null,
    createdAt: string,
    updatedAt: string,
    moduleStatusModuleId: string,
    moduleStatusStudentId: string,
  } | null,
};

export type UpdateModuleStatusMutationVariables = {
  input: UpdateModuleStatusInput,
  condition?: ModelModuleStatusConditionInput | null,
};

export type UpdateModuleStatusMutation = {
  updateModuleStatus?:  {
    __typename: "ModuleStatus",
    id: string,
    module:  {
      __typename: "Module",
      id: string,
      name: string,
      parentId?: string | null,
      content: string,
      precedence?: string | null,
      image?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      quizzes?:  {
        __typename: "ModelQuizConnection",
        nextToken?: string | null,
      } | null,
      prerequisite?: string | null,
      prerequisiteTitle?: string | null,
      afterModuleQuiz?: string | null,
      nextQuiz?: string | null,
      nextQuizTitle?: string | null,
      nextQuizSlug?: string | null,
      nextModule?: string | null,
      nextModuleTitle?: string | null,
      nextModuleId?: string | null,
      nextCourse?: string | null,
      nextCourseTitle?: string | null,
      nextCourseSlug?: string | null,
      vrContent?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      courseModulesId?: string | null,
      moduleImageId?: string | null,
      moduleVrContentId?: string | null,
      moduleCreatedById?: string | null,
    },
    student:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    },
    deleted?: string | null,
    isCompleted?: boolean | null,
    createdAt: string,
    updatedAt: string,
    moduleStatusModuleId: string,
    moduleStatusStudentId: string,
  } | null,
};

export type DeleteModuleStatusMutationVariables = {
  input: DeleteModuleStatusInput,
  condition?: ModelModuleStatusConditionInput | null,
};

export type DeleteModuleStatusMutation = {
  deleteModuleStatus?:  {
    __typename: "ModuleStatus",
    id: string,
    module:  {
      __typename: "Module",
      id: string,
      name: string,
      parentId?: string | null,
      content: string,
      precedence?: string | null,
      image?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      quizzes?:  {
        __typename: "ModelQuizConnection",
        nextToken?: string | null,
      } | null,
      prerequisite?: string | null,
      prerequisiteTitle?: string | null,
      afterModuleQuiz?: string | null,
      nextQuiz?: string | null,
      nextQuizTitle?: string | null,
      nextQuizSlug?: string | null,
      nextModule?: string | null,
      nextModuleTitle?: string | null,
      nextModuleId?: string | null,
      nextCourse?: string | null,
      nextCourseTitle?: string | null,
      nextCourseSlug?: string | null,
      vrContent?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      courseModulesId?: string | null,
      moduleImageId?: string | null,
      moduleVrContentId?: string | null,
      moduleCreatedById?: string | null,
    },
    student:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    },
    deleted?: string | null,
    isCompleted?: boolean | null,
    createdAt: string,
    updatedAt: string,
    moduleStatusModuleId: string,
    moduleStatusStudentId: string,
  } | null,
};

export type CreateQuizMutationVariables = {
  input: CreateQuizInput,
  condition?: ModelQuizConditionInput | null,
};

export type CreateQuizMutation = {
  createQuiz?:  {
    __typename: "Quiz",
    id: string,
    title: string,
    slug?: string | null,
    description?: string | null,
    type?:  {
      __typename: "QuizType",
      id: string,
      name: string,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      quizTypeCreatedById?: string | null,
    } | null,
    questions?:  {
      __typename: "ModelQuizQuestionConnection",
      items:  Array< {
        __typename: "QuizQuestion",
        id: string,
        quizID: string,
        questionID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    quizQuestions?: Array< string | null > | null,
    prerequisite?: string | null,
    prerequisiteTitle?: string | null,
    prerequisiteSlug?: string | null,
    prerequisiteModule?: string | null,
    prerequisiteModuleTitle?: string | null,
    nextQuiz?: string | null,
    nextQuizTitle?: string | null,
    nextQuizSlug?: string | null,
    nextCourse?: string | null,
    nextCourseTitle?: string | null,
    nextCourseSlug?: string | null,
    hasFeedback?: boolean | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    moduleQuizzesId?: string | null,
    quizTypeId?: string | null,
    quizCreatedById?: string | null,
  } | null,
};

export type UpdateQuizMutationVariables = {
  input: UpdateQuizInput,
  condition?: ModelQuizConditionInput | null,
};

export type UpdateQuizMutation = {
  updateQuiz?:  {
    __typename: "Quiz",
    id: string,
    title: string,
    slug?: string | null,
    description?: string | null,
    type?:  {
      __typename: "QuizType",
      id: string,
      name: string,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      quizTypeCreatedById?: string | null,
    } | null,
    questions?:  {
      __typename: "ModelQuizQuestionConnection",
      items:  Array< {
        __typename: "QuizQuestion",
        id: string,
        quizID: string,
        questionID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    quizQuestions?: Array< string | null > | null,
    prerequisite?: string | null,
    prerequisiteTitle?: string | null,
    prerequisiteSlug?: string | null,
    prerequisiteModule?: string | null,
    prerequisiteModuleTitle?: string | null,
    nextQuiz?: string | null,
    nextQuizTitle?: string | null,
    nextQuizSlug?: string | null,
    nextCourse?: string | null,
    nextCourseTitle?: string | null,
    nextCourseSlug?: string | null,
    hasFeedback?: boolean | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    moduleQuizzesId?: string | null,
    quizTypeId?: string | null,
    quizCreatedById?: string | null,
  } | null,
};

export type DeleteQuizMutationVariables = {
  input: DeleteQuizInput,
  condition?: ModelQuizConditionInput | null,
};

export type DeleteQuizMutation = {
  deleteQuiz?:  {
    __typename: "Quiz",
    id: string,
    title: string,
    slug?: string | null,
    description?: string | null,
    type?:  {
      __typename: "QuizType",
      id: string,
      name: string,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      quizTypeCreatedById?: string | null,
    } | null,
    questions?:  {
      __typename: "ModelQuizQuestionConnection",
      items:  Array< {
        __typename: "QuizQuestion",
        id: string,
        quizID: string,
        questionID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    quizQuestions?: Array< string | null > | null,
    prerequisite?: string | null,
    prerequisiteTitle?: string | null,
    prerequisiteSlug?: string | null,
    prerequisiteModule?: string | null,
    prerequisiteModuleTitle?: string | null,
    nextQuiz?: string | null,
    nextQuizTitle?: string | null,
    nextQuizSlug?: string | null,
    nextCourse?: string | null,
    nextCourseTitle?: string | null,
    nextCourseSlug?: string | null,
    hasFeedback?: boolean | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    moduleQuizzesId?: string | null,
    quizTypeId?: string | null,
    quizCreatedById?: string | null,
  } | null,
};

export type CreateQuizTypeMutationVariables = {
  input: CreateQuizTypeInput,
  condition?: ModelQuizTypeConditionInput | null,
};

export type CreateQuizTypeMutation = {
  createQuizType?:  {
    __typename: "QuizType",
    id: string,
    name: string,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    quizTypeCreatedById?: string | null,
  } | null,
};

export type UpdateQuizTypeMutationVariables = {
  input: UpdateQuizTypeInput,
  condition?: ModelQuizTypeConditionInput | null,
};

export type UpdateQuizTypeMutation = {
  updateQuizType?:  {
    __typename: "QuizType",
    id: string,
    name: string,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    quizTypeCreatedById?: string | null,
  } | null,
};

export type DeleteQuizTypeMutationVariables = {
  input: DeleteQuizTypeInput,
  condition?: ModelQuizTypeConditionInput | null,
};

export type DeleteQuizTypeMutation = {
  deleteQuizType?:  {
    __typename: "QuizType",
    id: string,
    name: string,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    quizTypeCreatedById?: string | null,
  } | null,
};

export type CreateQuizStatusMutationVariables = {
  input: CreateQuizStatusInput,
  condition?: ModelQuizStatusConditionInput | null,
};

export type CreateQuizStatusMutation = {
  createQuizStatus?:  {
    __typename: "QuizStatus",
    id: string,
    quiz:  {
      __typename: "Quiz",
      id: string,
      title: string,
      slug?: string | null,
      description?: string | null,
      type?:  {
        __typename: "QuizType",
        id: string,
        name: string,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        quizTypeCreatedById?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuizQuestionConnection",
        nextToken?: string | null,
      } | null,
      quizQuestions?: Array< string | null > | null,
      prerequisite?: string | null,
      prerequisiteTitle?: string | null,
      prerequisiteSlug?: string | null,
      prerequisiteModule?: string | null,
      prerequisiteModuleTitle?: string | null,
      nextQuiz?: string | null,
      nextQuizTitle?: string | null,
      nextQuizSlug?: string | null,
      nextCourse?: string | null,
      nextCourseTitle?: string | null,
      nextCourseSlug?: string | null,
      hasFeedback?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      moduleQuizzesId?: string | null,
      quizTypeId?: string | null,
      quizCreatedById?: string | null,
    },
    student:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    },
    isCompleted: boolean,
    totalScore: number,
    studentScore: number,
    deleted?: string | null,
    createdAt: string,
    updatedAt: string,
    quizStatusQuizId: string,
    quizStatusStudentId: string,
  } | null,
};

export type UpdateQuizStatusMutationVariables = {
  input: UpdateQuizStatusInput,
  condition?: ModelQuizStatusConditionInput | null,
};

export type UpdateQuizStatusMutation = {
  updateQuizStatus?:  {
    __typename: "QuizStatus",
    id: string,
    quiz:  {
      __typename: "Quiz",
      id: string,
      title: string,
      slug?: string | null,
      description?: string | null,
      type?:  {
        __typename: "QuizType",
        id: string,
        name: string,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        quizTypeCreatedById?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuizQuestionConnection",
        nextToken?: string | null,
      } | null,
      quizQuestions?: Array< string | null > | null,
      prerequisite?: string | null,
      prerequisiteTitle?: string | null,
      prerequisiteSlug?: string | null,
      prerequisiteModule?: string | null,
      prerequisiteModuleTitle?: string | null,
      nextQuiz?: string | null,
      nextQuizTitle?: string | null,
      nextQuizSlug?: string | null,
      nextCourse?: string | null,
      nextCourseTitle?: string | null,
      nextCourseSlug?: string | null,
      hasFeedback?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      moduleQuizzesId?: string | null,
      quizTypeId?: string | null,
      quizCreatedById?: string | null,
    },
    student:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    },
    isCompleted: boolean,
    totalScore: number,
    studentScore: number,
    deleted?: string | null,
    createdAt: string,
    updatedAt: string,
    quizStatusQuizId: string,
    quizStatusStudentId: string,
  } | null,
};

export type DeleteQuizStatusMutationVariables = {
  input: DeleteQuizStatusInput,
  condition?: ModelQuizStatusConditionInput | null,
};

export type DeleteQuizStatusMutation = {
  deleteQuizStatus?:  {
    __typename: "QuizStatus",
    id: string,
    quiz:  {
      __typename: "Quiz",
      id: string,
      title: string,
      slug?: string | null,
      description?: string | null,
      type?:  {
        __typename: "QuizType",
        id: string,
        name: string,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        quizTypeCreatedById?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuizQuestionConnection",
        nextToken?: string | null,
      } | null,
      quizQuestions?: Array< string | null > | null,
      prerequisite?: string | null,
      prerequisiteTitle?: string | null,
      prerequisiteSlug?: string | null,
      prerequisiteModule?: string | null,
      prerequisiteModuleTitle?: string | null,
      nextQuiz?: string | null,
      nextQuizTitle?: string | null,
      nextQuizSlug?: string | null,
      nextCourse?: string | null,
      nextCourseTitle?: string | null,
      nextCourseSlug?: string | null,
      hasFeedback?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      moduleQuizzesId?: string | null,
      quizTypeId?: string | null,
      quizCreatedById?: string | null,
    },
    student:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    },
    isCompleted: boolean,
    totalScore: number,
    studentScore: number,
    deleted?: string | null,
    createdAt: string,
    updatedAt: string,
    quizStatusQuizId: string,
    quizStatusStudentId: string,
  } | null,
};

export type CreateQuestionTypeMutationVariables = {
  input: CreateQuestionTypeInput,
  condition?: ModelQuestionTypeConditionInput | null,
};

export type CreateQuestionTypeMutation = {
  createQuestionType?:  {
    __typename: "QuestionType",
    id: string,
    name: string,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    questionTypeCreatedById?: string | null,
  } | null,
};

export type UpdateQuestionTypeMutationVariables = {
  input: UpdateQuestionTypeInput,
  condition?: ModelQuestionTypeConditionInput | null,
};

export type UpdateQuestionTypeMutation = {
  updateQuestionType?:  {
    __typename: "QuestionType",
    id: string,
    name: string,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    questionTypeCreatedById?: string | null,
  } | null,
};

export type DeleteQuestionTypeMutationVariables = {
  input: DeleteQuestionTypeInput,
  condition?: ModelQuestionTypeConditionInput | null,
};

export type DeleteQuestionTypeMutation = {
  deleteQuestionType?:  {
    __typename: "QuestionType",
    id: string,
    name: string,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    questionTypeCreatedById?: string | null,
  } | null,
};

export type CreateQuestionMutationVariables = {
  input: CreateQuestionInput,
  condition?: ModelQuestionConditionInput | null,
};

export type CreateQuestionMutation = {
  createQuestion?:  {
    __typename: "Question",
    id: string,
    title: string,
    type?:  {
      __typename: "QuestionType",
      id: string,
      name: string,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      questionTypeCreatedById?: string | null,
    } | null,
    correctAnswer?: string | null,
    options?: Array< string | null > | null,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    quizzes?:  {
      __typename: "ModelQuizQuestionConnection",
      items:  Array< {
        __typename: "QuizQuestion",
        id: string,
        quizID: string,
        questionID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    questionTypeId?: string | null,
    questionImageId?: string | null,
    questionCreatedById?: string | null,
  } | null,
};

export type UpdateQuestionMutationVariables = {
  input: UpdateQuestionInput,
  condition?: ModelQuestionConditionInput | null,
};

export type UpdateQuestionMutation = {
  updateQuestion?:  {
    __typename: "Question",
    id: string,
    title: string,
    type?:  {
      __typename: "QuestionType",
      id: string,
      name: string,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      questionTypeCreatedById?: string | null,
    } | null,
    correctAnswer?: string | null,
    options?: Array< string | null > | null,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    quizzes?:  {
      __typename: "ModelQuizQuestionConnection",
      items:  Array< {
        __typename: "QuizQuestion",
        id: string,
        quizID: string,
        questionID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    questionTypeId?: string | null,
    questionImageId?: string | null,
    questionCreatedById?: string | null,
  } | null,
};

export type DeleteQuestionMutationVariables = {
  input: DeleteQuestionInput,
  condition?: ModelQuestionConditionInput | null,
};

export type DeleteQuestionMutation = {
  deleteQuestion?:  {
    __typename: "Question",
    id: string,
    title: string,
    type?:  {
      __typename: "QuestionType",
      id: string,
      name: string,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      questionTypeCreatedById?: string | null,
    } | null,
    correctAnswer?: string | null,
    options?: Array< string | null > | null,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    quizzes?:  {
      __typename: "ModelQuizQuestionConnection",
      items:  Array< {
        __typename: "QuizQuestion",
        id: string,
        quizID: string,
        questionID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    questionTypeId?: string | null,
    questionImageId?: string | null,
    questionCreatedById?: string | null,
  } | null,
};

export type CreateAnswerMutationVariables = {
  input: CreateAnswerInput,
  condition?: ModelAnswerConditionInput | null,
};

export type CreateAnswerMutation = {
  createAnswer?:  {
    __typename: "Answer",
    id: string,
    question:  {
      __typename: "Question",
      id: string,
      title: string,
      type?:  {
        __typename: "QuestionType",
        id: string,
        name: string,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        questionTypeCreatedById?: string | null,
      } | null,
      correctAnswer?: string | null,
      options?: Array< string | null > | null,
      image?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      quizzes?:  {
        __typename: "ModelQuizQuestionConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      questionTypeId?: string | null,
      questionImageId?: string | null,
      questionCreatedById?: string | null,
    },
    student:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    },
    values: Array< string | null >,
    questionID?: string | null,
    studentID?: string | null,
    deleted?: string | null,
    isCorrect?: boolean | null,
    createdAt: string,
    updatedAt: string,
    answerQuestionId: string,
    answerStudentId: string,
  } | null,
};

export type UpdateAnswerMutationVariables = {
  input: UpdateAnswerInput,
  condition?: ModelAnswerConditionInput | null,
};

export type UpdateAnswerMutation = {
  updateAnswer?:  {
    __typename: "Answer",
    id: string,
    question:  {
      __typename: "Question",
      id: string,
      title: string,
      type?:  {
        __typename: "QuestionType",
        id: string,
        name: string,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        questionTypeCreatedById?: string | null,
      } | null,
      correctAnswer?: string | null,
      options?: Array< string | null > | null,
      image?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      quizzes?:  {
        __typename: "ModelQuizQuestionConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      questionTypeId?: string | null,
      questionImageId?: string | null,
      questionCreatedById?: string | null,
    },
    student:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    },
    values: Array< string | null >,
    questionID?: string | null,
    studentID?: string | null,
    deleted?: string | null,
    isCorrect?: boolean | null,
    createdAt: string,
    updatedAt: string,
    answerQuestionId: string,
    answerStudentId: string,
  } | null,
};

export type DeleteAnswerMutationVariables = {
  input: DeleteAnswerInput,
  condition?: ModelAnswerConditionInput | null,
};

export type DeleteAnswerMutation = {
  deleteAnswer?:  {
    __typename: "Answer",
    id: string,
    question:  {
      __typename: "Question",
      id: string,
      title: string,
      type?:  {
        __typename: "QuestionType",
        id: string,
        name: string,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        questionTypeCreatedById?: string | null,
      } | null,
      correctAnswer?: string | null,
      options?: Array< string | null > | null,
      image?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      quizzes?:  {
        __typename: "ModelQuizQuestionConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      questionTypeId?: string | null,
      questionImageId?: string | null,
      questionCreatedById?: string | null,
    },
    student:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    },
    values: Array< string | null >,
    questionID?: string | null,
    studentID?: string | null,
    deleted?: string | null,
    isCorrect?: boolean | null,
    createdAt: string,
    updatedAt: string,
    answerQuestionId: string,
    answerStudentId: string,
  } | null,
};

export type CreateCourseStudentMutationVariables = {
  input: CreateCourseStudentInput,
  condition?: ModelCourseStudentConditionInput | null,
};

export type CreateCourseStudentMutation = {
  createCourseStudent?:  {
    __typename: "CourseStudent",
    id: string,
    userID: string,
    courseID: string,
    user:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    },
    course:  {
      __typename: "Course",
      id: string,
      name: string,
      slug: string,
      description?: string | null,
      image?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelModuleConnection",
        nextToken?: string | null,
      } | null,
      students?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      prerequisiteQuiz?: string | null,
      prerequisiteQuizTitle?: string | null,
      prerequisiteQuizSlug?: string | null,
      prerequisiteModule?: string | null,
      prerequisiteModuleTitle?: string | null,
      prerequisiteModuleId?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      courseImageId?: string | null,
      courseCreatedById?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateCourseStudentMutationVariables = {
  input: UpdateCourseStudentInput,
  condition?: ModelCourseStudentConditionInput | null,
};

export type UpdateCourseStudentMutation = {
  updateCourseStudent?:  {
    __typename: "CourseStudent",
    id: string,
    userID: string,
    courseID: string,
    user:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    },
    course:  {
      __typename: "Course",
      id: string,
      name: string,
      slug: string,
      description?: string | null,
      image?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelModuleConnection",
        nextToken?: string | null,
      } | null,
      students?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      prerequisiteQuiz?: string | null,
      prerequisiteQuizTitle?: string | null,
      prerequisiteQuizSlug?: string | null,
      prerequisiteModule?: string | null,
      prerequisiteModuleTitle?: string | null,
      prerequisiteModuleId?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      courseImageId?: string | null,
      courseCreatedById?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteCourseStudentMutationVariables = {
  input: DeleteCourseStudentInput,
  condition?: ModelCourseStudentConditionInput | null,
};

export type DeleteCourseStudentMutation = {
  deleteCourseStudent?:  {
    __typename: "CourseStudent",
    id: string,
    userID: string,
    courseID: string,
    user:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    },
    course:  {
      __typename: "Course",
      id: string,
      name: string,
      slug: string,
      description?: string | null,
      image?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelModuleConnection",
        nextToken?: string | null,
      } | null,
      students?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      prerequisiteQuiz?: string | null,
      prerequisiteQuizTitle?: string | null,
      prerequisiteQuizSlug?: string | null,
      prerequisiteModule?: string | null,
      prerequisiteModuleTitle?: string | null,
      prerequisiteModuleId?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      courseImageId?: string | null,
      courseCreatedById?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateAccountLanguageMutationVariables = {
  input: CreateAccountLanguageInput,
  condition?: ModelAccountLanguageConditionInput | null,
};

export type CreateAccountLanguageMutation = {
  createAccountLanguage?:  {
    __typename: "AccountLanguage",
    id: string,
    accountID: string,
    languageID: string,
    account:  {
      __typename: "Account",
      id: string,
      logo?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      siteTitle: string,
      tagline: string,
      description: string,
      siteAddress: string,
      defaultLanguage: string,
      languages?:  {
        __typename: "ModelAccountLanguageConnection",
        nextToken?: string | null,
      } | null,
      status: string,
      socialLinks?:  {
        __typename: "ModelSocialLinkConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      accountLogoId?: string | null,
      accountCreatedById?: string | null,
    },
    language:  {
      __typename: "Language",
      id: string,
      name: string,
      code: string,
      accounts?:  {
        __typename: "ModelAccountLanguageConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      languageCreatedById?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateAccountLanguageMutationVariables = {
  input: UpdateAccountLanguageInput,
  condition?: ModelAccountLanguageConditionInput | null,
};

export type UpdateAccountLanguageMutation = {
  updateAccountLanguage?:  {
    __typename: "AccountLanguage",
    id: string,
    accountID: string,
    languageID: string,
    account:  {
      __typename: "Account",
      id: string,
      logo?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      siteTitle: string,
      tagline: string,
      description: string,
      siteAddress: string,
      defaultLanguage: string,
      languages?:  {
        __typename: "ModelAccountLanguageConnection",
        nextToken?: string | null,
      } | null,
      status: string,
      socialLinks?:  {
        __typename: "ModelSocialLinkConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      accountLogoId?: string | null,
      accountCreatedById?: string | null,
    },
    language:  {
      __typename: "Language",
      id: string,
      name: string,
      code: string,
      accounts?:  {
        __typename: "ModelAccountLanguageConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      languageCreatedById?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteAccountLanguageMutationVariables = {
  input: DeleteAccountLanguageInput,
  condition?: ModelAccountLanguageConditionInput | null,
};

export type DeleteAccountLanguageMutation = {
  deleteAccountLanguage?:  {
    __typename: "AccountLanguage",
    id: string,
    accountID: string,
    languageID: string,
    account:  {
      __typename: "Account",
      id: string,
      logo?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      siteTitle: string,
      tagline: string,
      description: string,
      siteAddress: string,
      defaultLanguage: string,
      languages?:  {
        __typename: "ModelAccountLanguageConnection",
        nextToken?: string | null,
      } | null,
      status: string,
      socialLinks?:  {
        __typename: "ModelSocialLinkConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      accountLogoId?: string | null,
      accountCreatedById?: string | null,
    },
    language:  {
      __typename: "Language",
      id: string,
      name: string,
      code: string,
      accounts?:  {
        __typename: "ModelAccountLanguageConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      languageCreatedById?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreatePostCategoryMutationVariables = {
  input: CreatePostCategoryInput,
  condition?: ModelPostCategoryConditionInput | null,
};

export type CreatePostCategoryMutation = {
  createPostCategory?:  {
    __typename: "PostCategory",
    id: string,
    categoryID: string,
    postID: string,
    category:  {
      __typename: "Category",
      id: string,
      title: string,
      slug: string,
      type: string,
      description?: string | null,
      thumbnail?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      visibility?: string | null,
      posts?:  {
        __typename: "ModelPostCategoryConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      categoryThumbnailId?: string | null,
      categoryCreatedById?: string | null,
    },
    post:  {
      __typename: "Post",
      id: string,
      title: string,
      slug: string,
      content?: string | null,
      excerpt?: string | null,
      status?: string | null,
      thumbnail?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      visibility?: string | null,
      categories?:  {
        __typename: "ModelPostCategoryConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      postThumbnailId?: string | null,
      postCreatedById?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdatePostCategoryMutationVariables = {
  input: UpdatePostCategoryInput,
  condition?: ModelPostCategoryConditionInput | null,
};

export type UpdatePostCategoryMutation = {
  updatePostCategory?:  {
    __typename: "PostCategory",
    id: string,
    categoryID: string,
    postID: string,
    category:  {
      __typename: "Category",
      id: string,
      title: string,
      slug: string,
      type: string,
      description?: string | null,
      thumbnail?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      visibility?: string | null,
      posts?:  {
        __typename: "ModelPostCategoryConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      categoryThumbnailId?: string | null,
      categoryCreatedById?: string | null,
    },
    post:  {
      __typename: "Post",
      id: string,
      title: string,
      slug: string,
      content?: string | null,
      excerpt?: string | null,
      status?: string | null,
      thumbnail?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      visibility?: string | null,
      categories?:  {
        __typename: "ModelPostCategoryConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      postThumbnailId?: string | null,
      postCreatedById?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeletePostCategoryMutationVariables = {
  input: DeletePostCategoryInput,
  condition?: ModelPostCategoryConditionInput | null,
};

export type DeletePostCategoryMutation = {
  deletePostCategory?:  {
    __typename: "PostCategory",
    id: string,
    categoryID: string,
    postID: string,
    category:  {
      __typename: "Category",
      id: string,
      title: string,
      slug: string,
      type: string,
      description?: string | null,
      thumbnail?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      visibility?: string | null,
      posts?:  {
        __typename: "ModelPostCategoryConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      categoryThumbnailId?: string | null,
      categoryCreatedById?: string | null,
    },
    post:  {
      __typename: "Post",
      id: string,
      title: string,
      slug: string,
      content?: string | null,
      excerpt?: string | null,
      status?: string | null,
      thumbnail?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      visibility?: string | null,
      categories?:  {
        __typename: "ModelPostCategoryConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      postThumbnailId?: string | null,
      postCreatedById?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateQuizQuestionMutationVariables = {
  input: CreateQuizQuestionInput,
  condition?: ModelQuizQuestionConditionInput | null,
};

export type CreateQuizQuestionMutation = {
  createQuizQuestion?:  {
    __typename: "QuizQuestion",
    id: string,
    quizID: string,
    questionID: string,
    quiz:  {
      __typename: "Quiz",
      id: string,
      title: string,
      slug?: string | null,
      description?: string | null,
      type?:  {
        __typename: "QuizType",
        id: string,
        name: string,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        quizTypeCreatedById?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuizQuestionConnection",
        nextToken?: string | null,
      } | null,
      quizQuestions?: Array< string | null > | null,
      prerequisite?: string | null,
      prerequisiteTitle?: string | null,
      prerequisiteSlug?: string | null,
      prerequisiteModule?: string | null,
      prerequisiteModuleTitle?: string | null,
      nextQuiz?: string | null,
      nextQuizTitle?: string | null,
      nextQuizSlug?: string | null,
      nextCourse?: string | null,
      nextCourseTitle?: string | null,
      nextCourseSlug?: string | null,
      hasFeedback?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      moduleQuizzesId?: string | null,
      quizTypeId?: string | null,
      quizCreatedById?: string | null,
    },
    question:  {
      __typename: "Question",
      id: string,
      title: string,
      type?:  {
        __typename: "QuestionType",
        id: string,
        name: string,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        questionTypeCreatedById?: string | null,
      } | null,
      correctAnswer?: string | null,
      options?: Array< string | null > | null,
      image?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      quizzes?:  {
        __typename: "ModelQuizQuestionConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      questionTypeId?: string | null,
      questionImageId?: string | null,
      questionCreatedById?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateQuizQuestionMutationVariables = {
  input: UpdateQuizQuestionInput,
  condition?: ModelQuizQuestionConditionInput | null,
};

export type UpdateQuizQuestionMutation = {
  updateQuizQuestion?:  {
    __typename: "QuizQuestion",
    id: string,
    quizID: string,
    questionID: string,
    quiz:  {
      __typename: "Quiz",
      id: string,
      title: string,
      slug?: string | null,
      description?: string | null,
      type?:  {
        __typename: "QuizType",
        id: string,
        name: string,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        quizTypeCreatedById?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuizQuestionConnection",
        nextToken?: string | null,
      } | null,
      quizQuestions?: Array< string | null > | null,
      prerequisite?: string | null,
      prerequisiteTitle?: string | null,
      prerequisiteSlug?: string | null,
      prerequisiteModule?: string | null,
      prerequisiteModuleTitle?: string | null,
      nextQuiz?: string | null,
      nextQuizTitle?: string | null,
      nextQuizSlug?: string | null,
      nextCourse?: string | null,
      nextCourseTitle?: string | null,
      nextCourseSlug?: string | null,
      hasFeedback?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      moduleQuizzesId?: string | null,
      quizTypeId?: string | null,
      quizCreatedById?: string | null,
    },
    question:  {
      __typename: "Question",
      id: string,
      title: string,
      type?:  {
        __typename: "QuestionType",
        id: string,
        name: string,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        questionTypeCreatedById?: string | null,
      } | null,
      correctAnswer?: string | null,
      options?: Array< string | null > | null,
      image?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      quizzes?:  {
        __typename: "ModelQuizQuestionConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      questionTypeId?: string | null,
      questionImageId?: string | null,
      questionCreatedById?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteQuizQuestionMutationVariables = {
  input: DeleteQuizQuestionInput,
  condition?: ModelQuizQuestionConditionInput | null,
};

export type DeleteQuizQuestionMutation = {
  deleteQuizQuestion?:  {
    __typename: "QuizQuestion",
    id: string,
    quizID: string,
    questionID: string,
    quiz:  {
      __typename: "Quiz",
      id: string,
      title: string,
      slug?: string | null,
      description?: string | null,
      type?:  {
        __typename: "QuizType",
        id: string,
        name: string,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        quizTypeCreatedById?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuizQuestionConnection",
        nextToken?: string | null,
      } | null,
      quizQuestions?: Array< string | null > | null,
      prerequisite?: string | null,
      prerequisiteTitle?: string | null,
      prerequisiteSlug?: string | null,
      prerequisiteModule?: string | null,
      prerequisiteModuleTitle?: string | null,
      nextQuiz?: string | null,
      nextQuizTitle?: string | null,
      nextQuizSlug?: string | null,
      nextCourse?: string | null,
      nextCourseTitle?: string | null,
      nextCourseSlug?: string | null,
      hasFeedback?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      moduleQuizzesId?: string | null,
      quizTypeId?: string | null,
      quizCreatedById?: string | null,
    },
    question:  {
      __typename: "Question",
      id: string,
      title: string,
      type?:  {
        __typename: "QuestionType",
        id: string,
        name: string,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        questionTypeCreatedById?: string | null,
      } | null,
      correctAnswer?: string | null,
      options?: Array< string | null > | null,
      image?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      quizzes?:  {
        __typename: "ModelQuizQuestionConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      questionTypeId?: string | null,
      questionImageId?: string | null,
      questionCreatedById?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type GetUserQueryVariables = {
  id: string,
};

export type GetUserQuery = {
  getUser?:  {
    __typename: "User",
    id: string,
    courses?:  {
      __typename: "ModelCourseStudentConnection",
      items:  Array< {
        __typename: "CourseStudent",
        id: string,
        userID: string,
        courseID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    username: string,
    preferred_username: string,
    email: string,
    email_verified?: boolean | null,
    phone_number?: string | null,
    phone_number_verified?: boolean | null,
    name?: string | null,
    nickname?: string | null,
    given_name?: string | null,
    middle_name?: string | null,
    family_name?: string | null,
    address?: string | null,
    birthdate?: string | null,
    gender?: string | null,
    locale?: string | null,
    picture?: string | null,
    website?: string | null,
    zoneinfo?: string | null,
    verification_code?: string | null,
    group?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?: string | null,
    updatedAt: string,
  } | null,
};

export type ListUsersQueryVariables = {
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUsersQuery = {
  listUsers?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ActiveUsersQueryVariables = {
  deleted: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ActiveUsersQuery = {
  activeUsers?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetEmailVerificationQueryVariables = {
  id: string,
};

export type GetEmailVerificationQuery = {
  getEmailVerification?:  {
    __typename: "EmailVerification",
    id: string,
    name: string,
    email: string,
    verification_code: string,
    createdAt: string,
    deleted?: string | null,
    updatedAt: string,
  } | null,
};

export type ListEmailVerificationsQueryVariables = {
  filter?: ModelEmailVerificationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEmailVerificationsQuery = {
  listEmailVerifications?:  {
    __typename: "ModelEmailVerificationConnection",
    items:  Array< {
      __typename: "EmailVerification",
      id: string,
      name: string,
      email: string,
      verification_code: string,
      createdAt: string,
      deleted?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ActiveVerificationsQueryVariables = {
  deleted: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEmailVerificationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ActiveVerificationsQuery = {
  activeVerifications?:  {
    __typename: "ModelEmailVerificationConnection",
    items:  Array< {
      __typename: "EmailVerification",
      id: string,
      name: string,
      email: string,
      verification_code: string,
      createdAt: string,
      deleted?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetAccountQueryVariables = {
  id: string,
};

export type GetAccountQuery = {
  getAccount?:  {
    __typename: "Account",
    id: string,
    logo?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    siteTitle: string,
    tagline: string,
    description: string,
    siteAddress: string,
    defaultLanguage: string,
    languages?:  {
      __typename: "ModelAccountLanguageConnection",
      items:  Array< {
        __typename: "AccountLanguage",
        id: string,
        accountID: string,
        languageID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    status: string,
    socialLinks?:  {
      __typename: "ModelSocialLinkConnection",
      items:  Array< {
        __typename: "SocialLink",
        id: string,
        name: string,
        icon: string,
        slug: string,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        accountSocialLinksId?: string | null,
        socialLinkCreatedById?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    accountLogoId?: string | null,
    accountCreatedById?: string | null,
  } | null,
};

export type ListAccountsQueryVariables = {
  filter?: ModelAccountFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAccountsQuery = {
  listAccounts?:  {
    __typename: "ModelAccountConnection",
    items:  Array< {
      __typename: "Account",
      id: string,
      logo?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      siteTitle: string,
      tagline: string,
      description: string,
      siteAddress: string,
      defaultLanguage: string,
      languages?:  {
        __typename: "ModelAccountLanguageConnection",
        nextToken?: string | null,
      } | null,
      status: string,
      socialLinks?:  {
        __typename: "ModelSocialLinkConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      accountLogoId?: string | null,
      accountCreatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ActiveAccountsQueryVariables = {
  deleted: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAccountFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ActiveAccountsQuery = {
  activeAccounts?:  {
    __typename: "ModelAccountConnection",
    items:  Array< {
      __typename: "Account",
      id: string,
      logo?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      siteTitle: string,
      tagline: string,
      description: string,
      siteAddress: string,
      defaultLanguage: string,
      languages?:  {
        __typename: "ModelAccountLanguageConnection",
        nextToken?: string | null,
      } | null,
      status: string,
      socialLinks?:  {
        __typename: "ModelSocialLinkConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      accountLogoId?: string | null,
      accountCreatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetSocialLinkQueryVariables = {
  id: string,
};

export type GetSocialLinkQuery = {
  getSocialLink?:  {
    __typename: "SocialLink",
    id: string,
    name: string,
    icon: string,
    slug: string,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    accountSocialLinksId?: string | null,
    socialLinkCreatedById?: string | null,
  } | null,
};

export type ListSocialLinksQueryVariables = {
  filter?: ModelSocialLinkFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSocialLinksQuery = {
  listSocialLinks?:  {
    __typename: "ModelSocialLinkConnection",
    items:  Array< {
      __typename: "SocialLink",
      id: string,
      name: string,
      icon: string,
      slug: string,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      accountSocialLinksId?: string | null,
      socialLinkCreatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ActiveLinksQueryVariables = {
  deleted: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSocialLinkFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ActiveLinksQuery = {
  activeLinks?:  {
    __typename: "ModelSocialLinkConnection",
    items:  Array< {
      __typename: "SocialLink",
      id: string,
      name: string,
      icon: string,
      slug: string,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      accountSocialLinksId?: string | null,
      socialLinkCreatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetFeatureQueryVariables = {
  id: string,
};

export type GetFeatureQuery = {
  getFeature?:  {
    __typename: "Feature",
    id: string,
    name: string,
    icon: string,
    slug?: string | null,
    isDashboard: string,
    precedence?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    featureCreatedById?: string | null,
  } | null,
};

export type ListFeaturesQueryVariables = {
  filter?: ModelFeatureFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFeaturesQuery = {
  listFeatures?:  {
    __typename: "ModelFeatureConnection",
    items:  Array< {
      __typename: "Feature",
      id: string,
      name: string,
      icon: string,
      slug?: string | null,
      isDashboard: string,
      precedence?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      featureCreatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ActiveFeaturesQueryVariables = {
  deleted: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFeatureFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ActiveFeaturesQuery = {
  activeFeatures?:  {
    __typename: "ModelFeatureConnection",
    items:  Array< {
      __typename: "Feature",
      id: string,
      name: string,
      icon: string,
      slug?: string | null,
      isDashboard: string,
      precedence?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      featureCreatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetLanguageQueryVariables = {
  id: string,
};

export type GetLanguageQuery = {
  getLanguage?:  {
    __typename: "Language",
    id: string,
    name: string,
    code: string,
    accounts?:  {
      __typename: "ModelAccountLanguageConnection",
      items:  Array< {
        __typename: "AccountLanguage",
        id: string,
        accountID: string,
        languageID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    languageCreatedById?: string | null,
  } | null,
};

export type ListLanguagesQueryVariables = {
  filter?: ModelLanguageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListLanguagesQuery = {
  listLanguages?:  {
    __typename: "ModelLanguageConnection",
    items:  Array< {
      __typename: "Language",
      id: string,
      name: string,
      code: string,
      accounts?:  {
        __typename: "ModelAccountLanguageConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      languageCreatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ActiveLanguagesQueryVariables = {
  deleted: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelLanguageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ActiveLanguagesQuery = {
  activeLanguages?:  {
    __typename: "ModelLanguageConnection",
    items:  Array< {
      __typename: "Language",
      id: string,
      name: string,
      code: string,
      accounts?:  {
        __typename: "ModelAccountLanguageConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      languageCreatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetMediaQueryVariables = {
  id: string,
};

export type GetMediaQuery = {
  getMedia?:  {
    __typename: "Media",
    id: string,
    fileurl: string,
    filename: string,
    filetype?: string | null,
    filesize?: number | null,
    alternativeText?: string | null,
    caption?: string | null,
    description?: string | null,
    attachments?:  {
      __typename: "ModelAttachmentConnection",
      items:  Array< {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    mediaCreatedById?: string | null,
  } | null,
};

export type ListMediaQueryVariables = {
  filter?: ModelMediaFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMediaQuery = {
  listMedia?:  {
    __typename: "ModelMediaConnection",
    items:  Array< {
      __typename: "Media",
      id: string,
      fileurl: string,
      filename: string,
      filetype?: string | null,
      filesize?: number | null,
      alternativeText?: string | null,
      caption?: string | null,
      description?: string | null,
      attachments?:  {
        __typename: "ModelAttachmentConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaCreatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ActiveMediaQueryVariables = {
  deleted: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMediaFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ActiveMediaQuery = {
  activeMedia?:  {
    __typename: "ModelMediaConnection",
    items:  Array< {
      __typename: "Media",
      id: string,
      fileurl: string,
      filename: string,
      filetype?: string | null,
      filesize?: number | null,
      alternativeText?: string | null,
      caption?: string | null,
      description?: string | null,
      attachments?:  {
        __typename: "ModelAttachmentConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaCreatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetAttachmentQueryVariables = {
  id: string,
};

export type GetAttachmentQuery = {
  getAttachment?:  {
    __typename: "Attachment",
    id: string,
    fileurl: string,
    alternativeText?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    mediaAttachmentsId?: string | null,
    attachmentCreatedById?: string | null,
  } | null,
};

export type ListAttachmentsQueryVariables = {
  filter?: ModelAttachmentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAttachmentsQuery = {
  listAttachments?:  {
    __typename: "ModelAttachmentConnection",
    items:  Array< {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetMenuQueryVariables = {
  id: string,
};

export type GetMenuQuery = {
  getMenu?:  {
    __typename: "Menu",
    id: string,
    name: string,
    locations: Array< string | null >,
    items?:  {
      __typename: "ModelMenuItemConnection",
      items:  Array< {
        __typename: "MenuItem",
        id: string,
        type: string,
        precedence?: string | null,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        menuItemsId?: string | null,
        menuItemPageId?: string | null,
        menuItemCreatedById?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    menuCreatedById?: string | null,
  } | null,
};

export type ListMenusQueryVariables = {
  filter?: ModelMenuFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMenusQuery = {
  listMenus?:  {
    __typename: "ModelMenuConnection",
    items:  Array< {
      __typename: "Menu",
      id: string,
      name: string,
      locations: Array< string | null >,
      items?:  {
        __typename: "ModelMenuItemConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      menuCreatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ActiveMenusQueryVariables = {
  deleted: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMenuFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ActiveMenusQuery = {
  activeMenus?:  {
    __typename: "ModelMenuConnection",
    items:  Array< {
      __typename: "Menu",
      id: string,
      name: string,
      locations: Array< string | null >,
      items?:  {
        __typename: "ModelMenuItemConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      menuCreatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetMenuItemQueryVariables = {
  id: string,
};

export type GetMenuItemQuery = {
  getMenuItem?:  {
    __typename: "MenuItem",
    id: string,
    type: string,
    page?:  {
      __typename: "Page",
      id: string,
      title: string,
      slug: string,
      status?: string | null,
      content?: string | null,
      excerpt?: string | null,
      parentId?: string | null,
      thumbnail?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      visibility?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      pageThumbnailId?: string | null,
      pageCreatedById?: string | null,
    } | null,
    precedence?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    menuItemsId?: string | null,
    menuItemPageId?: string | null,
    menuItemCreatedById?: string | null,
  } | null,
};

export type ListMenuItemsQueryVariables = {
  filter?: ModelMenuItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMenuItemsQuery = {
  listMenuItems?:  {
    __typename: "ModelMenuItemConnection",
    items:  Array< {
      __typename: "MenuItem",
      id: string,
      type: string,
      page?:  {
        __typename: "Page",
        id: string,
        title: string,
        slug: string,
        status?: string | null,
        content?: string | null,
        excerpt?: string | null,
        parentId?: string | null,
        visibility?: string | null,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        pageThumbnailId?: string | null,
        pageCreatedById?: string | null,
      } | null,
      precedence?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      menuItemsId?: string | null,
      menuItemPageId?: string | null,
      menuItemCreatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ActiveMenuItemsQueryVariables = {
  deleted: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMenuItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ActiveMenuItemsQuery = {
  activeMenuItems?:  {
    __typename: "ModelMenuItemConnection",
    items:  Array< {
      __typename: "MenuItem",
      id: string,
      type: string,
      page?:  {
        __typename: "Page",
        id: string,
        title: string,
        slug: string,
        status?: string | null,
        content?: string | null,
        excerpt?: string | null,
        parentId?: string | null,
        visibility?: string | null,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        pageThumbnailId?: string | null,
        pageCreatedById?: string | null,
      } | null,
      precedence?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      menuItemsId?: string | null,
      menuItemPageId?: string | null,
      menuItemCreatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPageQueryVariables = {
  id: string,
};

export type GetPageQuery = {
  getPage?:  {
    __typename: "Page",
    id: string,
    title: string,
    slug: string,
    status?: string | null,
    content?: string | null,
    excerpt?: string | null,
    parentId?: string | null,
    thumbnail?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    visibility?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    pageThumbnailId?: string | null,
    pageCreatedById?: string | null,
  } | null,
};

export type ListPagesQueryVariables = {
  filter?: ModelPageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPagesQuery = {
  listPages?:  {
    __typename: "ModelPageConnection",
    items:  Array< {
      __typename: "Page",
      id: string,
      title: string,
      slug: string,
      status?: string | null,
      content?: string | null,
      excerpt?: string | null,
      parentId?: string | null,
      thumbnail?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      visibility?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      pageThumbnailId?: string | null,
      pageCreatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ActivePagesQueryVariables = {
  deleted: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ActivePagesQuery = {
  activePages?:  {
    __typename: "ModelPageConnection",
    items:  Array< {
      __typename: "Page",
      id: string,
      title: string,
      slug: string,
      status?: string | null,
      content?: string | null,
      excerpt?: string | null,
      parentId?: string | null,
      thumbnail?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      visibility?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      pageThumbnailId?: string | null,
      pageCreatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCategoryQueryVariables = {
  id: string,
};

export type GetCategoryQuery = {
  getCategory?:  {
    __typename: "Category",
    id: string,
    title: string,
    slug: string,
    type: string,
    description?: string | null,
    thumbnail?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    visibility?: string | null,
    posts?:  {
      __typename: "ModelPostCategoryConnection",
      items:  Array< {
        __typename: "PostCategory",
        id: string,
        categoryID: string,
        postID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    categoryThumbnailId?: string | null,
    categoryCreatedById?: string | null,
  } | null,
};

export type ListCategoriesQueryVariables = {
  filter?: ModelCategoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCategoriesQuery = {
  listCategories?:  {
    __typename: "ModelCategoryConnection",
    items:  Array< {
      __typename: "Category",
      id: string,
      title: string,
      slug: string,
      type: string,
      description?: string | null,
      thumbnail?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      visibility?: string | null,
      posts?:  {
        __typename: "ModelPostCategoryConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      categoryThumbnailId?: string | null,
      categoryCreatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ActiveCategoriesQueryVariables = {
  deleted: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCategoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ActiveCategoriesQuery = {
  activeCategories?:  {
    __typename: "ModelCategoryConnection",
    items:  Array< {
      __typename: "Category",
      id: string,
      title: string,
      slug: string,
      type: string,
      description?: string | null,
      thumbnail?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      visibility?: string | null,
      posts?:  {
        __typename: "ModelPostCategoryConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      categoryThumbnailId?: string | null,
      categoryCreatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPostQueryVariables = {
  id: string,
};

export type GetPostQuery = {
  getPost?:  {
    __typename: "Post",
    id: string,
    title: string,
    slug: string,
    content?: string | null,
    excerpt?: string | null,
    status?: string | null,
    thumbnail?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    visibility?: string | null,
    categories?:  {
      __typename: "ModelPostCategoryConnection",
      items:  Array< {
        __typename: "PostCategory",
        id: string,
        categoryID: string,
        postID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    postThumbnailId?: string | null,
    postCreatedById?: string | null,
  } | null,
};

export type ListPostsQueryVariables = {
  filter?: ModelPostFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPostsQuery = {
  listPosts?:  {
    __typename: "ModelPostConnection",
    items:  Array< {
      __typename: "Post",
      id: string,
      title: string,
      slug: string,
      content?: string | null,
      excerpt?: string | null,
      status?: string | null,
      thumbnail?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      visibility?: string | null,
      categories?:  {
        __typename: "ModelPostCategoryConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      postThumbnailId?: string | null,
      postCreatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ActivePostsQueryVariables = {
  deleted: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPostFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ActivePostsQuery = {
  activePosts?:  {
    __typename: "ModelPostConnection",
    items:  Array< {
      __typename: "Post",
      id: string,
      title: string,
      slug: string,
      content?: string | null,
      excerpt?: string | null,
      status?: string | null,
      thumbnail?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      visibility?: string | null,
      categories?:  {
        __typename: "ModelPostCategoryConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      postThumbnailId?: string | null,
      postCreatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetMessageQueryVariables = {
  id: string,
};

export type GetMessageQuery = {
  getMessage?:  {
    __typename: "Message",
    id: string,
    name: string,
    email: string,
    subject?: string | null,
    message: string,
    deleted?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListMessagesQueryVariables = {
  filter?: ModelMessageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMessagesQuery = {
  listMessages?:  {
    __typename: "ModelMessageConnection",
    items:  Array< {
      __typename: "Message",
      id: string,
      name: string,
      email: string,
      subject?: string | null,
      message: string,
      deleted?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ActiveMessagesQueryVariables = {
  deleted: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMessageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ActiveMessagesQuery = {
  activeMessages?:  {
    __typename: "ModelMessageConnection",
    items:  Array< {
      __typename: "Message",
      id: string,
      name: string,
      email: string,
      subject?: string | null,
      message: string,
      deleted?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetSlideQueryVariables = {
  id: string,
};

export type GetSlideQuery = {
  getSlide?:  {
    __typename: "Slide",
    id: string,
    title: string,
    precedence: string,
    content?: string | null,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    mobImage?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    slideImageId?: string | null,
    slideMobImageId?: string | null,
    slideCreatedById?: string | null,
  } | null,
};

export type ListSlidesQueryVariables = {
  filter?: ModelSlideFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSlidesQuery = {
  listSlides?:  {
    __typename: "ModelSlideConnection",
    items:  Array< {
      __typename: "Slide",
      id: string,
      title: string,
      precedence: string,
      content?: string | null,
      image?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      mobImage?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      slideImageId?: string | null,
      slideMobImageId?: string | null,
      slideCreatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ActtiveSlidesQueryVariables = {
  deleted: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSlideFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ActtiveSlidesQuery = {
  acttiveSlides?:  {
    __typename: "ModelSlideConnection",
    items:  Array< {
      __typename: "Slide",
      id: string,
      title: string,
      precedence: string,
      content?: string | null,
      image?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      mobImage?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      slideImageId?: string | null,
      slideMobImageId?: string | null,
      slideCreatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCourseQueryVariables = {
  id: string,
};

export type GetCourseQuery = {
  getCourse?:  {
    __typename: "Course",
    id: string,
    name: string,
    slug: string,
    description?: string | null,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    modules?:  {
      __typename: "ModelModuleConnection",
      items:  Array< {
        __typename: "Module",
        id: string,
        name: string,
        parentId?: string | null,
        content: string,
        precedence?: string | null,
        prerequisite?: string | null,
        prerequisiteTitle?: string | null,
        afterModuleQuiz?: string | null,
        nextQuiz?: string | null,
        nextQuizTitle?: string | null,
        nextQuizSlug?: string | null,
        nextModule?: string | null,
        nextModuleTitle?: string | null,
        nextModuleId?: string | null,
        nextCourse?: string | null,
        nextCourseTitle?: string | null,
        nextCourseSlug?: string | null,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        courseModulesId?: string | null,
        moduleImageId?: string | null,
        moduleVrContentId?: string | null,
        moduleCreatedById?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    students?:  {
      __typename: "ModelCourseStudentConnection",
      items:  Array< {
        __typename: "CourseStudent",
        id: string,
        userID: string,
        courseID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    prerequisiteQuiz?: string | null,
    prerequisiteQuizTitle?: string | null,
    prerequisiteQuizSlug?: string | null,
    prerequisiteModule?: string | null,
    prerequisiteModuleTitle?: string | null,
    prerequisiteModuleId?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    courseImageId?: string | null,
    courseCreatedById?: string | null,
  } | null,
};

export type ListCoursesQueryVariables = {
  filter?: ModelCourseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCoursesQuery = {
  listCourses?:  {
    __typename: "ModelCourseConnection",
    items:  Array< {
      __typename: "Course",
      id: string,
      name: string,
      slug: string,
      description?: string | null,
      image?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelModuleConnection",
        nextToken?: string | null,
      } | null,
      students?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      prerequisiteQuiz?: string | null,
      prerequisiteQuizTitle?: string | null,
      prerequisiteQuizSlug?: string | null,
      prerequisiteModule?: string | null,
      prerequisiteModuleTitle?: string | null,
      prerequisiteModuleId?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      courseImageId?: string | null,
      courseCreatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ActiveCoursesQueryVariables = {
  deleted: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCourseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ActiveCoursesQuery = {
  activeCourses?:  {
    __typename: "ModelCourseConnection",
    items:  Array< {
      __typename: "Course",
      id: string,
      name: string,
      slug: string,
      description?: string | null,
      image?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelModuleConnection",
        nextToken?: string | null,
      } | null,
      students?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      prerequisiteQuiz?: string | null,
      prerequisiteQuizTitle?: string | null,
      prerequisiteQuizSlug?: string | null,
      prerequisiteModule?: string | null,
      prerequisiteModuleTitle?: string | null,
      prerequisiteModuleId?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      courseImageId?: string | null,
      courseCreatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetModuleQueryVariables = {
  id: string,
};

export type GetModuleQuery = {
  getModule?:  {
    __typename: "Module",
    id: string,
    name: string,
    parentId?: string | null,
    content: string,
    precedence?: string | null,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    quizzes?:  {
      __typename: "ModelQuizConnection",
      items:  Array< {
        __typename: "Quiz",
        id: string,
        title: string,
        slug?: string | null,
        description?: string | null,
        quizQuestions?: Array< string | null > | null,
        prerequisite?: string | null,
        prerequisiteTitle?: string | null,
        prerequisiteSlug?: string | null,
        prerequisiteModule?: string | null,
        prerequisiteModuleTitle?: string | null,
        nextQuiz?: string | null,
        nextQuizTitle?: string | null,
        nextQuizSlug?: string | null,
        nextCourse?: string | null,
        nextCourseTitle?: string | null,
        nextCourseSlug?: string | null,
        hasFeedback?: boolean | null,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        moduleQuizzesId?: string | null,
        quizTypeId?: string | null,
        quizCreatedById?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    prerequisite?: string | null,
    prerequisiteTitle?: string | null,
    afterModuleQuiz?: string | null,
    nextQuiz?: string | null,
    nextQuizTitle?: string | null,
    nextQuizSlug?: string | null,
    nextModule?: string | null,
    nextModuleTitle?: string | null,
    nextModuleId?: string | null,
    nextCourse?: string | null,
    nextCourseTitle?: string | null,
    nextCourseSlug?: string | null,
    vrContent?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    courseModulesId?: string | null,
    moduleImageId?: string | null,
    moduleVrContentId?: string | null,
    moduleCreatedById?: string | null,
  } | null,
};

export type ListModulesQueryVariables = {
  filter?: ModelModuleFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListModulesQuery = {
  listModules?:  {
    __typename: "ModelModuleConnection",
    items:  Array< {
      __typename: "Module",
      id: string,
      name: string,
      parentId?: string | null,
      content: string,
      precedence?: string | null,
      image?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      quizzes?:  {
        __typename: "ModelQuizConnection",
        nextToken?: string | null,
      } | null,
      prerequisite?: string | null,
      prerequisiteTitle?: string | null,
      afterModuleQuiz?: string | null,
      nextQuiz?: string | null,
      nextQuizTitle?: string | null,
      nextQuizSlug?: string | null,
      nextModule?: string | null,
      nextModuleTitle?: string | null,
      nextModuleId?: string | null,
      nextCourse?: string | null,
      nextCourseTitle?: string | null,
      nextCourseSlug?: string | null,
      vrContent?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      courseModulesId?: string | null,
      moduleImageId?: string | null,
      moduleVrContentId?: string | null,
      moduleCreatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ActiveModulesQueryVariables = {
  deleted: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelModuleFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ActiveModulesQuery = {
  activeModules?:  {
    __typename: "ModelModuleConnection",
    items:  Array< {
      __typename: "Module",
      id: string,
      name: string,
      parentId?: string | null,
      content: string,
      precedence?: string | null,
      image?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      quizzes?:  {
        __typename: "ModelQuizConnection",
        nextToken?: string | null,
      } | null,
      prerequisite?: string | null,
      prerequisiteTitle?: string | null,
      afterModuleQuiz?: string | null,
      nextQuiz?: string | null,
      nextQuizTitle?: string | null,
      nextQuizSlug?: string | null,
      nextModule?: string | null,
      nextModuleTitle?: string | null,
      nextModuleId?: string | null,
      nextCourse?: string | null,
      nextCourseTitle?: string | null,
      nextCourseSlug?: string | null,
      vrContent?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      courseModulesId?: string | null,
      moduleImageId?: string | null,
      moduleVrContentId?: string | null,
      moduleCreatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetModuleStatusQueryVariables = {
  id: string,
};

export type GetModuleStatusQuery = {
  getModuleStatus?:  {
    __typename: "ModuleStatus",
    id: string,
    module:  {
      __typename: "Module",
      id: string,
      name: string,
      parentId?: string | null,
      content: string,
      precedence?: string | null,
      image?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      quizzes?:  {
        __typename: "ModelQuizConnection",
        nextToken?: string | null,
      } | null,
      prerequisite?: string | null,
      prerequisiteTitle?: string | null,
      afterModuleQuiz?: string | null,
      nextQuiz?: string | null,
      nextQuizTitle?: string | null,
      nextQuizSlug?: string | null,
      nextModule?: string | null,
      nextModuleTitle?: string | null,
      nextModuleId?: string | null,
      nextCourse?: string | null,
      nextCourseTitle?: string | null,
      nextCourseSlug?: string | null,
      vrContent?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      courseModulesId?: string | null,
      moduleImageId?: string | null,
      moduleVrContentId?: string | null,
      moduleCreatedById?: string | null,
    },
    student:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    },
    deleted?: string | null,
    isCompleted?: boolean | null,
    createdAt: string,
    updatedAt: string,
    moduleStatusModuleId: string,
    moduleStatusStudentId: string,
  } | null,
};

export type ListModuleStatusesQueryVariables = {
  filter?: ModelModuleStatusFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListModuleStatusesQuery = {
  listModuleStatuses?:  {
    __typename: "ModelModuleStatusConnection",
    items:  Array< {
      __typename: "ModuleStatus",
      id: string,
      module:  {
        __typename: "Module",
        id: string,
        name: string,
        parentId?: string | null,
        content: string,
        precedence?: string | null,
        prerequisite?: string | null,
        prerequisiteTitle?: string | null,
        afterModuleQuiz?: string | null,
        nextQuiz?: string | null,
        nextQuizTitle?: string | null,
        nextQuizSlug?: string | null,
        nextModule?: string | null,
        nextModuleTitle?: string | null,
        nextModuleId?: string | null,
        nextCourse?: string | null,
        nextCourseTitle?: string | null,
        nextCourseSlug?: string | null,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        courseModulesId?: string | null,
        moduleImageId?: string | null,
        moduleVrContentId?: string | null,
        moduleCreatedById?: string | null,
      },
      student:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      },
      deleted?: string | null,
      isCompleted?: boolean | null,
      createdAt: string,
      updatedAt: string,
      moduleStatusModuleId: string,
      moduleStatusStudentId: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ActiveModuleStatusQueryVariables = {
  deleted: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelModuleStatusFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ActiveModuleStatusQuery = {
  activeModuleStatus?:  {
    __typename: "ModelModuleStatusConnection",
    items:  Array< {
      __typename: "ModuleStatus",
      id: string,
      module:  {
        __typename: "Module",
        id: string,
        name: string,
        parentId?: string | null,
        content: string,
        precedence?: string | null,
        prerequisite?: string | null,
        prerequisiteTitle?: string | null,
        afterModuleQuiz?: string | null,
        nextQuiz?: string | null,
        nextQuizTitle?: string | null,
        nextQuizSlug?: string | null,
        nextModule?: string | null,
        nextModuleTitle?: string | null,
        nextModuleId?: string | null,
        nextCourse?: string | null,
        nextCourseTitle?: string | null,
        nextCourseSlug?: string | null,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        courseModulesId?: string | null,
        moduleImageId?: string | null,
        moduleVrContentId?: string | null,
        moduleCreatedById?: string | null,
      },
      student:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      },
      deleted?: string | null,
      isCompleted?: boolean | null,
      createdAt: string,
      updatedAt: string,
      moduleStatusModuleId: string,
      moduleStatusStudentId: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetQuizQueryVariables = {
  id: string,
};

export type GetQuizQuery = {
  getQuiz?:  {
    __typename: "Quiz",
    id: string,
    title: string,
    slug?: string | null,
    description?: string | null,
    type?:  {
      __typename: "QuizType",
      id: string,
      name: string,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      quizTypeCreatedById?: string | null,
    } | null,
    questions?:  {
      __typename: "ModelQuizQuestionConnection",
      items:  Array< {
        __typename: "QuizQuestion",
        id: string,
        quizID: string,
        questionID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    quizQuestions?: Array< string | null > | null,
    prerequisite?: string | null,
    prerequisiteTitle?: string | null,
    prerequisiteSlug?: string | null,
    prerequisiteModule?: string | null,
    prerequisiteModuleTitle?: string | null,
    nextQuiz?: string | null,
    nextQuizTitle?: string | null,
    nextQuizSlug?: string | null,
    nextCourse?: string | null,
    nextCourseTitle?: string | null,
    nextCourseSlug?: string | null,
    hasFeedback?: boolean | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    moduleQuizzesId?: string | null,
    quizTypeId?: string | null,
    quizCreatedById?: string | null,
  } | null,
};

export type ListQuizzesQueryVariables = {
  filter?: ModelQuizFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListQuizzesQuery = {
  listQuizzes?:  {
    __typename: "ModelQuizConnection",
    items:  Array< {
      __typename: "Quiz",
      id: string,
      title: string,
      slug?: string | null,
      description?: string | null,
      type?:  {
        __typename: "QuizType",
        id: string,
        name: string,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        quizTypeCreatedById?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuizQuestionConnection",
        nextToken?: string | null,
      } | null,
      quizQuestions?: Array< string | null > | null,
      prerequisite?: string | null,
      prerequisiteTitle?: string | null,
      prerequisiteSlug?: string | null,
      prerequisiteModule?: string | null,
      prerequisiteModuleTitle?: string | null,
      nextQuiz?: string | null,
      nextQuizTitle?: string | null,
      nextQuizSlug?: string | null,
      nextCourse?: string | null,
      nextCourseTitle?: string | null,
      nextCourseSlug?: string | null,
      hasFeedback?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      moduleQuizzesId?: string | null,
      quizTypeId?: string | null,
      quizCreatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ActiveQuizzesQueryVariables = {
  deleted: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelQuizFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ActiveQuizzesQuery = {
  activeQuizzes?:  {
    __typename: "ModelQuizConnection",
    items:  Array< {
      __typename: "Quiz",
      id: string,
      title: string,
      slug?: string | null,
      description?: string | null,
      type?:  {
        __typename: "QuizType",
        id: string,
        name: string,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        quizTypeCreatedById?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuizQuestionConnection",
        nextToken?: string | null,
      } | null,
      quizQuestions?: Array< string | null > | null,
      prerequisite?: string | null,
      prerequisiteTitle?: string | null,
      prerequisiteSlug?: string | null,
      prerequisiteModule?: string | null,
      prerequisiteModuleTitle?: string | null,
      nextQuiz?: string | null,
      nextQuizTitle?: string | null,
      nextQuizSlug?: string | null,
      nextCourse?: string | null,
      nextCourseTitle?: string | null,
      nextCourseSlug?: string | null,
      hasFeedback?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      moduleQuizzesId?: string | null,
      quizTypeId?: string | null,
      quizCreatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetQuizTypeQueryVariables = {
  id: string,
};

export type GetQuizTypeQuery = {
  getQuizType?:  {
    __typename: "QuizType",
    id: string,
    name: string,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    quizTypeCreatedById?: string | null,
  } | null,
};

export type ListQuizTypesQueryVariables = {
  filter?: ModelQuizTypeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListQuizTypesQuery = {
  listQuizTypes?:  {
    __typename: "ModelQuizTypeConnection",
    items:  Array< {
      __typename: "QuizType",
      id: string,
      name: string,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      quizTypeCreatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ActiveQuizTypesQueryVariables = {
  deleted: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelQuizTypeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ActiveQuizTypesQuery = {
  activeQuizTypes?:  {
    __typename: "ModelQuizTypeConnection",
    items:  Array< {
      __typename: "QuizType",
      id: string,
      name: string,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      quizTypeCreatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetQuizStatusQueryVariables = {
  id: string,
};

export type GetQuizStatusQuery = {
  getQuizStatus?:  {
    __typename: "QuizStatus",
    id: string,
    quiz:  {
      __typename: "Quiz",
      id: string,
      title: string,
      slug?: string | null,
      description?: string | null,
      type?:  {
        __typename: "QuizType",
        id: string,
        name: string,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        quizTypeCreatedById?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuizQuestionConnection",
        nextToken?: string | null,
      } | null,
      quizQuestions?: Array< string | null > | null,
      prerequisite?: string | null,
      prerequisiteTitle?: string | null,
      prerequisiteSlug?: string | null,
      prerequisiteModule?: string | null,
      prerequisiteModuleTitle?: string | null,
      nextQuiz?: string | null,
      nextQuizTitle?: string | null,
      nextQuizSlug?: string | null,
      nextCourse?: string | null,
      nextCourseTitle?: string | null,
      nextCourseSlug?: string | null,
      hasFeedback?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      moduleQuizzesId?: string | null,
      quizTypeId?: string | null,
      quizCreatedById?: string | null,
    },
    student:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    },
    isCompleted: boolean,
    totalScore: number,
    studentScore: number,
    deleted?: string | null,
    createdAt: string,
    updatedAt: string,
    quizStatusQuizId: string,
    quizStatusStudentId: string,
  } | null,
};

export type ListQuizStatusesQueryVariables = {
  filter?: ModelQuizStatusFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListQuizStatusesQuery = {
  listQuizStatuses?:  {
    __typename: "ModelQuizStatusConnection",
    items:  Array< {
      __typename: "QuizStatus",
      id: string,
      quiz:  {
        __typename: "Quiz",
        id: string,
        title: string,
        slug?: string | null,
        description?: string | null,
        quizQuestions?: Array< string | null > | null,
        prerequisite?: string | null,
        prerequisiteTitle?: string | null,
        prerequisiteSlug?: string | null,
        prerequisiteModule?: string | null,
        prerequisiteModuleTitle?: string | null,
        nextQuiz?: string | null,
        nextQuizTitle?: string | null,
        nextQuizSlug?: string | null,
        nextCourse?: string | null,
        nextCourseTitle?: string | null,
        nextCourseSlug?: string | null,
        hasFeedback?: boolean | null,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        moduleQuizzesId?: string | null,
        quizTypeId?: string | null,
        quizCreatedById?: string | null,
      },
      student:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      },
      isCompleted: boolean,
      totalScore: number,
      studentScore: number,
      deleted?: string | null,
      createdAt: string,
      updatedAt: string,
      quizStatusQuizId: string,
      quizStatusStudentId: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ActiveQuizStatusQueryVariables = {
  deleted: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelQuizStatusFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ActiveQuizStatusQuery = {
  activeQuizStatus?:  {
    __typename: "ModelQuizStatusConnection",
    items:  Array< {
      __typename: "QuizStatus",
      id: string,
      quiz:  {
        __typename: "Quiz",
        id: string,
        title: string,
        slug?: string | null,
        description?: string | null,
        quizQuestions?: Array< string | null > | null,
        prerequisite?: string | null,
        prerequisiteTitle?: string | null,
        prerequisiteSlug?: string | null,
        prerequisiteModule?: string | null,
        prerequisiteModuleTitle?: string | null,
        nextQuiz?: string | null,
        nextQuizTitle?: string | null,
        nextQuizSlug?: string | null,
        nextCourse?: string | null,
        nextCourseTitle?: string | null,
        nextCourseSlug?: string | null,
        hasFeedback?: boolean | null,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        moduleQuizzesId?: string | null,
        quizTypeId?: string | null,
        quizCreatedById?: string | null,
      },
      student:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      },
      isCompleted: boolean,
      totalScore: number,
      studentScore: number,
      deleted?: string | null,
      createdAt: string,
      updatedAt: string,
      quizStatusQuizId: string,
      quizStatusStudentId: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetQuestionTypeQueryVariables = {
  id: string,
};

export type GetQuestionTypeQuery = {
  getQuestionType?:  {
    __typename: "QuestionType",
    id: string,
    name: string,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    questionTypeCreatedById?: string | null,
  } | null,
};

export type ListQuestionTypesQueryVariables = {
  filter?: ModelQuestionTypeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListQuestionTypesQuery = {
  listQuestionTypes?:  {
    __typename: "ModelQuestionTypeConnection",
    items:  Array< {
      __typename: "QuestionType",
      id: string,
      name: string,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      questionTypeCreatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ActiveQuestionTypesQueryVariables = {
  deleted: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelQuestionTypeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ActiveQuestionTypesQuery = {
  activeQuestionTypes?:  {
    __typename: "ModelQuestionTypeConnection",
    items:  Array< {
      __typename: "QuestionType",
      id: string,
      name: string,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      questionTypeCreatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetQuestionQueryVariables = {
  id: string,
};

export type GetQuestionQuery = {
  getQuestion?:  {
    __typename: "Question",
    id: string,
    title: string,
    type?:  {
      __typename: "QuestionType",
      id: string,
      name: string,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      questionTypeCreatedById?: string | null,
    } | null,
    correctAnswer?: string | null,
    options?: Array< string | null > | null,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    quizzes?:  {
      __typename: "ModelQuizQuestionConnection",
      items:  Array< {
        __typename: "QuizQuestion",
        id: string,
        quizID: string,
        questionID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    questionTypeId?: string | null,
    questionImageId?: string | null,
    questionCreatedById?: string | null,
  } | null,
};

export type ListQuestionsQueryVariables = {
  filter?: ModelQuestionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListQuestionsQuery = {
  listQuestions?:  {
    __typename: "ModelQuestionConnection",
    items:  Array< {
      __typename: "Question",
      id: string,
      title: string,
      type?:  {
        __typename: "QuestionType",
        id: string,
        name: string,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        questionTypeCreatedById?: string | null,
      } | null,
      correctAnswer?: string | null,
      options?: Array< string | null > | null,
      image?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      quizzes?:  {
        __typename: "ModelQuizQuestionConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      questionTypeId?: string | null,
      questionImageId?: string | null,
      questionCreatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ActiveQuestionsQueryVariables = {
  deleted: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelQuestionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ActiveQuestionsQuery = {
  activeQuestions?:  {
    __typename: "ModelQuestionConnection",
    items:  Array< {
      __typename: "Question",
      id: string,
      title: string,
      type?:  {
        __typename: "QuestionType",
        id: string,
        name: string,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        questionTypeCreatedById?: string | null,
      } | null,
      correctAnswer?: string | null,
      options?: Array< string | null > | null,
      image?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      quizzes?:  {
        __typename: "ModelQuizQuestionConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      questionTypeId?: string | null,
      questionImageId?: string | null,
      questionCreatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetAnswerQueryVariables = {
  id: string,
};

export type GetAnswerQuery = {
  getAnswer?:  {
    __typename: "Answer",
    id: string,
    question:  {
      __typename: "Question",
      id: string,
      title: string,
      type?:  {
        __typename: "QuestionType",
        id: string,
        name: string,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        questionTypeCreatedById?: string | null,
      } | null,
      correctAnswer?: string | null,
      options?: Array< string | null > | null,
      image?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      quizzes?:  {
        __typename: "ModelQuizQuestionConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      questionTypeId?: string | null,
      questionImageId?: string | null,
      questionCreatedById?: string | null,
    },
    student:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    },
    values: Array< string | null >,
    questionID?: string | null,
    studentID?: string | null,
    deleted?: string | null,
    isCorrect?: boolean | null,
    createdAt: string,
    updatedAt: string,
    answerQuestionId: string,
    answerStudentId: string,
  } | null,
};

export type ListAnswersQueryVariables = {
  filter?: ModelAnswerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAnswersQuery = {
  listAnswers?:  {
    __typename: "ModelAnswerConnection",
    items:  Array< {
      __typename: "Answer",
      id: string,
      question:  {
        __typename: "Question",
        id: string,
        title: string,
        correctAnswer?: string | null,
        options?: Array< string | null > | null,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        questionTypeId?: string | null,
        questionImageId?: string | null,
        questionCreatedById?: string | null,
      },
      student:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      },
      values: Array< string | null >,
      questionID?: string | null,
      studentID?: string | null,
      deleted?: string | null,
      isCorrect?: boolean | null,
      createdAt: string,
      updatedAt: string,
      answerQuestionId: string,
      answerStudentId: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type QuestionAnswerQueryVariables = {
  questionID: string,
  studentID?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAnswerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type QuestionAnswerQuery = {
  questionAnswer?:  {
    __typename: "ModelAnswerConnection",
    items:  Array< {
      __typename: "Answer",
      id: string,
      question:  {
        __typename: "Question",
        id: string,
        title: string,
        correctAnswer?: string | null,
        options?: Array< string | null > | null,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        questionTypeId?: string | null,
        questionImageId?: string | null,
        questionCreatedById?: string | null,
      },
      student:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      },
      values: Array< string | null >,
      questionID?: string | null,
      studentID?: string | null,
      deleted?: string | null,
      isCorrect?: boolean | null,
      createdAt: string,
      updatedAt: string,
      answerQuestionId: string,
      answerStudentId: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type StudentAnswerQueryVariables = {
  studentID: string,
  questionID?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAnswerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type StudentAnswerQuery = {
  studentAnswer?:  {
    __typename: "ModelAnswerConnection",
    items:  Array< {
      __typename: "Answer",
      id: string,
      question:  {
        __typename: "Question",
        id: string,
        title: string,
        correctAnswer?: string | null,
        options?: Array< string | null > | null,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        questionTypeId?: string | null,
        questionImageId?: string | null,
        questionCreatedById?: string | null,
      },
      student:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      },
      values: Array< string | null >,
      questionID?: string | null,
      studentID?: string | null,
      deleted?: string | null,
      isCorrect?: boolean | null,
      createdAt: string,
      updatedAt: string,
      answerQuestionId: string,
      answerStudentId: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ActiveAnswersQueryVariables = {
  deleted: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAnswerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ActiveAnswersQuery = {
  activeAnswers?:  {
    __typename: "ModelAnswerConnection",
    items:  Array< {
      __typename: "Answer",
      id: string,
      question:  {
        __typename: "Question",
        id: string,
        title: string,
        correctAnswer?: string | null,
        options?: Array< string | null > | null,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        questionTypeId?: string | null,
        questionImageId?: string | null,
        questionCreatedById?: string | null,
      },
      student:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      },
      values: Array< string | null >,
      questionID?: string | null,
      studentID?: string | null,
      deleted?: string | null,
      isCorrect?: boolean | null,
      createdAt: string,
      updatedAt: string,
      answerQuestionId: string,
      answerStudentId: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCourseStudentQueryVariables = {
  id: string,
};

export type GetCourseStudentQuery = {
  getCourseStudent?:  {
    __typename: "CourseStudent",
    id: string,
    userID: string,
    courseID: string,
    user:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    },
    course:  {
      __typename: "Course",
      id: string,
      name: string,
      slug: string,
      description?: string | null,
      image?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelModuleConnection",
        nextToken?: string | null,
      } | null,
      students?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      prerequisiteQuiz?: string | null,
      prerequisiteQuizTitle?: string | null,
      prerequisiteQuizSlug?: string | null,
      prerequisiteModule?: string | null,
      prerequisiteModuleTitle?: string | null,
      prerequisiteModuleId?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      courseImageId?: string | null,
      courseCreatedById?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListCourseStudentsQueryVariables = {
  filter?: ModelCourseStudentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCourseStudentsQuery = {
  listCourseStudents?:  {
    __typename: "ModelCourseStudentConnection",
    items:  Array< {
      __typename: "CourseStudent",
      id: string,
      userID: string,
      courseID: string,
      user:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      },
      course:  {
        __typename: "Course",
        id: string,
        name: string,
        slug: string,
        description?: string | null,
        prerequisiteQuiz?: string | null,
        prerequisiteQuizTitle?: string | null,
        prerequisiteQuizSlug?: string | null,
        prerequisiteModule?: string | null,
        prerequisiteModuleTitle?: string | null,
        prerequisiteModuleId?: string | null,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        courseImageId?: string | null,
        courseCreatedById?: string | null,
      },
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetAccountLanguageQueryVariables = {
  id: string,
};

export type GetAccountLanguageQuery = {
  getAccountLanguage?:  {
    __typename: "AccountLanguage",
    id: string,
    accountID: string,
    languageID: string,
    account:  {
      __typename: "Account",
      id: string,
      logo?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      siteTitle: string,
      tagline: string,
      description: string,
      siteAddress: string,
      defaultLanguage: string,
      languages?:  {
        __typename: "ModelAccountLanguageConnection",
        nextToken?: string | null,
      } | null,
      status: string,
      socialLinks?:  {
        __typename: "ModelSocialLinkConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      accountLogoId?: string | null,
      accountCreatedById?: string | null,
    },
    language:  {
      __typename: "Language",
      id: string,
      name: string,
      code: string,
      accounts?:  {
        __typename: "ModelAccountLanguageConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      languageCreatedById?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListAccountLanguagesQueryVariables = {
  filter?: ModelAccountLanguageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAccountLanguagesQuery = {
  listAccountLanguages?:  {
    __typename: "ModelAccountLanguageConnection",
    items:  Array< {
      __typename: "AccountLanguage",
      id: string,
      accountID: string,
      languageID: string,
      account:  {
        __typename: "Account",
        id: string,
        siteTitle: string,
        tagline: string,
        description: string,
        siteAddress: string,
        defaultLanguage: string,
        status: string,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        accountLogoId?: string | null,
        accountCreatedById?: string | null,
      },
      language:  {
        __typename: "Language",
        id: string,
        name: string,
        code: string,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        languageCreatedById?: string | null,
      },
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPostCategoryQueryVariables = {
  id: string,
};

export type GetPostCategoryQuery = {
  getPostCategory?:  {
    __typename: "PostCategory",
    id: string,
    categoryID: string,
    postID: string,
    category:  {
      __typename: "Category",
      id: string,
      title: string,
      slug: string,
      type: string,
      description?: string | null,
      thumbnail?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      visibility?: string | null,
      posts?:  {
        __typename: "ModelPostCategoryConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      categoryThumbnailId?: string | null,
      categoryCreatedById?: string | null,
    },
    post:  {
      __typename: "Post",
      id: string,
      title: string,
      slug: string,
      content?: string | null,
      excerpt?: string | null,
      status?: string | null,
      thumbnail?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      visibility?: string | null,
      categories?:  {
        __typename: "ModelPostCategoryConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      postThumbnailId?: string | null,
      postCreatedById?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListPostCategoriesQueryVariables = {
  filter?: ModelPostCategoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPostCategoriesQuery = {
  listPostCategories?:  {
    __typename: "ModelPostCategoryConnection",
    items:  Array< {
      __typename: "PostCategory",
      id: string,
      categoryID: string,
      postID: string,
      category:  {
        __typename: "Category",
        id: string,
        title: string,
        slug: string,
        type: string,
        description?: string | null,
        visibility?: string | null,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        categoryThumbnailId?: string | null,
        categoryCreatedById?: string | null,
      },
      post:  {
        __typename: "Post",
        id: string,
        title: string,
        slug: string,
        content?: string | null,
        excerpt?: string | null,
        status?: string | null,
        visibility?: string | null,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        postThumbnailId?: string | null,
        postCreatedById?: string | null,
      },
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetQuizQuestionQueryVariables = {
  id: string,
};

export type GetQuizQuestionQuery = {
  getQuizQuestion?:  {
    __typename: "QuizQuestion",
    id: string,
    quizID: string,
    questionID: string,
    quiz:  {
      __typename: "Quiz",
      id: string,
      title: string,
      slug?: string | null,
      description?: string | null,
      type?:  {
        __typename: "QuizType",
        id: string,
        name: string,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        quizTypeCreatedById?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuizQuestionConnection",
        nextToken?: string | null,
      } | null,
      quizQuestions?: Array< string | null > | null,
      prerequisite?: string | null,
      prerequisiteTitle?: string | null,
      prerequisiteSlug?: string | null,
      prerequisiteModule?: string | null,
      prerequisiteModuleTitle?: string | null,
      nextQuiz?: string | null,
      nextQuizTitle?: string | null,
      nextQuizSlug?: string | null,
      nextCourse?: string | null,
      nextCourseTitle?: string | null,
      nextCourseSlug?: string | null,
      hasFeedback?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      moduleQuizzesId?: string | null,
      quizTypeId?: string | null,
      quizCreatedById?: string | null,
    },
    question:  {
      __typename: "Question",
      id: string,
      title: string,
      type?:  {
        __typename: "QuestionType",
        id: string,
        name: string,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        questionTypeCreatedById?: string | null,
      } | null,
      correctAnswer?: string | null,
      options?: Array< string | null > | null,
      image?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      quizzes?:  {
        __typename: "ModelQuizQuestionConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      questionTypeId?: string | null,
      questionImageId?: string | null,
      questionCreatedById?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListQuizQuestionsQueryVariables = {
  filter?: ModelQuizQuestionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListQuizQuestionsQuery = {
  listQuizQuestions?:  {
    __typename: "ModelQuizQuestionConnection",
    items:  Array< {
      __typename: "QuizQuestion",
      id: string,
      quizID: string,
      questionID: string,
      quiz:  {
        __typename: "Quiz",
        id: string,
        title: string,
        slug?: string | null,
        description?: string | null,
        quizQuestions?: Array< string | null > | null,
        prerequisite?: string | null,
        prerequisiteTitle?: string | null,
        prerequisiteSlug?: string | null,
        prerequisiteModule?: string | null,
        prerequisiteModuleTitle?: string | null,
        nextQuiz?: string | null,
        nextQuizTitle?: string | null,
        nextQuizSlug?: string | null,
        nextCourse?: string | null,
        nextCourseTitle?: string | null,
        nextCourseSlug?: string | null,
        hasFeedback?: boolean | null,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        moduleQuizzesId?: string | null,
        quizTypeId?: string | null,
        quizCreatedById?: string | null,
      },
      question:  {
        __typename: "Question",
        id: string,
        title: string,
        correctAnswer?: string | null,
        options?: Array< string | null > | null,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        questionTypeId?: string | null,
        questionImageId?: string | null,
        questionCreatedById?: string | null,
      },
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OnCreateUserSubscription = {
  onCreateUser?:  {
    __typename: "User",
    id: string,
    courses?:  {
      __typename: "ModelCourseStudentConnection",
      items:  Array< {
        __typename: "CourseStudent",
        id: string,
        userID: string,
        courseID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    username: string,
    preferred_username: string,
    email: string,
    email_verified?: boolean | null,
    phone_number?: string | null,
    phone_number_verified?: boolean | null,
    name?: string | null,
    nickname?: string | null,
    given_name?: string | null,
    middle_name?: string | null,
    family_name?: string | null,
    address?: string | null,
    birthdate?: string | null,
    gender?: string | null,
    locale?: string | null,
    picture?: string | null,
    website?: string | null,
    zoneinfo?: string | null,
    verification_code?: string | null,
    group?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?: string | null,
    updatedAt: string,
  } | null,
};

export type OnUpdateUserSubscription = {
  onUpdateUser?:  {
    __typename: "User",
    id: string,
    courses?:  {
      __typename: "ModelCourseStudentConnection",
      items:  Array< {
        __typename: "CourseStudent",
        id: string,
        userID: string,
        courseID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    username: string,
    preferred_username: string,
    email: string,
    email_verified?: boolean | null,
    phone_number?: string | null,
    phone_number_verified?: boolean | null,
    name?: string | null,
    nickname?: string | null,
    given_name?: string | null,
    middle_name?: string | null,
    family_name?: string | null,
    address?: string | null,
    birthdate?: string | null,
    gender?: string | null,
    locale?: string | null,
    picture?: string | null,
    website?: string | null,
    zoneinfo?: string | null,
    verification_code?: string | null,
    group?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?: string | null,
    updatedAt: string,
  } | null,
};

export type OnDeleteUserSubscription = {
  onDeleteUser?:  {
    __typename: "User",
    id: string,
    courses?:  {
      __typename: "ModelCourseStudentConnection",
      items:  Array< {
        __typename: "CourseStudent",
        id: string,
        userID: string,
        courseID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    username: string,
    preferred_username: string,
    email: string,
    email_verified?: boolean | null,
    phone_number?: string | null,
    phone_number_verified?: boolean | null,
    name?: string | null,
    nickname?: string | null,
    given_name?: string | null,
    middle_name?: string | null,
    family_name?: string | null,
    address?: string | null,
    birthdate?: string | null,
    gender?: string | null,
    locale?: string | null,
    picture?: string | null,
    website?: string | null,
    zoneinfo?: string | null,
    verification_code?: string | null,
    group?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?: string | null,
    updatedAt: string,
  } | null,
};

export type OnCreateEmailVerificationSubscription = {
  onCreateEmailVerification?:  {
    __typename: "EmailVerification",
    id: string,
    name: string,
    email: string,
    verification_code: string,
    createdAt: string,
    deleted?: string | null,
    updatedAt: string,
  } | null,
};

export type OnUpdateEmailVerificationSubscription = {
  onUpdateEmailVerification?:  {
    __typename: "EmailVerification",
    id: string,
    name: string,
    email: string,
    verification_code: string,
    createdAt: string,
    deleted?: string | null,
    updatedAt: string,
  } | null,
};

export type OnDeleteEmailVerificationSubscription = {
  onDeleteEmailVerification?:  {
    __typename: "EmailVerification",
    id: string,
    name: string,
    email: string,
    verification_code: string,
    createdAt: string,
    deleted?: string | null,
    updatedAt: string,
  } | null,
};

export type OnCreateAccountSubscription = {
  onCreateAccount?:  {
    __typename: "Account",
    id: string,
    logo?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    siteTitle: string,
    tagline: string,
    description: string,
    siteAddress: string,
    defaultLanguage: string,
    languages?:  {
      __typename: "ModelAccountLanguageConnection",
      items:  Array< {
        __typename: "AccountLanguage",
        id: string,
        accountID: string,
        languageID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    status: string,
    socialLinks?:  {
      __typename: "ModelSocialLinkConnection",
      items:  Array< {
        __typename: "SocialLink",
        id: string,
        name: string,
        icon: string,
        slug: string,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        accountSocialLinksId?: string | null,
        socialLinkCreatedById?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    accountLogoId?: string | null,
    accountCreatedById?: string | null,
  } | null,
};

export type OnUpdateAccountSubscription = {
  onUpdateAccount?:  {
    __typename: "Account",
    id: string,
    logo?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    siteTitle: string,
    tagline: string,
    description: string,
    siteAddress: string,
    defaultLanguage: string,
    languages?:  {
      __typename: "ModelAccountLanguageConnection",
      items:  Array< {
        __typename: "AccountLanguage",
        id: string,
        accountID: string,
        languageID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    status: string,
    socialLinks?:  {
      __typename: "ModelSocialLinkConnection",
      items:  Array< {
        __typename: "SocialLink",
        id: string,
        name: string,
        icon: string,
        slug: string,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        accountSocialLinksId?: string | null,
        socialLinkCreatedById?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    accountLogoId?: string | null,
    accountCreatedById?: string | null,
  } | null,
};

export type OnDeleteAccountSubscription = {
  onDeleteAccount?:  {
    __typename: "Account",
    id: string,
    logo?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    siteTitle: string,
    tagline: string,
    description: string,
    siteAddress: string,
    defaultLanguage: string,
    languages?:  {
      __typename: "ModelAccountLanguageConnection",
      items:  Array< {
        __typename: "AccountLanguage",
        id: string,
        accountID: string,
        languageID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    status: string,
    socialLinks?:  {
      __typename: "ModelSocialLinkConnection",
      items:  Array< {
        __typename: "SocialLink",
        id: string,
        name: string,
        icon: string,
        slug: string,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        accountSocialLinksId?: string | null,
        socialLinkCreatedById?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    accountLogoId?: string | null,
    accountCreatedById?: string | null,
  } | null,
};

export type OnCreateSocialLinkSubscription = {
  onCreateSocialLink?:  {
    __typename: "SocialLink",
    id: string,
    name: string,
    icon: string,
    slug: string,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    accountSocialLinksId?: string | null,
    socialLinkCreatedById?: string | null,
  } | null,
};

export type OnUpdateSocialLinkSubscription = {
  onUpdateSocialLink?:  {
    __typename: "SocialLink",
    id: string,
    name: string,
    icon: string,
    slug: string,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    accountSocialLinksId?: string | null,
    socialLinkCreatedById?: string | null,
  } | null,
};

export type OnDeleteSocialLinkSubscription = {
  onDeleteSocialLink?:  {
    __typename: "SocialLink",
    id: string,
    name: string,
    icon: string,
    slug: string,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    accountSocialLinksId?: string | null,
    socialLinkCreatedById?: string | null,
  } | null,
};

export type OnCreateFeatureSubscription = {
  onCreateFeature?:  {
    __typename: "Feature",
    id: string,
    name: string,
    icon: string,
    slug?: string | null,
    isDashboard: string,
    precedence?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    featureCreatedById?: string | null,
  } | null,
};

export type OnUpdateFeatureSubscription = {
  onUpdateFeature?:  {
    __typename: "Feature",
    id: string,
    name: string,
    icon: string,
    slug?: string | null,
    isDashboard: string,
    precedence?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    featureCreatedById?: string | null,
  } | null,
};

export type OnDeleteFeatureSubscription = {
  onDeleteFeature?:  {
    __typename: "Feature",
    id: string,
    name: string,
    icon: string,
    slug?: string | null,
    isDashboard: string,
    precedence?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    featureCreatedById?: string | null,
  } | null,
};

export type OnCreateLanguageSubscription = {
  onCreateLanguage?:  {
    __typename: "Language",
    id: string,
    name: string,
    code: string,
    accounts?:  {
      __typename: "ModelAccountLanguageConnection",
      items:  Array< {
        __typename: "AccountLanguage",
        id: string,
        accountID: string,
        languageID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    languageCreatedById?: string | null,
  } | null,
};

export type OnUpdateLanguageSubscription = {
  onUpdateLanguage?:  {
    __typename: "Language",
    id: string,
    name: string,
    code: string,
    accounts?:  {
      __typename: "ModelAccountLanguageConnection",
      items:  Array< {
        __typename: "AccountLanguage",
        id: string,
        accountID: string,
        languageID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    languageCreatedById?: string | null,
  } | null,
};

export type OnDeleteLanguageSubscription = {
  onDeleteLanguage?:  {
    __typename: "Language",
    id: string,
    name: string,
    code: string,
    accounts?:  {
      __typename: "ModelAccountLanguageConnection",
      items:  Array< {
        __typename: "AccountLanguage",
        id: string,
        accountID: string,
        languageID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    languageCreatedById?: string | null,
  } | null,
};

export type OnCreateMediaSubscription = {
  onCreateMedia?:  {
    __typename: "Media",
    id: string,
    fileurl: string,
    filename: string,
    filetype?: string | null,
    filesize?: number | null,
    alternativeText?: string | null,
    caption?: string | null,
    description?: string | null,
    attachments?:  {
      __typename: "ModelAttachmentConnection",
      items:  Array< {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    mediaCreatedById?: string | null,
  } | null,
};

export type OnUpdateMediaSubscription = {
  onUpdateMedia?:  {
    __typename: "Media",
    id: string,
    fileurl: string,
    filename: string,
    filetype?: string | null,
    filesize?: number | null,
    alternativeText?: string | null,
    caption?: string | null,
    description?: string | null,
    attachments?:  {
      __typename: "ModelAttachmentConnection",
      items:  Array< {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    mediaCreatedById?: string | null,
  } | null,
};

export type OnDeleteMediaSubscription = {
  onDeleteMedia?:  {
    __typename: "Media",
    id: string,
    fileurl: string,
    filename: string,
    filetype?: string | null,
    filesize?: number | null,
    alternativeText?: string | null,
    caption?: string | null,
    description?: string | null,
    attachments?:  {
      __typename: "ModelAttachmentConnection",
      items:  Array< {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    mediaCreatedById?: string | null,
  } | null,
};

export type OnCreateAttachmentSubscription = {
  onCreateAttachment?:  {
    __typename: "Attachment",
    id: string,
    fileurl: string,
    alternativeText?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    mediaAttachmentsId?: string | null,
    attachmentCreatedById?: string | null,
  } | null,
};

export type OnUpdateAttachmentSubscription = {
  onUpdateAttachment?:  {
    __typename: "Attachment",
    id: string,
    fileurl: string,
    alternativeText?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    mediaAttachmentsId?: string | null,
    attachmentCreatedById?: string | null,
  } | null,
};

export type OnDeleteAttachmentSubscription = {
  onDeleteAttachment?:  {
    __typename: "Attachment",
    id: string,
    fileurl: string,
    alternativeText?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    mediaAttachmentsId?: string | null,
    attachmentCreatedById?: string | null,
  } | null,
};

export type OnCreateMenuSubscription = {
  onCreateMenu?:  {
    __typename: "Menu",
    id: string,
    name: string,
    locations: Array< string | null >,
    items?:  {
      __typename: "ModelMenuItemConnection",
      items:  Array< {
        __typename: "MenuItem",
        id: string,
        type: string,
        precedence?: string | null,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        menuItemsId?: string | null,
        menuItemPageId?: string | null,
        menuItemCreatedById?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    menuCreatedById?: string | null,
  } | null,
};

export type OnUpdateMenuSubscription = {
  onUpdateMenu?:  {
    __typename: "Menu",
    id: string,
    name: string,
    locations: Array< string | null >,
    items?:  {
      __typename: "ModelMenuItemConnection",
      items:  Array< {
        __typename: "MenuItem",
        id: string,
        type: string,
        precedence?: string | null,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        menuItemsId?: string | null,
        menuItemPageId?: string | null,
        menuItemCreatedById?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    menuCreatedById?: string | null,
  } | null,
};

export type OnDeleteMenuSubscription = {
  onDeleteMenu?:  {
    __typename: "Menu",
    id: string,
    name: string,
    locations: Array< string | null >,
    items?:  {
      __typename: "ModelMenuItemConnection",
      items:  Array< {
        __typename: "MenuItem",
        id: string,
        type: string,
        precedence?: string | null,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        menuItemsId?: string | null,
        menuItemPageId?: string | null,
        menuItemCreatedById?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    menuCreatedById?: string | null,
  } | null,
};

export type OnCreateMenuItemSubscription = {
  onCreateMenuItem?:  {
    __typename: "MenuItem",
    id: string,
    type: string,
    page?:  {
      __typename: "Page",
      id: string,
      title: string,
      slug: string,
      status?: string | null,
      content?: string | null,
      excerpt?: string | null,
      parentId?: string | null,
      thumbnail?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      visibility?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      pageThumbnailId?: string | null,
      pageCreatedById?: string | null,
    } | null,
    precedence?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    menuItemsId?: string | null,
    menuItemPageId?: string | null,
    menuItemCreatedById?: string | null,
  } | null,
};

export type OnUpdateMenuItemSubscription = {
  onUpdateMenuItem?:  {
    __typename: "MenuItem",
    id: string,
    type: string,
    page?:  {
      __typename: "Page",
      id: string,
      title: string,
      slug: string,
      status?: string | null,
      content?: string | null,
      excerpt?: string | null,
      parentId?: string | null,
      thumbnail?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      visibility?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      pageThumbnailId?: string | null,
      pageCreatedById?: string | null,
    } | null,
    precedence?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    menuItemsId?: string | null,
    menuItemPageId?: string | null,
    menuItemCreatedById?: string | null,
  } | null,
};

export type OnDeleteMenuItemSubscription = {
  onDeleteMenuItem?:  {
    __typename: "MenuItem",
    id: string,
    type: string,
    page?:  {
      __typename: "Page",
      id: string,
      title: string,
      slug: string,
      status?: string | null,
      content?: string | null,
      excerpt?: string | null,
      parentId?: string | null,
      thumbnail?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      visibility?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      pageThumbnailId?: string | null,
      pageCreatedById?: string | null,
    } | null,
    precedence?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    menuItemsId?: string | null,
    menuItemPageId?: string | null,
    menuItemCreatedById?: string | null,
  } | null,
};

export type OnCreatePageSubscription = {
  onCreatePage?:  {
    __typename: "Page",
    id: string,
    title: string,
    slug: string,
    status?: string | null,
    content?: string | null,
    excerpt?: string | null,
    parentId?: string | null,
    thumbnail?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    visibility?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    pageThumbnailId?: string | null,
    pageCreatedById?: string | null,
  } | null,
};

export type OnUpdatePageSubscription = {
  onUpdatePage?:  {
    __typename: "Page",
    id: string,
    title: string,
    slug: string,
    status?: string | null,
    content?: string | null,
    excerpt?: string | null,
    parentId?: string | null,
    thumbnail?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    visibility?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    pageThumbnailId?: string | null,
    pageCreatedById?: string | null,
  } | null,
};

export type OnDeletePageSubscription = {
  onDeletePage?:  {
    __typename: "Page",
    id: string,
    title: string,
    slug: string,
    status?: string | null,
    content?: string | null,
    excerpt?: string | null,
    parentId?: string | null,
    thumbnail?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    visibility?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    pageThumbnailId?: string | null,
    pageCreatedById?: string | null,
  } | null,
};

export type OnCreateCategorySubscription = {
  onCreateCategory?:  {
    __typename: "Category",
    id: string,
    title: string,
    slug: string,
    type: string,
    description?: string | null,
    thumbnail?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    visibility?: string | null,
    posts?:  {
      __typename: "ModelPostCategoryConnection",
      items:  Array< {
        __typename: "PostCategory",
        id: string,
        categoryID: string,
        postID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    categoryThumbnailId?: string | null,
    categoryCreatedById?: string | null,
  } | null,
};

export type OnUpdateCategorySubscription = {
  onUpdateCategory?:  {
    __typename: "Category",
    id: string,
    title: string,
    slug: string,
    type: string,
    description?: string | null,
    thumbnail?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    visibility?: string | null,
    posts?:  {
      __typename: "ModelPostCategoryConnection",
      items:  Array< {
        __typename: "PostCategory",
        id: string,
        categoryID: string,
        postID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    categoryThumbnailId?: string | null,
    categoryCreatedById?: string | null,
  } | null,
};

export type OnDeleteCategorySubscription = {
  onDeleteCategory?:  {
    __typename: "Category",
    id: string,
    title: string,
    slug: string,
    type: string,
    description?: string | null,
    thumbnail?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    visibility?: string | null,
    posts?:  {
      __typename: "ModelPostCategoryConnection",
      items:  Array< {
        __typename: "PostCategory",
        id: string,
        categoryID: string,
        postID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    categoryThumbnailId?: string | null,
    categoryCreatedById?: string | null,
  } | null,
};

export type OnCreatePostSubscription = {
  onCreatePost?:  {
    __typename: "Post",
    id: string,
    title: string,
    slug: string,
    content?: string | null,
    excerpt?: string | null,
    status?: string | null,
    thumbnail?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    visibility?: string | null,
    categories?:  {
      __typename: "ModelPostCategoryConnection",
      items:  Array< {
        __typename: "PostCategory",
        id: string,
        categoryID: string,
        postID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    postThumbnailId?: string | null,
    postCreatedById?: string | null,
  } | null,
};

export type OnUpdatePostSubscription = {
  onUpdatePost?:  {
    __typename: "Post",
    id: string,
    title: string,
    slug: string,
    content?: string | null,
    excerpt?: string | null,
    status?: string | null,
    thumbnail?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    visibility?: string | null,
    categories?:  {
      __typename: "ModelPostCategoryConnection",
      items:  Array< {
        __typename: "PostCategory",
        id: string,
        categoryID: string,
        postID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    postThumbnailId?: string | null,
    postCreatedById?: string | null,
  } | null,
};

export type OnDeletePostSubscription = {
  onDeletePost?:  {
    __typename: "Post",
    id: string,
    title: string,
    slug: string,
    content?: string | null,
    excerpt?: string | null,
    status?: string | null,
    thumbnail?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    visibility?: string | null,
    categories?:  {
      __typename: "ModelPostCategoryConnection",
      items:  Array< {
        __typename: "PostCategory",
        id: string,
        categoryID: string,
        postID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    postThumbnailId?: string | null,
    postCreatedById?: string | null,
  } | null,
};

export type OnCreateMessageSubscription = {
  onCreateMessage?:  {
    __typename: "Message",
    id: string,
    name: string,
    email: string,
    subject?: string | null,
    message: string,
    deleted?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateMessageSubscription = {
  onUpdateMessage?:  {
    __typename: "Message",
    id: string,
    name: string,
    email: string,
    subject?: string | null,
    message: string,
    deleted?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteMessageSubscription = {
  onDeleteMessage?:  {
    __typename: "Message",
    id: string,
    name: string,
    email: string,
    subject?: string | null,
    message: string,
    deleted?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateSlideSubscription = {
  onCreateSlide?:  {
    __typename: "Slide",
    id: string,
    title: string,
    precedence: string,
    content?: string | null,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    mobImage?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    slideImageId?: string | null,
    slideMobImageId?: string | null,
    slideCreatedById?: string | null,
  } | null,
};

export type OnUpdateSlideSubscription = {
  onUpdateSlide?:  {
    __typename: "Slide",
    id: string,
    title: string,
    precedence: string,
    content?: string | null,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    mobImage?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    slideImageId?: string | null,
    slideMobImageId?: string | null,
    slideCreatedById?: string | null,
  } | null,
};

export type OnDeleteSlideSubscription = {
  onDeleteSlide?:  {
    __typename: "Slide",
    id: string,
    title: string,
    precedence: string,
    content?: string | null,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    mobImage?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    slideImageId?: string | null,
    slideMobImageId?: string | null,
    slideCreatedById?: string | null,
  } | null,
};

export type OnCreateCourseSubscription = {
  onCreateCourse?:  {
    __typename: "Course",
    id: string,
    name: string,
    slug: string,
    description?: string | null,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    modules?:  {
      __typename: "ModelModuleConnection",
      items:  Array< {
        __typename: "Module",
        id: string,
        name: string,
        parentId?: string | null,
        content: string,
        precedence?: string | null,
        prerequisite?: string | null,
        prerequisiteTitle?: string | null,
        afterModuleQuiz?: string | null,
        nextQuiz?: string | null,
        nextQuizTitle?: string | null,
        nextQuizSlug?: string | null,
        nextModule?: string | null,
        nextModuleTitle?: string | null,
        nextModuleId?: string | null,
        nextCourse?: string | null,
        nextCourseTitle?: string | null,
        nextCourseSlug?: string | null,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        courseModulesId?: string | null,
        moduleImageId?: string | null,
        moduleVrContentId?: string | null,
        moduleCreatedById?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    students?:  {
      __typename: "ModelCourseStudentConnection",
      items:  Array< {
        __typename: "CourseStudent",
        id: string,
        userID: string,
        courseID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    prerequisiteQuiz?: string | null,
    prerequisiteQuizTitle?: string | null,
    prerequisiteQuizSlug?: string | null,
    prerequisiteModule?: string | null,
    prerequisiteModuleTitle?: string | null,
    prerequisiteModuleId?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    courseImageId?: string | null,
    courseCreatedById?: string | null,
  } | null,
};

export type OnUpdateCourseSubscription = {
  onUpdateCourse?:  {
    __typename: "Course",
    id: string,
    name: string,
    slug: string,
    description?: string | null,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    modules?:  {
      __typename: "ModelModuleConnection",
      items:  Array< {
        __typename: "Module",
        id: string,
        name: string,
        parentId?: string | null,
        content: string,
        precedence?: string | null,
        prerequisite?: string | null,
        prerequisiteTitle?: string | null,
        afterModuleQuiz?: string | null,
        nextQuiz?: string | null,
        nextQuizTitle?: string | null,
        nextQuizSlug?: string | null,
        nextModule?: string | null,
        nextModuleTitle?: string | null,
        nextModuleId?: string | null,
        nextCourse?: string | null,
        nextCourseTitle?: string | null,
        nextCourseSlug?: string | null,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        courseModulesId?: string | null,
        moduleImageId?: string | null,
        moduleVrContentId?: string | null,
        moduleCreatedById?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    students?:  {
      __typename: "ModelCourseStudentConnection",
      items:  Array< {
        __typename: "CourseStudent",
        id: string,
        userID: string,
        courseID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    prerequisiteQuiz?: string | null,
    prerequisiteQuizTitle?: string | null,
    prerequisiteQuizSlug?: string | null,
    prerequisiteModule?: string | null,
    prerequisiteModuleTitle?: string | null,
    prerequisiteModuleId?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    courseImageId?: string | null,
    courseCreatedById?: string | null,
  } | null,
};

export type OnDeleteCourseSubscription = {
  onDeleteCourse?:  {
    __typename: "Course",
    id: string,
    name: string,
    slug: string,
    description?: string | null,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    modules?:  {
      __typename: "ModelModuleConnection",
      items:  Array< {
        __typename: "Module",
        id: string,
        name: string,
        parentId?: string | null,
        content: string,
        precedence?: string | null,
        prerequisite?: string | null,
        prerequisiteTitle?: string | null,
        afterModuleQuiz?: string | null,
        nextQuiz?: string | null,
        nextQuizTitle?: string | null,
        nextQuizSlug?: string | null,
        nextModule?: string | null,
        nextModuleTitle?: string | null,
        nextModuleId?: string | null,
        nextCourse?: string | null,
        nextCourseTitle?: string | null,
        nextCourseSlug?: string | null,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        courseModulesId?: string | null,
        moduleImageId?: string | null,
        moduleVrContentId?: string | null,
        moduleCreatedById?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    students?:  {
      __typename: "ModelCourseStudentConnection",
      items:  Array< {
        __typename: "CourseStudent",
        id: string,
        userID: string,
        courseID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    prerequisiteQuiz?: string | null,
    prerequisiteQuizTitle?: string | null,
    prerequisiteQuizSlug?: string | null,
    prerequisiteModule?: string | null,
    prerequisiteModuleTitle?: string | null,
    prerequisiteModuleId?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    courseImageId?: string | null,
    courseCreatedById?: string | null,
  } | null,
};

export type OnCreateModuleSubscription = {
  onCreateModule?:  {
    __typename: "Module",
    id: string,
    name: string,
    parentId?: string | null,
    content: string,
    precedence?: string | null,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    quizzes?:  {
      __typename: "ModelQuizConnection",
      items:  Array< {
        __typename: "Quiz",
        id: string,
        title: string,
        slug?: string | null,
        description?: string | null,
        quizQuestions?: Array< string | null > | null,
        prerequisite?: string | null,
        prerequisiteTitle?: string | null,
        prerequisiteSlug?: string | null,
        prerequisiteModule?: string | null,
        prerequisiteModuleTitle?: string | null,
        nextQuiz?: string | null,
        nextQuizTitle?: string | null,
        nextQuizSlug?: string | null,
        nextCourse?: string | null,
        nextCourseTitle?: string | null,
        nextCourseSlug?: string | null,
        hasFeedback?: boolean | null,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        moduleQuizzesId?: string | null,
        quizTypeId?: string | null,
        quizCreatedById?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    prerequisite?: string | null,
    prerequisiteTitle?: string | null,
    afterModuleQuiz?: string | null,
    nextQuiz?: string | null,
    nextQuizTitle?: string | null,
    nextQuizSlug?: string | null,
    nextModule?: string | null,
    nextModuleTitle?: string | null,
    nextModuleId?: string | null,
    nextCourse?: string | null,
    nextCourseTitle?: string | null,
    nextCourseSlug?: string | null,
    vrContent?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    courseModulesId?: string | null,
    moduleImageId?: string | null,
    moduleVrContentId?: string | null,
    moduleCreatedById?: string | null,
  } | null,
};

export type OnUpdateModuleSubscription = {
  onUpdateModule?:  {
    __typename: "Module",
    id: string,
    name: string,
    parentId?: string | null,
    content: string,
    precedence?: string | null,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    quizzes?:  {
      __typename: "ModelQuizConnection",
      items:  Array< {
        __typename: "Quiz",
        id: string,
        title: string,
        slug?: string | null,
        description?: string | null,
        quizQuestions?: Array< string | null > | null,
        prerequisite?: string | null,
        prerequisiteTitle?: string | null,
        prerequisiteSlug?: string | null,
        prerequisiteModule?: string | null,
        prerequisiteModuleTitle?: string | null,
        nextQuiz?: string | null,
        nextQuizTitle?: string | null,
        nextQuizSlug?: string | null,
        nextCourse?: string | null,
        nextCourseTitle?: string | null,
        nextCourseSlug?: string | null,
        hasFeedback?: boolean | null,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        moduleQuizzesId?: string | null,
        quizTypeId?: string | null,
        quizCreatedById?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    prerequisite?: string | null,
    prerequisiteTitle?: string | null,
    afterModuleQuiz?: string | null,
    nextQuiz?: string | null,
    nextQuizTitle?: string | null,
    nextQuizSlug?: string | null,
    nextModule?: string | null,
    nextModuleTitle?: string | null,
    nextModuleId?: string | null,
    nextCourse?: string | null,
    nextCourseTitle?: string | null,
    nextCourseSlug?: string | null,
    vrContent?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    courseModulesId?: string | null,
    moduleImageId?: string | null,
    moduleVrContentId?: string | null,
    moduleCreatedById?: string | null,
  } | null,
};

export type OnDeleteModuleSubscription = {
  onDeleteModule?:  {
    __typename: "Module",
    id: string,
    name: string,
    parentId?: string | null,
    content: string,
    precedence?: string | null,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    quizzes?:  {
      __typename: "ModelQuizConnection",
      items:  Array< {
        __typename: "Quiz",
        id: string,
        title: string,
        slug?: string | null,
        description?: string | null,
        quizQuestions?: Array< string | null > | null,
        prerequisite?: string | null,
        prerequisiteTitle?: string | null,
        prerequisiteSlug?: string | null,
        prerequisiteModule?: string | null,
        prerequisiteModuleTitle?: string | null,
        nextQuiz?: string | null,
        nextQuizTitle?: string | null,
        nextQuizSlug?: string | null,
        nextCourse?: string | null,
        nextCourseTitle?: string | null,
        nextCourseSlug?: string | null,
        hasFeedback?: boolean | null,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        moduleQuizzesId?: string | null,
        quizTypeId?: string | null,
        quizCreatedById?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    prerequisite?: string | null,
    prerequisiteTitle?: string | null,
    afterModuleQuiz?: string | null,
    nextQuiz?: string | null,
    nextQuizTitle?: string | null,
    nextQuizSlug?: string | null,
    nextModule?: string | null,
    nextModuleTitle?: string | null,
    nextModuleId?: string | null,
    nextCourse?: string | null,
    nextCourseTitle?: string | null,
    nextCourseSlug?: string | null,
    vrContent?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    courseModulesId?: string | null,
    moduleImageId?: string | null,
    moduleVrContentId?: string | null,
    moduleCreatedById?: string | null,
  } | null,
};

export type OnCreateModuleStatusSubscription = {
  onCreateModuleStatus?:  {
    __typename: "ModuleStatus",
    id: string,
    module:  {
      __typename: "Module",
      id: string,
      name: string,
      parentId?: string | null,
      content: string,
      precedence?: string | null,
      image?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      quizzes?:  {
        __typename: "ModelQuizConnection",
        nextToken?: string | null,
      } | null,
      prerequisite?: string | null,
      prerequisiteTitle?: string | null,
      afterModuleQuiz?: string | null,
      nextQuiz?: string | null,
      nextQuizTitle?: string | null,
      nextQuizSlug?: string | null,
      nextModule?: string | null,
      nextModuleTitle?: string | null,
      nextModuleId?: string | null,
      nextCourse?: string | null,
      nextCourseTitle?: string | null,
      nextCourseSlug?: string | null,
      vrContent?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      courseModulesId?: string | null,
      moduleImageId?: string | null,
      moduleVrContentId?: string | null,
      moduleCreatedById?: string | null,
    },
    student:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    },
    deleted?: string | null,
    isCompleted?: boolean | null,
    createdAt: string,
    updatedAt: string,
    moduleStatusModuleId: string,
    moduleStatusStudentId: string,
  } | null,
};

export type OnUpdateModuleStatusSubscription = {
  onUpdateModuleStatus?:  {
    __typename: "ModuleStatus",
    id: string,
    module:  {
      __typename: "Module",
      id: string,
      name: string,
      parentId?: string | null,
      content: string,
      precedence?: string | null,
      image?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      quizzes?:  {
        __typename: "ModelQuizConnection",
        nextToken?: string | null,
      } | null,
      prerequisite?: string | null,
      prerequisiteTitle?: string | null,
      afterModuleQuiz?: string | null,
      nextQuiz?: string | null,
      nextQuizTitle?: string | null,
      nextQuizSlug?: string | null,
      nextModule?: string | null,
      nextModuleTitle?: string | null,
      nextModuleId?: string | null,
      nextCourse?: string | null,
      nextCourseTitle?: string | null,
      nextCourseSlug?: string | null,
      vrContent?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      courseModulesId?: string | null,
      moduleImageId?: string | null,
      moduleVrContentId?: string | null,
      moduleCreatedById?: string | null,
    },
    student:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    },
    deleted?: string | null,
    isCompleted?: boolean | null,
    createdAt: string,
    updatedAt: string,
    moduleStatusModuleId: string,
    moduleStatusStudentId: string,
  } | null,
};

export type OnDeleteModuleStatusSubscription = {
  onDeleteModuleStatus?:  {
    __typename: "ModuleStatus",
    id: string,
    module:  {
      __typename: "Module",
      id: string,
      name: string,
      parentId?: string | null,
      content: string,
      precedence?: string | null,
      image?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      quizzes?:  {
        __typename: "ModelQuizConnection",
        nextToken?: string | null,
      } | null,
      prerequisite?: string | null,
      prerequisiteTitle?: string | null,
      afterModuleQuiz?: string | null,
      nextQuiz?: string | null,
      nextQuizTitle?: string | null,
      nextQuizSlug?: string | null,
      nextModule?: string | null,
      nextModuleTitle?: string | null,
      nextModuleId?: string | null,
      nextCourse?: string | null,
      nextCourseTitle?: string | null,
      nextCourseSlug?: string | null,
      vrContent?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      courseModulesId?: string | null,
      moduleImageId?: string | null,
      moduleVrContentId?: string | null,
      moduleCreatedById?: string | null,
    },
    student:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    },
    deleted?: string | null,
    isCompleted?: boolean | null,
    createdAt: string,
    updatedAt: string,
    moduleStatusModuleId: string,
    moduleStatusStudentId: string,
  } | null,
};

export type OnCreateQuizSubscription = {
  onCreateQuiz?:  {
    __typename: "Quiz",
    id: string,
    title: string,
    slug?: string | null,
    description?: string | null,
    type?:  {
      __typename: "QuizType",
      id: string,
      name: string,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      quizTypeCreatedById?: string | null,
    } | null,
    questions?:  {
      __typename: "ModelQuizQuestionConnection",
      items:  Array< {
        __typename: "QuizQuestion",
        id: string,
        quizID: string,
        questionID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    quizQuestions?: Array< string | null > | null,
    prerequisite?: string | null,
    prerequisiteTitle?: string | null,
    prerequisiteSlug?: string | null,
    prerequisiteModule?: string | null,
    prerequisiteModuleTitle?: string | null,
    nextQuiz?: string | null,
    nextQuizTitle?: string | null,
    nextQuizSlug?: string | null,
    nextCourse?: string | null,
    nextCourseTitle?: string | null,
    nextCourseSlug?: string | null,
    hasFeedback?: boolean | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    moduleQuizzesId?: string | null,
    quizTypeId?: string | null,
    quizCreatedById?: string | null,
  } | null,
};

export type OnUpdateQuizSubscription = {
  onUpdateQuiz?:  {
    __typename: "Quiz",
    id: string,
    title: string,
    slug?: string | null,
    description?: string | null,
    type?:  {
      __typename: "QuizType",
      id: string,
      name: string,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      quizTypeCreatedById?: string | null,
    } | null,
    questions?:  {
      __typename: "ModelQuizQuestionConnection",
      items:  Array< {
        __typename: "QuizQuestion",
        id: string,
        quizID: string,
        questionID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    quizQuestions?: Array< string | null > | null,
    prerequisite?: string | null,
    prerequisiteTitle?: string | null,
    prerequisiteSlug?: string | null,
    prerequisiteModule?: string | null,
    prerequisiteModuleTitle?: string | null,
    nextQuiz?: string | null,
    nextQuizTitle?: string | null,
    nextQuizSlug?: string | null,
    nextCourse?: string | null,
    nextCourseTitle?: string | null,
    nextCourseSlug?: string | null,
    hasFeedback?: boolean | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    moduleQuizzesId?: string | null,
    quizTypeId?: string | null,
    quizCreatedById?: string | null,
  } | null,
};

export type OnDeleteQuizSubscription = {
  onDeleteQuiz?:  {
    __typename: "Quiz",
    id: string,
    title: string,
    slug?: string | null,
    description?: string | null,
    type?:  {
      __typename: "QuizType",
      id: string,
      name: string,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      quizTypeCreatedById?: string | null,
    } | null,
    questions?:  {
      __typename: "ModelQuizQuestionConnection",
      items:  Array< {
        __typename: "QuizQuestion",
        id: string,
        quizID: string,
        questionID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    quizQuestions?: Array< string | null > | null,
    prerequisite?: string | null,
    prerequisiteTitle?: string | null,
    prerequisiteSlug?: string | null,
    prerequisiteModule?: string | null,
    prerequisiteModuleTitle?: string | null,
    nextQuiz?: string | null,
    nextQuizTitle?: string | null,
    nextQuizSlug?: string | null,
    nextCourse?: string | null,
    nextCourseTitle?: string | null,
    nextCourseSlug?: string | null,
    hasFeedback?: boolean | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    moduleQuizzesId?: string | null,
    quizTypeId?: string | null,
    quizCreatedById?: string | null,
  } | null,
};

export type OnCreateQuizTypeSubscription = {
  onCreateQuizType?:  {
    __typename: "QuizType",
    id: string,
    name: string,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    quizTypeCreatedById?: string | null,
  } | null,
};

export type OnUpdateQuizTypeSubscription = {
  onUpdateQuizType?:  {
    __typename: "QuizType",
    id: string,
    name: string,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    quizTypeCreatedById?: string | null,
  } | null,
};

export type OnDeleteQuizTypeSubscription = {
  onDeleteQuizType?:  {
    __typename: "QuizType",
    id: string,
    name: string,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    quizTypeCreatedById?: string | null,
  } | null,
};

export type OnCreateQuizStatusSubscription = {
  onCreateQuizStatus?:  {
    __typename: "QuizStatus",
    id: string,
    quiz:  {
      __typename: "Quiz",
      id: string,
      title: string,
      slug?: string | null,
      description?: string | null,
      type?:  {
        __typename: "QuizType",
        id: string,
        name: string,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        quizTypeCreatedById?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuizQuestionConnection",
        nextToken?: string | null,
      } | null,
      quizQuestions?: Array< string | null > | null,
      prerequisite?: string | null,
      prerequisiteTitle?: string | null,
      prerequisiteSlug?: string | null,
      prerequisiteModule?: string | null,
      prerequisiteModuleTitle?: string | null,
      nextQuiz?: string | null,
      nextQuizTitle?: string | null,
      nextQuizSlug?: string | null,
      nextCourse?: string | null,
      nextCourseTitle?: string | null,
      nextCourseSlug?: string | null,
      hasFeedback?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      moduleQuizzesId?: string | null,
      quizTypeId?: string | null,
      quizCreatedById?: string | null,
    },
    student:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    },
    isCompleted: boolean,
    totalScore: number,
    studentScore: number,
    deleted?: string | null,
    createdAt: string,
    updatedAt: string,
    quizStatusQuizId: string,
    quizStatusStudentId: string,
  } | null,
};

export type OnUpdateQuizStatusSubscription = {
  onUpdateQuizStatus?:  {
    __typename: "QuizStatus",
    id: string,
    quiz:  {
      __typename: "Quiz",
      id: string,
      title: string,
      slug?: string | null,
      description?: string | null,
      type?:  {
        __typename: "QuizType",
        id: string,
        name: string,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        quizTypeCreatedById?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuizQuestionConnection",
        nextToken?: string | null,
      } | null,
      quizQuestions?: Array< string | null > | null,
      prerequisite?: string | null,
      prerequisiteTitle?: string | null,
      prerequisiteSlug?: string | null,
      prerequisiteModule?: string | null,
      prerequisiteModuleTitle?: string | null,
      nextQuiz?: string | null,
      nextQuizTitle?: string | null,
      nextQuizSlug?: string | null,
      nextCourse?: string | null,
      nextCourseTitle?: string | null,
      nextCourseSlug?: string | null,
      hasFeedback?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      moduleQuizzesId?: string | null,
      quizTypeId?: string | null,
      quizCreatedById?: string | null,
    },
    student:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    },
    isCompleted: boolean,
    totalScore: number,
    studentScore: number,
    deleted?: string | null,
    createdAt: string,
    updatedAt: string,
    quizStatusQuizId: string,
    quizStatusStudentId: string,
  } | null,
};

export type OnDeleteQuizStatusSubscription = {
  onDeleteQuizStatus?:  {
    __typename: "QuizStatus",
    id: string,
    quiz:  {
      __typename: "Quiz",
      id: string,
      title: string,
      slug?: string | null,
      description?: string | null,
      type?:  {
        __typename: "QuizType",
        id: string,
        name: string,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        quizTypeCreatedById?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuizQuestionConnection",
        nextToken?: string | null,
      } | null,
      quizQuestions?: Array< string | null > | null,
      prerequisite?: string | null,
      prerequisiteTitle?: string | null,
      prerequisiteSlug?: string | null,
      prerequisiteModule?: string | null,
      prerequisiteModuleTitle?: string | null,
      nextQuiz?: string | null,
      nextQuizTitle?: string | null,
      nextQuizSlug?: string | null,
      nextCourse?: string | null,
      nextCourseTitle?: string | null,
      nextCourseSlug?: string | null,
      hasFeedback?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      moduleQuizzesId?: string | null,
      quizTypeId?: string | null,
      quizCreatedById?: string | null,
    },
    student:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    },
    isCompleted: boolean,
    totalScore: number,
    studentScore: number,
    deleted?: string | null,
    createdAt: string,
    updatedAt: string,
    quizStatusQuizId: string,
    quizStatusStudentId: string,
  } | null,
};

export type OnCreateQuestionTypeSubscription = {
  onCreateQuestionType?:  {
    __typename: "QuestionType",
    id: string,
    name: string,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    questionTypeCreatedById?: string | null,
  } | null,
};

export type OnUpdateQuestionTypeSubscription = {
  onUpdateQuestionType?:  {
    __typename: "QuestionType",
    id: string,
    name: string,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    questionTypeCreatedById?: string | null,
  } | null,
};

export type OnDeleteQuestionTypeSubscription = {
  onDeleteQuestionType?:  {
    __typename: "QuestionType",
    id: string,
    name: string,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    questionTypeCreatedById?: string | null,
  } | null,
};

export type OnCreateQuestionSubscription = {
  onCreateQuestion?:  {
    __typename: "Question",
    id: string,
    title: string,
    type?:  {
      __typename: "QuestionType",
      id: string,
      name: string,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      questionTypeCreatedById?: string | null,
    } | null,
    correctAnswer?: string | null,
    options?: Array< string | null > | null,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    quizzes?:  {
      __typename: "ModelQuizQuestionConnection",
      items:  Array< {
        __typename: "QuizQuestion",
        id: string,
        quizID: string,
        questionID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    questionTypeId?: string | null,
    questionImageId?: string | null,
    questionCreatedById?: string | null,
  } | null,
};

export type OnUpdateQuestionSubscription = {
  onUpdateQuestion?:  {
    __typename: "Question",
    id: string,
    title: string,
    type?:  {
      __typename: "QuestionType",
      id: string,
      name: string,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      questionTypeCreatedById?: string | null,
    } | null,
    correctAnswer?: string | null,
    options?: Array< string | null > | null,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    quizzes?:  {
      __typename: "ModelQuizQuestionConnection",
      items:  Array< {
        __typename: "QuizQuestion",
        id: string,
        quizID: string,
        questionID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    questionTypeId?: string | null,
    questionImageId?: string | null,
    questionCreatedById?: string | null,
  } | null,
};

export type OnDeleteQuestionSubscription = {
  onDeleteQuestion?:  {
    __typename: "Question",
    id: string,
    title: string,
    type?:  {
      __typename: "QuestionType",
      id: string,
      name: string,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      questionTypeCreatedById?: string | null,
    } | null,
    correctAnswer?: string | null,
    options?: Array< string | null > | null,
    image?:  {
      __typename: "Attachment",
      id: string,
      fileurl: string,
      alternativeText?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      mediaAttachmentsId?: string | null,
      attachmentCreatedById?: string | null,
    } | null,
    quizzes?:  {
      __typename: "ModelQuizQuestionConnection",
      items:  Array< {
        __typename: "QuizQuestion",
        id: string,
        quizID: string,
        questionID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    } | null,
    updatedAt: string,
    questionTypeId?: string | null,
    questionImageId?: string | null,
    questionCreatedById?: string | null,
  } | null,
};

export type OnCreateAnswerSubscription = {
  onCreateAnswer?:  {
    __typename: "Answer",
    id: string,
    question:  {
      __typename: "Question",
      id: string,
      title: string,
      type?:  {
        __typename: "QuestionType",
        id: string,
        name: string,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        questionTypeCreatedById?: string | null,
      } | null,
      correctAnswer?: string | null,
      options?: Array< string | null > | null,
      image?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      quizzes?:  {
        __typename: "ModelQuizQuestionConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      questionTypeId?: string | null,
      questionImageId?: string | null,
      questionCreatedById?: string | null,
    },
    student:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    },
    values: Array< string | null >,
    questionID?: string | null,
    studentID?: string | null,
    deleted?: string | null,
    isCorrect?: boolean | null,
    createdAt: string,
    updatedAt: string,
    answerQuestionId: string,
    answerStudentId: string,
  } | null,
};

export type OnUpdateAnswerSubscription = {
  onUpdateAnswer?:  {
    __typename: "Answer",
    id: string,
    question:  {
      __typename: "Question",
      id: string,
      title: string,
      type?:  {
        __typename: "QuestionType",
        id: string,
        name: string,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        questionTypeCreatedById?: string | null,
      } | null,
      correctAnswer?: string | null,
      options?: Array< string | null > | null,
      image?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      quizzes?:  {
        __typename: "ModelQuizQuestionConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      questionTypeId?: string | null,
      questionImageId?: string | null,
      questionCreatedById?: string | null,
    },
    student:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    },
    values: Array< string | null >,
    questionID?: string | null,
    studentID?: string | null,
    deleted?: string | null,
    isCorrect?: boolean | null,
    createdAt: string,
    updatedAt: string,
    answerQuestionId: string,
    answerStudentId: string,
  } | null,
};

export type OnDeleteAnswerSubscription = {
  onDeleteAnswer?:  {
    __typename: "Answer",
    id: string,
    question:  {
      __typename: "Question",
      id: string,
      title: string,
      type?:  {
        __typename: "QuestionType",
        id: string,
        name: string,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        questionTypeCreatedById?: string | null,
      } | null,
      correctAnswer?: string | null,
      options?: Array< string | null > | null,
      image?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      quizzes?:  {
        __typename: "ModelQuizQuestionConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      questionTypeId?: string | null,
      questionImageId?: string | null,
      questionCreatedById?: string | null,
    },
    student:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    },
    values: Array< string | null >,
    questionID?: string | null,
    studentID?: string | null,
    deleted?: string | null,
    isCorrect?: boolean | null,
    createdAt: string,
    updatedAt: string,
    answerQuestionId: string,
    answerStudentId: string,
  } | null,
};

export type OnCreateCourseStudentSubscription = {
  onCreateCourseStudent?:  {
    __typename: "CourseStudent",
    id: string,
    userID: string,
    courseID: string,
    user:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    },
    course:  {
      __typename: "Course",
      id: string,
      name: string,
      slug: string,
      description?: string | null,
      image?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelModuleConnection",
        nextToken?: string | null,
      } | null,
      students?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      prerequisiteQuiz?: string | null,
      prerequisiteQuizTitle?: string | null,
      prerequisiteQuizSlug?: string | null,
      prerequisiteModule?: string | null,
      prerequisiteModuleTitle?: string | null,
      prerequisiteModuleId?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      courseImageId?: string | null,
      courseCreatedById?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateCourseStudentSubscription = {
  onUpdateCourseStudent?:  {
    __typename: "CourseStudent",
    id: string,
    userID: string,
    courseID: string,
    user:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    },
    course:  {
      __typename: "Course",
      id: string,
      name: string,
      slug: string,
      description?: string | null,
      image?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelModuleConnection",
        nextToken?: string | null,
      } | null,
      students?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      prerequisiteQuiz?: string | null,
      prerequisiteQuizTitle?: string | null,
      prerequisiteQuizSlug?: string | null,
      prerequisiteModule?: string | null,
      prerequisiteModuleTitle?: string | null,
      prerequisiteModuleId?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      courseImageId?: string | null,
      courseCreatedById?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteCourseStudentSubscription = {
  onDeleteCourseStudent?:  {
    __typename: "CourseStudent",
    id: string,
    userID: string,
    courseID: string,
    user:  {
      __typename: "User",
      id: string,
      courses?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      username: string,
      preferred_username: string,
      email: string,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      group?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?: string | null,
      updatedAt: string,
    },
    course:  {
      __typename: "Course",
      id: string,
      name: string,
      slug: string,
      description?: string | null,
      image?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelModuleConnection",
        nextToken?: string | null,
      } | null,
      students?:  {
        __typename: "ModelCourseStudentConnection",
        nextToken?: string | null,
      } | null,
      prerequisiteQuiz?: string | null,
      prerequisiteQuizTitle?: string | null,
      prerequisiteQuizSlug?: string | null,
      prerequisiteModule?: string | null,
      prerequisiteModuleTitle?: string | null,
      prerequisiteModuleId?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      courseImageId?: string | null,
      courseCreatedById?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateAccountLanguageSubscription = {
  onCreateAccountLanguage?:  {
    __typename: "AccountLanguage",
    id: string,
    accountID: string,
    languageID: string,
    account:  {
      __typename: "Account",
      id: string,
      logo?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      siteTitle: string,
      tagline: string,
      description: string,
      siteAddress: string,
      defaultLanguage: string,
      languages?:  {
        __typename: "ModelAccountLanguageConnection",
        nextToken?: string | null,
      } | null,
      status: string,
      socialLinks?:  {
        __typename: "ModelSocialLinkConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      accountLogoId?: string | null,
      accountCreatedById?: string | null,
    },
    language:  {
      __typename: "Language",
      id: string,
      name: string,
      code: string,
      accounts?:  {
        __typename: "ModelAccountLanguageConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      languageCreatedById?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateAccountLanguageSubscription = {
  onUpdateAccountLanguage?:  {
    __typename: "AccountLanguage",
    id: string,
    accountID: string,
    languageID: string,
    account:  {
      __typename: "Account",
      id: string,
      logo?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      siteTitle: string,
      tagline: string,
      description: string,
      siteAddress: string,
      defaultLanguage: string,
      languages?:  {
        __typename: "ModelAccountLanguageConnection",
        nextToken?: string | null,
      } | null,
      status: string,
      socialLinks?:  {
        __typename: "ModelSocialLinkConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      accountLogoId?: string | null,
      accountCreatedById?: string | null,
    },
    language:  {
      __typename: "Language",
      id: string,
      name: string,
      code: string,
      accounts?:  {
        __typename: "ModelAccountLanguageConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      languageCreatedById?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteAccountLanguageSubscription = {
  onDeleteAccountLanguage?:  {
    __typename: "AccountLanguage",
    id: string,
    accountID: string,
    languageID: string,
    account:  {
      __typename: "Account",
      id: string,
      logo?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      siteTitle: string,
      tagline: string,
      description: string,
      siteAddress: string,
      defaultLanguage: string,
      languages?:  {
        __typename: "ModelAccountLanguageConnection",
        nextToken?: string | null,
      } | null,
      status: string,
      socialLinks?:  {
        __typename: "ModelSocialLinkConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      accountLogoId?: string | null,
      accountCreatedById?: string | null,
    },
    language:  {
      __typename: "Language",
      id: string,
      name: string,
      code: string,
      accounts?:  {
        __typename: "ModelAccountLanguageConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      languageCreatedById?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreatePostCategorySubscription = {
  onCreatePostCategory?:  {
    __typename: "PostCategory",
    id: string,
    categoryID: string,
    postID: string,
    category:  {
      __typename: "Category",
      id: string,
      title: string,
      slug: string,
      type: string,
      description?: string | null,
      thumbnail?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      visibility?: string | null,
      posts?:  {
        __typename: "ModelPostCategoryConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      categoryThumbnailId?: string | null,
      categoryCreatedById?: string | null,
    },
    post:  {
      __typename: "Post",
      id: string,
      title: string,
      slug: string,
      content?: string | null,
      excerpt?: string | null,
      status?: string | null,
      thumbnail?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      visibility?: string | null,
      categories?:  {
        __typename: "ModelPostCategoryConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      postThumbnailId?: string | null,
      postCreatedById?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdatePostCategorySubscription = {
  onUpdatePostCategory?:  {
    __typename: "PostCategory",
    id: string,
    categoryID: string,
    postID: string,
    category:  {
      __typename: "Category",
      id: string,
      title: string,
      slug: string,
      type: string,
      description?: string | null,
      thumbnail?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      visibility?: string | null,
      posts?:  {
        __typename: "ModelPostCategoryConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      categoryThumbnailId?: string | null,
      categoryCreatedById?: string | null,
    },
    post:  {
      __typename: "Post",
      id: string,
      title: string,
      slug: string,
      content?: string | null,
      excerpt?: string | null,
      status?: string | null,
      thumbnail?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      visibility?: string | null,
      categories?:  {
        __typename: "ModelPostCategoryConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      postThumbnailId?: string | null,
      postCreatedById?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeletePostCategorySubscription = {
  onDeletePostCategory?:  {
    __typename: "PostCategory",
    id: string,
    categoryID: string,
    postID: string,
    category:  {
      __typename: "Category",
      id: string,
      title: string,
      slug: string,
      type: string,
      description?: string | null,
      thumbnail?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      visibility?: string | null,
      posts?:  {
        __typename: "ModelPostCategoryConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      categoryThumbnailId?: string | null,
      categoryCreatedById?: string | null,
    },
    post:  {
      __typename: "Post",
      id: string,
      title: string,
      slug: string,
      content?: string | null,
      excerpt?: string | null,
      status?: string | null,
      thumbnail?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      visibility?: string | null,
      categories?:  {
        __typename: "ModelPostCategoryConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      postThumbnailId?: string | null,
      postCreatedById?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateQuizQuestionSubscription = {
  onCreateQuizQuestion?:  {
    __typename: "QuizQuestion",
    id: string,
    quizID: string,
    questionID: string,
    quiz:  {
      __typename: "Quiz",
      id: string,
      title: string,
      slug?: string | null,
      description?: string | null,
      type?:  {
        __typename: "QuizType",
        id: string,
        name: string,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        quizTypeCreatedById?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuizQuestionConnection",
        nextToken?: string | null,
      } | null,
      quizQuestions?: Array< string | null > | null,
      prerequisite?: string | null,
      prerequisiteTitle?: string | null,
      prerequisiteSlug?: string | null,
      prerequisiteModule?: string | null,
      prerequisiteModuleTitle?: string | null,
      nextQuiz?: string | null,
      nextQuizTitle?: string | null,
      nextQuizSlug?: string | null,
      nextCourse?: string | null,
      nextCourseTitle?: string | null,
      nextCourseSlug?: string | null,
      hasFeedback?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      moduleQuizzesId?: string | null,
      quizTypeId?: string | null,
      quizCreatedById?: string | null,
    },
    question:  {
      __typename: "Question",
      id: string,
      title: string,
      type?:  {
        __typename: "QuestionType",
        id: string,
        name: string,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        questionTypeCreatedById?: string | null,
      } | null,
      correctAnswer?: string | null,
      options?: Array< string | null > | null,
      image?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      quizzes?:  {
        __typename: "ModelQuizQuestionConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      questionTypeId?: string | null,
      questionImageId?: string | null,
      questionCreatedById?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateQuizQuestionSubscription = {
  onUpdateQuizQuestion?:  {
    __typename: "QuizQuestion",
    id: string,
    quizID: string,
    questionID: string,
    quiz:  {
      __typename: "Quiz",
      id: string,
      title: string,
      slug?: string | null,
      description?: string | null,
      type?:  {
        __typename: "QuizType",
        id: string,
        name: string,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        quizTypeCreatedById?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuizQuestionConnection",
        nextToken?: string | null,
      } | null,
      quizQuestions?: Array< string | null > | null,
      prerequisite?: string | null,
      prerequisiteTitle?: string | null,
      prerequisiteSlug?: string | null,
      prerequisiteModule?: string | null,
      prerequisiteModuleTitle?: string | null,
      nextQuiz?: string | null,
      nextQuizTitle?: string | null,
      nextQuizSlug?: string | null,
      nextCourse?: string | null,
      nextCourseTitle?: string | null,
      nextCourseSlug?: string | null,
      hasFeedback?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      moduleQuizzesId?: string | null,
      quizTypeId?: string | null,
      quizCreatedById?: string | null,
    },
    question:  {
      __typename: "Question",
      id: string,
      title: string,
      type?:  {
        __typename: "QuestionType",
        id: string,
        name: string,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        questionTypeCreatedById?: string | null,
      } | null,
      correctAnswer?: string | null,
      options?: Array< string | null > | null,
      image?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      quizzes?:  {
        __typename: "ModelQuizQuestionConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      questionTypeId?: string | null,
      questionImageId?: string | null,
      questionCreatedById?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteQuizQuestionSubscription = {
  onDeleteQuizQuestion?:  {
    __typename: "QuizQuestion",
    id: string,
    quizID: string,
    questionID: string,
    quiz:  {
      __typename: "Quiz",
      id: string,
      title: string,
      slug?: string | null,
      description?: string | null,
      type?:  {
        __typename: "QuizType",
        id: string,
        name: string,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        quizTypeCreatedById?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuizQuestionConnection",
        nextToken?: string | null,
      } | null,
      quizQuestions?: Array< string | null > | null,
      prerequisite?: string | null,
      prerequisiteTitle?: string | null,
      prerequisiteSlug?: string | null,
      prerequisiteModule?: string | null,
      prerequisiteModuleTitle?: string | null,
      nextQuiz?: string | null,
      nextQuizTitle?: string | null,
      nextQuizSlug?: string | null,
      nextCourse?: string | null,
      nextCourseTitle?: string | null,
      nextCourseSlug?: string | null,
      hasFeedback?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      moduleQuizzesId?: string | null,
      quizTypeId?: string | null,
      quizCreatedById?: string | null,
    },
    question:  {
      __typename: "Question",
      id: string,
      title: string,
      type?:  {
        __typename: "QuestionType",
        id: string,
        name: string,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
        questionTypeCreatedById?: string | null,
      } | null,
      correctAnswer?: string | null,
      options?: Array< string | null > | null,
      image?:  {
        __typename: "Attachment",
        id: string,
        fileurl: string,
        alternativeText?: string | null,
        createdAt: string,
        updatedAt: string,
        mediaAttachmentsId?: string | null,
        attachmentCreatedById?: string | null,
      } | null,
      quizzes?:  {
        __typename: "ModelQuizQuestionConnection",
        nextToken?: string | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdBy?:  {
        __typename: "User",
        id: string,
        username: string,
        preferred_username: string,
        email: string,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        group?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdBy?: string | null,
        updatedAt: string,
      } | null,
      updatedAt: string,
      questionTypeId?: string | null,
      questionImageId?: string | null,
      questionCreatedById?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};
