import { Directions, Languages } from "./../../constants/enums";

// actions
const SET_DIRECTION = "app/SET_DIRECTION";
const SET_LANGUAGE = "app/SET_LANGUAGE";
const OPEN_MODAL = "app/OPEN_MODAL";
const CLOSE_MODAL = "app/CLOSE_SNACKBAR";
const OPEN_SNACKBAR = "app/OPEN_SNACKBAR";
const CLOSE_SNACKBAR = "app/CLOSE_MODAL";
const CLEAR = "app/CLEAR";
const SET = "app/SET";

const DEFAULT_STATE = {
  direction: Directions.LTR,
  language: Languages.ENGLISH,
  modalOpen: false,
  modalContent: null,
  snackBarOpen: false,
  snackBarSeverity: "info",
  snackBarContent: null,
  session: null,
};

// reducer
const appReducer = (state = DEFAULT_STATE, action: any = {}) => {
  switch (action.type) {
    case SET_DIRECTION:
      return Object.assign({}, state, { direction: action.direction });
    case SET_LANGUAGE:
      return Object.assign({}, state, { language: action.language });
    case OPEN_MODAL:
      return Object.assign({}, state, {
        modalOpen: true,
        modalContent: action.modalContent,
      });
    case CLOSE_MODAL:
      return Object.assign({}, state, {
        modalOpen: false,
        modalContent: null,
      });
    case OPEN_SNACKBAR:
      return Object.assign({}, state, {
        snackBarOpen: true,
        snackBarContent: action.snackBarContent,
        snackBarSeverity: action.snackBarSeverity,
      });
    case CLOSE_SNACKBAR:
      return Object.assign({}, state, {
        snackBarOpen: false,
        snackBarContent: null,
        snackBarSeverity: "info",
      });
    case SET:
      return Object.assign({}, state, { session: action.session });
    case CLEAR:
      return Object.assign({}, state, { session: null });
    default:
      return state;
  }
};

export default appReducer;

// action creators
export const setDirection = (direction: string) => {
  return { direction, type: SET_DIRECTION };
};

export const setLanguage = (language: string) => {
  return { language, type: SET_DIRECTION };
};

export const openModal = (modalContent: any) => {
  return { modalContent, type: OPEN_MODAL };
};

export const closeModal = () => {
  return { modalContent: null, type: CLOSE_MODAL };
};

export const openSnackBar = (
  snackBarContent: string,
  snackBarSeverity: string
) => {
  return { snackBarContent, snackBarSeverity, type: OPEN_SNACKBAR };
};

export const closeSnackBar = () => {
  return { snackBarContent: null, type: CLOSE_SNACKBAR };
};

export const setSession = (session: any) => {
  return { session, type: SET };
};

export const clearSession = () => {
  return { type: CLEAR };
};
