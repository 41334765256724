import { Account } from "../../models/api";

// actions
const SET = "accounts/SET";
const SET_FEATURES = "accounts/SET_FEATURES";
const SET_SELECTED = "accounts/SET_SELECTED";
const SET_SEARCH_TEXT = "accounts/SET_SEARCH_TEXT";
const SET_DATES = "accounts/SET_DATES";

const DEFAULT_STATE = {
  accounts: [],
  features: [],
  selectedAccount: null,
  searchText: "",
  datesFilter: [null, null],
};

// reducer
const accountReducer = (state = DEFAULT_STATE, action: any = {}) => {
  switch (action.type) {
    case SET:
      return Object.assign({}, state, { accounts: action.accounts });
    case SET_FEATURES:
      return Object.assign({}, state, { features: action.features });
    case SET_SELECTED:
      return Object.assign({}, state, { selectedAccount: action.account });
    case SET_SEARCH_TEXT:
      return Object.assign({}, state, { searchText: action.searchText });
    case SET_DATES:
      return Object.assign({}, state, { datesFilter: action.dates });
    default:
      return state;
  }
};

export default accountReducer;

// action creators
export const setAccounts = (accounts: Account[]) => {
  return { accounts, type: SET };
};

export const setAccountFeatures = (features: any) => {
  return { features, type: SET_FEATURES };
};

export const setSelectedAccount = (account: Account) => {
  return { account, type: SET_SELECTED };
};

export const setSearchText = (searchText: string) => {
  return { searchText, type: SET_SEARCH_TEXT };
};

export const setDates = (dates: any[]) => {
  return { dates, type: SET_DATES };
};
