import { API, graphqlOperation } from "aws-amplify";
import {
  createFeature,
  deleteFeature,
  updateFeature,
} from "../graphql/mutations";
import { activeFeatures, getFeature } from "../graphql/queries";
import {
  CreateFeatureInput,
  ModelSortDirection,
  UpdateFeatureInput,
} from "../models/api";
import { Token } from "../models/pagination";

export const list = async (limit: number, nextToken: Token) => {
  try {
    const result: any = await API.graphql(
      graphqlOperation(activeFeatures, {
        deleted: "0",
        limit,
        nextToken,
        sortDirection: ModelSortDirection.DESC,
      })
    );

    return result.data.activeFeatures;
  } catch (error: any) {
    throw new Error(error.errors[0].message);
  }
};

export const get = async (id: string) => {
  try {
    const result: any = await API.graphql(graphqlOperation(getFeature, { id }));

    return result.data.getFeature;
  } catch (error: any) {
    throw new Error(error.errors[0].message);
  }
};

export const create = async (data: CreateFeatureInput) => {
  try {
    const result: any = await API.graphql(
      graphqlOperation(createFeature, { input: data })
    );

    return result.data.createFeature;
  } catch (error: any) {
    throw new Error(error.errors[0].message);
  }
};

export const update = async (data: UpdateFeatureInput) => {
  try {
    const result: any = await API.graphql(
      graphqlOperation(updateFeature, { input: data })
    );

    return result.data.updateFeature;
  } catch (error: any) {
    throw new Error(error.errors[0].message);
  }
};

export const softDelete = async (id: string) => {
  try {
    const result: any = await API.graphql(
      graphqlOperation(updateFeature, { input: { id, deleted: "1" } })
    );

    return result.data.updateFeature;
  } catch (error: any) {
    throw new Error(error.errors[0].message);
  }
};

export const remove = async (id: string) => {
  try {
    const result: any = await API.graphql(
      graphqlOperation(deleteFeature, { input: { id } })
    );

    return result.data.deleteFeature;
  } catch (error: any) {
    throw new Error(error.errors[0].message);
  }
};
